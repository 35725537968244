import English from "@/utillities/languages/English";

export default {
  ...English,
  checklist_status_sheet: "Feuille d'état de la checklist",
  all: "Tout",

  start: "Démarrer",
  filling: "Remplissage",

  accept: "Accepter",
  reject: "Rejeter",
  move_to_next_stage: "Passer à l'étape suivante",
  save_as_draft: "Enregistrer comme brouillon",
  save_and_submit: "Enregistrer et soumettre",
  closure: "Clôture",
  duration: "Durée",
  refill_request: "Demande de recharge",
  is_be_cancelled: "sera annulé",
  delete_this: "Supprimer cela",
  event: "Événement",

  for: "Pour",
  of: "De",
  downtime: "Temps d'arrêt",

  metrics: "Metrics",
  oee_by_day: "OEE par jour",

  ideal_cycle_time: "Temps de cycle idéal",

  minutes: "Minutes",
  production_time: "Temps de production",
  back: "Retour",
  down_times: "Temps d'arrêt",

  top_5_reasons: "Top 5 des raisons",
  top_5_categories: "Top 5 des catégories",
  enter_apparent_energy_manually: "Entrer l'énergie apparente manuellement",

  oee: "OEE",
  reason: "Raison",
  add_downtime_reason: "Ajouter une raison d'arrêt",

  default_target: "Cible par défaut",

  enter_in_minutes: "Entrer en minutes",
  custome_fields: "Champs personnalisés",
  enter_data_in_minutes: "Entrer les données en minutes",
  enter_data_in_hours: "Entrer les données en heures",

  create_tod_billing_widget: "Créer un widget de facturation TOD",
  edit_tod_billing_widget: "Modifier le widget de facturation TOD",
  choose_from_machine_groups: "Choisir parmi les groupes de machines",
  tod_billing: "Facturation TOD",
  choose: "Choisir",

  create_shift_based_machine_usage_log:
    "Créer un journal d'utilisation des machines",
  shift_based_machine_usage_log: "Journal d'utilisation des machines",
  rejected: "Rejeté",
  machine_operator: "Opérateur de machine",
  production_end_timestamp: "Heure de fin de production",
  production_start_timestamp: "Heure de début de production",
  running_time: "Temps de fonctionnement",
  idle_time: "Temps d'inactivité",
  down_time: "Temps d'arrêt",

  date_row_index: "Rangée de date",
  date_position: "Position de la date",

  safety_incident_sheet: "Feuille d'incident de sécurité",
  safety_inspection_sheet: "Feuille d'inspection de sécurité",

  ghg_emission_logs: "Journaux des émissions de GES",
  add_ghg_emission_data: "Ajouter des journaux des émissions de GES",
  ghg_scope_conversion: "Conversion du périmètre des GES",
  create_ghg_scope_conversion: "Créer une conversion du périmètre des GES",
  ghg_data_logs: "Journaux des données d'émissions de GES",

  choose_inspection_type_optional: "Choisir le type d'inspection (Optionnel)",

  supplier_code: "Code fournisseur",
  contact: "Contact",
  point_of_contact: "Point de contact",
  quantity_supplied: "Quantité fournie",
  create_product: "Créer un produit",
  products: "Produits",
  data: "Données",
  timezone: "Fuseau horaire",
  tariffs: "Tarifs",

  days_delta: "Delta de jours",
  machine_groups: "Groupes de machines",
  param_groups: "Groupes de paramètres",
  user_groups: "Groupes d'utilisateurs",

  topic_prefix: "Préfixe du sujet",
  message_direction: "Direction du message",

  safety_inspection: "Inspection de sécurité",
  write_inspection_log: "Écrire le journal d'inspection",
  inspection_id: "ID d'inspection",
  inspector_id: "ID de l'inspecteur",
  inspection_date: "Date d'inspection",
  inspection_type: "Type d'inspection",
  findings: "Constatations",
  actions_required: "Actions requises",
  followup_date: "Date de suivi",
  status: "Statut",

  safety_incident: "Incident de sécurité",
  write_incident: "Écrire l'incident",
  incident_description: "Description de l'incident",
  severity: "Gravité",
  accident: "Accident",
  accident_with_loss: "Accident avec perte",
  closed: "Fermé",
  corrective_action: "Action corrective",
  employee_id: "ID employé",
  apply: "Appliquer",
  choose_location_optional: "Choisir l'emplacement (Optionnel)",

  create_tariff: "Créer Tarif",
  default_tariff_val: "Valeur de Tarif Par Défaut",
  add_tariff_data: "Ajouter des Données Tarifaires",
  edit_tariff: "Modifier Tarif",
  delete_tariff: "Supprimer Tarif",
  tariff_value: "Valeur de Tarif",

  machine_status_timeline: "Chronologie de l'état de la machine",
  total_duration: "Durée totale",
  generate: "Générer",
  remove_machine: "Retirer la machine",
  edit_machine_status_timeline_widget:
    "Modifier le widget de la chronologie de l'état de la machine",
  add_machine_Status__timeline_widget:
    "Ajouter un widget de chronologie de l'état de la machine",
  select_timeframe: "Sélectionner la période",
  timeframe_type_shift: "Poste",
  timeframe_type_custom: "Personnalisé",
  timeframe_type_yesterday: "Hier",
  edit_report_details: "Modifier les détails du rapport",
  download_current_template: "Télécharger le modèle actuel",
  date_column_index: "Date Colonne (Index)",
  select_area: "Sélectionner une zone",
  select_shift: "Sélectionner le poste",

  dashboard_heading: "Tableau de bord",
  production_params: "Params de production",
  no_modules_configured: "Aucun module configuré",
  dashboard_panel: "Panneau de bord",
  choose_type: "Choisir le type",
  name_is_required: "Le nom est requis",
  description_is_required: "La description est requise",
  required: "Requis",
  you_have_to_select_something: "Vous devez sélectionner quelque chose",
  name_must_be_less_than_30_characters:
    "Le nom doit être inférieur à 30 caractères",
  name_must_be_less_than_25_characters:
    "Le nom doit être inférieur à 25 caractères",
  name_must_be_less_than_50_characters:
    "Le nom doit être inférieur à 50 caractères",
  description_must_be_less_than_35_characters:
    "La description doit être inférieure à 35 caractères",
  description_must_be_less_than_55_characters:
    "La description doit être inférieure à 55 caractères",
  number_must_be_less_than_6_digits:
    "Le nombre doit être inférieur à 6 chiffres",
  number_must_be_less_than_8_digits:
    "Le nombre doit être inférieur Plus de 8 chiffres",
  number_must_be_less_than_7_digits:
    "Le nombre doit être inférieur Plus de 7 chiffres",
  panel: "Groupe",
  gauge: "Mesure",
  param: "Param",
  machine: "Machine",
  add_panel_to_dashboard: "Ajouter un panneau au tableau de bord",
  edit_dashboard_panel: "Modifier le panneau de tableau de bord",
  param_type: "Type de paramètre",
  hub: "Moyeu",
  workspace: "Espace de travail",
  device: "Appareil",
  name: "Dénomination",
  language: "langue",
  time_duration: "Durée",
  submit: "Soumettre",
  cancel: "Annuler",
  close: "Fermer",
  usage: "Utilisation",
  peak_value: "Valeur maximale",
  current_data: "Données actuelles",
  parameter: "Paramètre",
  computed_param: "Param calculé",
  derived_param: "Param dérivé",
  last_7_days: "7 derniers jours",
  last_30_days: "30 derniers jours",
  last_60_days: "Les 60 derniers jours",
  last_90_days: "derniers 90 jours",
  last_180_days: "Les 180 derniers jours",
  last_365_days: "Lat 365 Jours",
  last_1_hour: "dernière 1 heure",
  last_3_hours: "3 dernières heures",
  last_6_hours: "6 dernières heures",
  reorder_views: "Réorganiser",
  create_view: "Créer",
  clear_view_name_filter: "Effacer les filtres",
  view_type: "Type de vue",
  description: "Désignation des marchandises",
  table_view: "Affichage du tableau",
  widget_view: "Affichage Widget",
  dynamic_timeframed_widget_view: "Affichage dynamique du calendrier Widget",
  diagram_view: "Vue du diagramme",
  energy_usage_view: "Vue sur l'utilisation de l'énergie",
  machine_scroll_view: "Scroll machine Affichage",
  param_scroll_view: "Parapente Affichage",
  consolidated_view: "Vue consolidée",
  air_quality_view: "Vue sur la qualité de l'air",
  view_auto_change: "Afficher la modification automatique",
  no_views_configured: "Aucune vue configurée",
  add_to_dashboard: "Ajouter au tableau de bord",
  edit_view_name: "Modifier le nom de la vue",
  edit_view: "Modifier le nom",
  save: "Sauvegarder",
  discard: "jeter",
  adjust: "ajuster",
  refresh: "Actualiser",
  create_machine: "Créer une machine",
  add_machine: "Ajouter une machine",
  delete_premise_area: "Supprimer une zone de locaux",
  sequence: "Séquence",
  machine_type: "Type de machine",
  physical_machine: "Machine physique",
  virtual_machine: "Machine virtuelle",
  positive_workspace: "Espace de travail positif",
  positive_machine: "Machine positive",
  negative_workspace: "Espace de travail négatif",
  negative_machine: "Machine négative",
  label: "Étiquette",
  positive_machine_Ids: "Machine positive Les cartes d'identité",
  negative_machine_Ids: "Machine négative Les cartes d'identité",
  trend_analysis_tools: "Outils d'analyse des tendances",
  energy_consumption_by_machines: "Consommation d'énergie Par machines",
  Create_report: "Créer un rapport",
  view_report: "Voir le rapport",
  tarrif_on: "Tarif",
  active_energy: "Énergie active",
  apparent_energy: "Énergie apparente",
  automate_report: "Rapport d'automatisation",
  add_root_machine: "Ajouter la machine racine",
  add_common_machine: "Ajouter une machine commune",
  notification_groups: "Groupes de notification",
  add_machines_to_list: "Ajouter des machines à la liste",
  machine_list: "Liste des machines",
  data_historian: "Données historiques",
  timeframed_trends: "Tendances dans les délais",
  dual_axis_trend_comparison: "Comparaison des tendances des deux axes",
  granular24_hrs_trend: "Granular24 Hrs Tendance",
  machinewise_trend: "Tendance de la machine",
  basic_statistical_trends: "Statistiques de base Tendances",
  log_analysis: "Analyse du journal",
  aqi_comparison_panel: "Groupe de comparaison de l'AQI",
  time_of_day_trend: "Tendance de la journée",
  param_historical_comparisons: "Comparaisons historiques des paramètres",
  energy_usage_trends: "Tendances de l'utilisation de l'énergie",
  machine_name: "Nom de la machine",
  param_name: "Nom du paramètre",
  trend_view: "Affichage des tendances",
  raw: "Montant brut",
  trend: "Tendances",
  normalised: "Normalisé",
  from_date: "À partir de la date",
  to_date: "À ce jour",
  from: "De",
  to: "Aux",
  hour: "Heure",
  minute: "Minute",
  generate_report: "Générer un rapport",
  time_range: "Plage de temps",
  no_data_available: "Aucune donnée disponible",
  daily: "Tous les jours",
  monthly: "Mois",
  weekly: "Semaine",
  week: "Semai",
  t4_days: "4-Jours",
  yearly: "Année",
  device_name: "Nom du périphérique",
  machine_wise_comparison: "Comparaison avec les machines",
  stats_detailed_report: "Statistiques Rapport détaillé",
  log_type: "Type de journal",
  choose_Log: "Choisir le journal",
  area: "Domaine",
  aq_device: "Appareil AQ",
  nebeskie_labs_office: "Bureau des laboratoires Nebeskie",
  date_range: "Date_range",
  this_year: "Cette année",
  "last 6 months": "6 derniers mois",
  "last quater": "Dernier trimestre",
  mtd: "MTD",
  ytd: "YTD",
  yesterday: "Hier",
  custom: "Personnalisé",
  run_report: "RAPPORT RUN",
  create_report: "Créer un rapport",
  usage_report: "Rapport d'utilisation",
  consolidated_report: "Rapport de synthèse",
  dynamic_excel_report: "Rapport dynamique Excel",
  energy_usage_report: "Rapport sur l'utilisation de l'énergie",
  create_action: "Créer une action",
  action_type: "Type d'action",
  notification_group: "Groupe de notification",
  address: "Adresse",
  number_of_registers: "Nombre de registres",
  value: "Valeur",
  send_notification: "Envoyer une notification",
  write_parameter: "Écrire le paramètre",
  relays: "Relais",
  relay_1: "Relais 1",
  relay_2: "Relais 2",
  relay_3: "Relais 3",
  relay_4: "Relais 4",
  email_must_be_valid: "Le courriel doit être valide",
  it_should_be_a_number: "Ça devrait être un numéro",
  it_should_be_a_valid_number: "Ce devrait être un numéro valide",
  operator: "Opérateur",
  operand: "Opérations",
  target_value: "Valeur cible",
  wait_time_seconds: "Temps d'attente (secondes)",
  greater_than: "Plus que",
  modulus_greater_than: "Modulus Plus Que",
  lesser_than: "Moins que",
  modulus_lesser_than: "Moins de module Que",
  equal_to: "Égale à",
  not_equal_to: "Pas égal à",
  modulus_equal_to: "Modulus égal à",
  greater_than_equal_to: "Plus qu'égal",
  modulus_greater_than_equal_to: "Modulus plus grand que égal à",
  lesser_than_equal_to: "Moins qu ' égale à",
  modulus_lesser_than_equal_to: "Modulus moins que égal à",
  bit_high: "Un peu haut",
  bit_low: "Faible",
  it_should_be_a_positive_whole_number:
    "Ce devrait être un nombre entier positif",
  it_should_be_a_positive: "Cela devrait être positif",
  create_process: "Créer un processus",
  edit_process: "Modifier le processus",
  status_rule: "Règle de statut",
  create_process_status_rule: "Créer la règle d'état du processus",
  tag_type: "Type d'étiquette",
  process_status: "État du processus",
  machines: "Machines",
  running: "Courir",
  idling: "Jeune",
  offline: "Hors ligne",
  no_machines_configured: "Pas de machines configurées",
  delete_process: "Supprimer le processus",
  add_machine_to_process: "Ajouter une machine au processus",
  add: "ADD",
  add_to_list: "Ajouter à la liste",
  search: "Recherche",
  switch_to: "Changer",
  switch_to_base_org: "Passer à l'ordre de base",
  switch: "Commutateur",
  create_new_organisation: "Créer une nouvelle organisation",
  organisation_name: "Nom de l'organisation",
  organisation_type: "Type d'organisation",
  country_code: "Code Pays",
  country: "Pays",
  state: "État",
  clear: "Effacer",
  change_organisation: "Changement d'organisation",
  organisation_id: "ID de l'organisation",
  update_your_oem_logo: "Mettre à jour votre logo OEM",
  select_a_logo_to_upload: "Sélectionnez un logo à télécharger",
  submit_logo: "Soumettre le logo",
  create_workspace: "Créer un espace de travail",
  hub_id: "NUMÉRO D'IDENTIFICATION",
  name_must_be_less_than_45_characters:
    "Le nom doit être inférieur à 45 caractères",
  id_is_required: "L'ID est requis",
  no_space_allowed: "Aucun espace autorisé",
  id_must_be_less_than_6_characters: "Id doit être moins de 6 caractères",
  edit_workspace: "Modifier l'espace de travail",
  delete_workspace: "Supprimer l'espace de travail",
  add_modbus_device: "Ajouter un périphérique Modbus",
  add_device: "Ajouter un périphérique",
  oops: "Oups !!",
  ok: "Très bien",
  slave_id: "Identification de l'esclave",
  device_type: "Type de périphérique",
  connection_type: "Type de connexion",
  mGate: "m Porte",
  make: "Marque",
  model: "Modèle",
  name_must_be_less_than_33_characters:
    "Le nom doit être inférieur à 33 caractères",
  name_must_be_less_than_11_characters:
    "Le nom doit être inférieur à 11 caractères",
  it_should_be_a_valid_slave_id:
    "Ce devrait être une carte d'identité d'esclave valide",
  name_must_be_less_than_4_digits: "Le nom doit être inférieur à 4 chiffres",
  modbus_tcp: "Modbus TCP",
  modbus_rtu: "Modbus RTU",
  add_virtual_device: "Ajouter un périphérique virtuel",
  add_mqtt_device: "Ajouter un périphérique MQtt",
  topic: "Thème",
  identifier: "Identification",
  identifier_value: "Valeur d'identification",
  name_must_be_less_than_20_characters:
    "Le nom doit être inférieur à 20 caractères",
  no_special_characters_or_space_allowed:
    "Pas de caractères spéciaux ou d'espace autorisé",
  must_be_less_than_15_characters: "Doit Être moins de 15 charcters",
  create_role: "Créer un rôle",
  role: "Rôle",
  role_is_required: "Un rôle est nécessaire",
  role_must_be_less_than_40_characters:
    "Le rôle doit être inférieur à 40 caractères",
  role_should_not_contain_white_characters:
    "Le rôle ne doit pas contenir de caractères blancs",
  comparison: "Comparaison",
  historical_time_of_day_comparison_parameter:
    "Historique Comparaison de l'heure du jour - Paramètre",
  device_parameter: "Paramètres du périphérique",
  date_1: "Date 1",
  date_2: "Date 2",
  tod_comparison_report: "Rapport de comparaison TSD",
  time_frame: "Calendrier",
  download_table: "Télécharger le tableau",
  table: "Tableau",
  showMinMax_values: "Afficher les valeurs min et max",
  usage_or_average: "Utilisation ou moyenne",
  min_value: "Valeur minimale",
  last_12_weeks: "12 dernières semaines",
  last_6_months: "6 derniers mois",
  last_12_months: "12 derniers mois",
  year_5: "5 ans",
  machinewise_comparison: "Comparaison avec la machine",
  timeFrame: "Calendrier",
  it_should_be_a_date: "Ce devrait être une date",
  time_of_day_parameter: "Heure du jour - Paramètre",
  tod_report: "TOD - Rapport",
  in_this_timeframe: "Dans ce délai",
  average: "Moyenne",
  maximum: "Maximum",
  minimum: "Minimum",
  energy_usage_trend: "Tendance de l'utilisation de l'énergie",
  standard_deviation: "Écart type",
  statistical_mode: "Mode statistique",
  production_logs: "Registres de production",
  capacity_logs: "Registres des capacités",
  dynamic_logs: "Logs dynamiques",
  pollutants: "Polluants",
  group_by: "Groupe par",
  generate_grouped_trends: "Générer un groupe Tendances",
  tCo2: "tCo2",
  scope_1: "Portée 1",
  scope_2: "Portée 2",
  scope_3: "Portée 3",
  source: "Source",
  type: "Type",
  user_access_log: "Journal d'accès de l'utilisateur",
  user_logs: "Registres d'utilisateur",
  fetch_logs: "Récupération des journaux",
  update_profile: "Mettre à jour le profil",
  lang: "Langue",
  theme: "Thème",
  phone: "Téléphone",
  update: "Mise à jour",
  update_password: "Mettre à jour le mot de passe",
  change_password: "Modifier le mot de passe",
  old_password: "Ancien mot de passe",
  new_password: "Nouveau mot de passe",
  confirm_password: "Confirmer le mot de passe",
  password_must_be_more_than_8_characters:
    "Le mot de passe doit être plus de 8 caractères",
  passwords_do_not_match: "Les mots de passe ne correspondent pas",
  add_hub: "Ajouter un hub",
  id_must_be_less_than_4_characters:
    "L'identifiant doit être inférieur à 4 caractères",
  add_maintainer: "Ajouter le mainteneur",
  add_parent_org: "Ajouter l'ordre des parents",
  add_parent_to_organisation: "Ajouter un parent à l'organisation",
  parent_org_id: "Identification du parent",
  add_maintainer_to_organization: "Ajouter Mainteneur à l'organisation",
  maintainer_id: "ID du mainteneur",
  update_organisation_details: "Mise à jour des détails de l'organisation",
  update_your_organization_details:
    "Mettre à jour les détails de votre organisation",
  default_email: "Courriel par défaut",
  picker_in_menu: "Picker dans le menu",
  param_diagnostics: "Diagnostic du param",
  show_logo: "Afficher le logo",
  show_oem_logo: "Afficher le logo d'OEM",
  organisation_alias: "Organisation Alias",
  update_organisation_alias: "Mettre à jour l'organisation Alias",
  update_org_alias: "Mettre à jour Org Alias",
  update_your_organization_logo: "Mettre à jour votre organisation Logo",
  select_a_logo: "Sélectionner un logo",
  update_logo: "Mettre à jour le logo",
  create_uam_user: "Créer UAM Utilisateur",
  overview: "Aperçu général",
  batches: "Abattements",
  track_batch: "Lot de voie",
  batch_movement: "Mouvement par lots",
  production_planner: "Planificateur de production",
  show_unshipped_only: "Afficher Un expédié seulement",
  view_logs: "Afficher les journaux",
  params: "Params",
  add_filter: "Ajouter un filtre",
  filters: "Filtres",
  batch_id: "_Id du lot",
  show_details: "Afficher les détails",
  tracing_logs: "Recherche des journaux",
  quantity: "Quantité",
  location_event_type: "Emplacement Type d'événement",
  fetch_plans: "Récupère les plans",
  sku: "UGS",
  skus: "Unités de gestion des stocks",
  locations: "Emplacements",
  param_equations: "Équations de paramètres",
  combo_params: "paramètres combinés",
  calculated_params: "paramètres calculés",
  locations_event_types: "Types d'événements",
  lists: "Listes",
  code: "Code",
  add_production_plans: "Ajouter des plans de production",
  date: "Date",
  from_time: "De temps en temps",
  to_time: "Vers le temps",
  activities: "Activités",
  task_name: "Nom de la tâche",
  task_details: "Détails de la tâche",
  start_date: "Date de début",
  end_date: "Date de fin",
  assignee: "Assigné",
  manager: "Gestionnaire",
  activity: "Activité",
  activity_type: "Type d'activité",
  escalation_matrix: "Matrice d'escalade",
  maintenance_activity: "Activité d'entretien",
  em_level: "Niveau EM",
  delay: "Délai",
  user_group: "Groupe d'utilisateurs",
  mins_5: "5 minutes",
  mins_10: "10 minutes",
  mins_15: "15 minutes",
  mins_30: "30 minutes",
  hour_1: "1 heure",
  hour_6: "6 heures",
  day_1: "1 jour",
  day: "jour",
  day_3: "3 jours",
  insights: "Perspectives",
  create_emailer_group: "Créer un groupe d'e-mailers",
  notification_type: "Type de notification",
  email: "Courriel",
  sms: "SMS",
  create_machine_group: "Créer un groupe de machines",
  create_param_group: "Créer un groupe Param",
  create_user_group: "Créer un groupe d'utilisateurs",
  create_module: "Créer un module",
  busbar_temp_module: "Module Temp BusBar",
  power_quality_module: "Module de qualité de l'énergie",
  esg_module: "ESG Module",
  edge_hub: "Hub de bord",
  tolerance: "Tolérance",
  ambient_temp_input_type: "Type d'entrée temporaire ambiante",
  ambient_temp: "Température ambiante",
  workspace_ambient_temp: "Espace de travail Temps ambiant",
  device_ambient_temp: "Température ambiante du périphérique",
  r_temperature: "R Température",
  y_temperature: "Y Température",
  b_temperature: "B Température",
  n_temperature: "N Température",
  rated_current: "Actuels cotés",
  no_load_current: "Pas de courant de charge",
  r_current: "R Actuellement",
  y_current: "Y Actuellement",
  b_current: "B Actuellement",
  n_current: "N Actuellement",
  delete_activity: "Supprimer l'activité",
  substation_voltage_kv: "Tension de sous-station(KV)",
  transformer_rating_mva: "Note du transformateur(MVA)",
  percentage_impedance_of_the_transformer: "% Impédance du transformateur",
  voltage_rv_y: "Puissance RV(Y)",
  voltage_yb_v: "Tension YB(V)",
  voltage_br_v: "Tension BR(V)",
  current_r_a: "Actuellement R(A)",
  current_y_a: "Actuellement Y(A)",
  current_b_a: "Actuellement B(A)",
  thd_i: "JE SUIS",
  thd_v: "THD V",
  thd_i1: "TÉLÉPHONE I1",
  active_power_kw: "Puissance active (kw)",
  apparent_power_kva: "Puissance apparente(KVA)",
  reactive_power_kvar: "Puissance réactive (KVAR)",
  active_energy_kwh: "Énergie active (KWH)",
  apparent_energy_kvah: "Énergie apparente (KVAH)",
  pf: "PF",
  text: "Texte",
  energy_source: "Source d'énergie",
  scope: "Portée",
  system_logs: "Registres système",
  system_logbook: "Livre de bord du système",
  esg_data_log: "ESG Registre des données",
  add_esg_data: "Ajouter ESG Données",
  add_esg_data_log: "Ajouter ESG Registre des données",
  source_type: "Type de source",
  unit_of_measurement: "Unité de mesure",
  from_date_optional: "Date (facultative)",
  emission_factor: "Facteur d'émission (kgCO2)",
  create_dynamic_logger: "Créer un enregistreur dynamique",
  dynamic_log: "Log dynamique",
  primary_field_label: "Étiquette de champ primaire",
  primary_field_type: "Type de champ primaire",
  primary: "Enseignement primaire",
  additional_fields: "Champs supplémentaires",
  field_label: "Étiquette du champ",
  field_type: "Type de champ",
  choose_list: "Choisir une liste",
  list: "Liste",
  create_capacity_logger: "Créer un enregistreur de capacité",
  capacity_log: "Registre des capacités",
  create: "Créer",
  create_production_logger: "Créer un enregistreur de production",
  production_log: "Registre de production",
  production_logger: "Enregistreur de production",
  input_type: "Type d'entrée",
  input: "Entrée",
  input_name: "Nom de l'entrée",
  output_type: "Type de sortie",
  output: "Produit",
  output_name: "Nom de la sortie",
  output_params: "Params de sortie",
  compute_production: "Production de calcul",
  compute_energy: "Calculer l'énergie",
  energy_param_type: "Type de paramètre énergétique",
  energy_device: "Dispositif énergétique",
  energy_param: "Param énergétique",
  production_operator: "Opérateur de production",
  production_correction_factor: "Facteur de correction de la production",
  output_operator: "Opérateur de sortie",
  output_correction_factor: "Facteur de correction de la production",
  input_operator: "Opérateur d'entrée",
  input_correction_factor: "Facteur de correction de la production",
  energy_unit: "Unité énergie",
  production_unit: "Unité de production",
  production: "Production",
  create_data_logger: "Créer un enregistreur de données",
  data_log: "Registre des données",
  add_param: "Ajouter Param",
  param_id: "Identification du paramètre",
  create_batch_production_logger:
    "Créer un enregistreur de production par lots",
  batch_tracing_log: "Journal de repérage des lots",
  log_name: "Nom du journal",
  location: "Lieu",
  info: "Informations",
  remarks: "Commentaires",
  code_will_be_available_to_log_by_default:
    "code sera disponible pour se connecter par défaut",
  fields: "Champs",
  additional_info: "Informations supplémentaires",
  action: "Décision",
  report_type: "Type de rapport",
  create_parameter_report: "Créer un rapport de paramètres",
  data_processing: "Traitement des données",
  addition: "Ajout",
  singular: "Singular",
  report: "Rapport annuel",
  energy_parameters_kwh: "Paramètres énergétiques (KWH)",
  apparent_energy_KVAh: "Énergie apparente KVAh",
  maximum_demand_MD: "Demande maximale (MD)",
  excel_frequency: "Fréquence Excel",
  multiplier: "Multiplieur",
  single: "Personne seule",
  incomers: "Revenus",
  sections: "Sections",
  energy_distribution_report: "Distribution d'énergie Rapport annuel",
  root_machine: "Machine racine",
  tariff_on: "Tarif",
  add_root_common_machine: "Ajouter la racine et la machine commune",
  common_machine: "Machine commune",
  energy_bill_distribution_report:
    "Rapport sur la distribution des projets de loi sur l'énergie",
  create_consolidated: "Créer",
  choose_report: "Choisir un rapport",
  id: "Idée",
  timeframed_sec: "Délai",
  create_dynamic_excel_report: "Créer un rapport dynamique Excel",
  update_dynamic_excel_report: "Mettre à jour le rapport Dynamic Excel",
  add_sheet: "Ajouter une feuille",
  sheet_name: "Nom de la feuille",
  timestamp: "Timbre",
  sheet: "Feuille",
  sheets: "Feuilles",
  sheet_id: "Numéro de feuille",
  delete_sheet: "Supprimer la feuille",
  report_name: "Nom du rapport",
  update_details: "Mettre à jour les détails",
  unit: "Unité",
  parameters: "Paramètres",
  send_excel_file: "Envoyer un fichier Excel",
  update_excel_template_based_report:
    "Mettre à jour le rapport basé sur le modèle Excel",
  create_excel_template_based_report:
    "Créer un rapport basé sur le modèle Excel",
  upload_excel_template_xlsx: "Télécharger le modèle Excel (xlsx)",
  param_equation: "Équation de Param",
  edit_sheets: "Modifier les feuilles",
  esg_scope_logging: "Journalisation du périmètre ESG",
  targets: "Cibles",
  row_no: "Numéro de ligne",
  column_no: "Numéro de colonne",
  row_number: "Numéro de ligne",
  column_number: "Numéro de colonne",
  colummn_number: "Numéro de colonne",
  no_sheets_added: "Pas de feuilles ajoutées",
  process_based_usage_sheet: "Feuille d'utilisation basée sur le processus",
  process: "Processus",
  process_label: "Étiquette du procédé",
  add_tag_types: "Ajouter des types d'étiquettes",
  add_process_to_list: "Ajouter un processus à la liste",
  machine_tag_based_daily_data_sheet:
    "Tag machine basé quotidiennement Données Feuille",
  machine_label: "Étiquette de machine",
  add_machine_to_list: "Ajouter une machine à la liste",
  add_tag_type_to_list: "Ajouter un type d'étiquette à la liste",
  shift_based_usage_sheet: "Feuille d'utilisation basée sur le décalage",
  status_for_process: "Processus",
  status_for_machine: "Machine",
  esg_summary_item: "Résumé",
  esg_details_item: "Détails",
  esg_scope1_label: "Portée 1",
  esg_scope2_label: "Portée 2",
  esg_scope3_label: "Portée 3",
  esg_scopes_list_label: "Portée",
  transpose: "Transposition",
  process_based_timeframed_data_sheet:
    "Délai établi en fonction du processus Fiche technique",
  add_pocess_to_list: "Ajouter un processus à la liste",
  tag_types: "Types d'étiquette",
  " consolidated_shift_based_usage_data_sheet":
    "Fiche de données consolidée sur l'utilisation par poste",
  date_format: "Format de date",
  include_process_total: "Inclure le total du processus",
  machine_group_based_usage_sheet: "Fiche d'utilisation par groupe de machines",
  machine_group_label: "Étiquette du groupe machine",
  machine_group: "Groupe de machines",
  add_machine_group_to_list: "Ajouter un groupe de machines à la liste",
  parameter_based_usage_data_sheet:
    "Fiche de données sur l'utilisation des paramètres",
  "show_month_start&end_data":
    "Afficher les données de début et de fin du mois",
  statistics: "Statistiques",
  add_param_to_list: "Ajouter un paramètre à la liste",
  add_timestamp_to_list: "Ajouter un timestamp à la liste",
  parameter_group_based_timeframed: "Parameter Group Based Timeframed",
  param_group_label: "Étiquette du groupe Param",
  param_group: "Groupe Param",
  add_param_group_to_list: "Ajouter le groupe Param à la liste",
  dynamic_logger_based_data_sheet:
    "Enregistreur dynamique Données basées Feuille",
  dynamic_log_label: "Étiquette de log dynamique",
  parameter_equation_based_sheet: "Feuille basée sur l'équation des paramètres",
  data_source: "Source des données",
  equation_label: "Étiquette d'équation",
  add_equation_to_list: "Ajouter l'équation à la liste",
  absolute_values: "Valeurs absolues",
  consumption_based_sheet: "Feuille de consommation",
  parameter_name: "Nom du paramètre",
  statistical_analysis_based_sheet: "Feuille d'analyse statistique",
  parameter_instantaneous_data_sheet: "Paramètres instantanés Fiche technique",
  create_sheet_column: "Créer une colonne de feuille",
  column_label: "Étiquette colonne",
  machine_id: "Identification de la machine",
  create_machine_list: "Créer une liste de machines",
  create_param_list: "Créer un paramètre Liste",
  create_sheets: "Créer des feuilles",
  create_shift: "Créer un Maj",
  description_optional: "Désignation (facultative)",
  create_sku: "Créer un UGS",
  create_location: "Créer un emplacement",
  location_type: "Type de localisation",
  from_location: "Depuis l'emplacement",
  to_location: "Vers l'emplacement",
  event_type: "Type d'événement",
  create_location_event_type: "Créer l'emplacement Type d'événement",
  create_list: "Créer une liste",
  create_param_equation: "Créer l'équation de Param",
  positive_params: "Params positifs",
  params_to_be_substracted: "Params à soustraire",
  create_combo_param: "Créer Combo Param",
  inter_param_operator: "Opérateur interparam",
  correction_operator: "Opérateur de correction",
  correction_operand: "Fonctionnalité",
  create_calculated_param: "Créer un paramètre calculé",
  calculated_param: "Param calculé",
  correction_factor: "Facteur de correction",
  param1_type: "Param 1 Type",
  param1: "Param 1",
  param1_operator: "Param 1 opérateur",
  param1_operand: "Param 1 Operand",
  param2_type: "Param 2 Type",
  param2: "Param 2",
  param2_operator: "Param 2 Opérateur",
  param2_operand: "Param 2 Operand",
  create_esg_scope_conversion: "Créer ESG Conversion de la portée",
  category: "Catégorie",
  get_from_param: "Obtenez de Param",
  choose_param: "Choisir Param",
  create_target: "Créer une cible",
  create_word: "Créer un mot",
  words: "Mots",
  choose_OEM: "Choisir OEM",
  get_the_models: "Obtenez les modèles",
  get_oem_devices: "Obtenez OEM Dispositifs",
  model_number: "Numéro de modèle",
  register: "Registre",
  add_modbus_gateway: "Ajouter la passerelle Modbus",
  " add_modbus_TCP_gateway": "Ajouter la passerelle Modbus TCP",
  IPAddress: "Adresse IP",
  port_number: "Numéro de port",
  HubID: "ID du hub",
  baud_rate: "Taux",
  parity: "Parité",
  stop_bit: "Arrêter Bit",
  update_wifi: "Mettre à jour le Wifi",
  SSID: "SSID",
  password: "Mot de passe",
  restart_enture: "Redémarrer l'ouverture",
  force_update_configs: "Configs de mise à jour de la force",
  activity_name: "Nom de l'activité",
  board: "Conseil",
  calender: "Calandre",
  maintenance: "Entretien",
  welcome: "Bienvenue",
  logout: "Déconnexion",
  help: "Aide",
  accounts: "Comptes",
  organisation: "Organisation",
  pointer_color_optional: "Couleur du pointeur (facultatif)",
  user_id: "ID utilisateur",
  confirm_new_password: "Confirmer un nouveau mot de passe",
  service: "Services",
  send_grid: "Envoyer la grille",
  default_enture_view: "Affichage d'ouverture par défaut",
  permissions: "Autorisations",
  areas: "Domaines",
  remark: "Remarque",
  days: "Jours",
  yesterdays_aqi: "Hier, l'AQI",
  aqi: "AQI",
  choose_air_quality_device: "Choisir l'appareil de qualité de l'air",
  delete_this_device: "Supprimer ce périphérique",
  air_quality_label: "Étiquette qualité de l'air",
  humidity: "Humidité",
  temperature: "Température",
  watcher: "Observateur",
  area_reference: "Référence géographique",
  write_data: "Écrire les données",
  day_of_week: "Jour de la semaine",
  month: "Mois",
  diagnostics_notification_groups: "Groupes de notification des diagnostics",
  hub_name: "Nom du centre",
  hub_model: "Modèle Hub",
  hub_type: "Type de hub",
  update_your_MQTT_bridge_config: "Mettre à jour votre Config Bridge MQTT",
  MQTT_bridge_mode: "MQTT Mode passerelle",
  broker_address: "Adresse du courtier",
  update_your_OPC_config: "Mettre à jour votre config OPC",
  OPC_client_mode: "Mode client du CPVP",
  OPC_server_URL: "URL du serveur OPC",
  user_auth: "Auth utilisateur",
  mode: "Mode",
  security_policy: "Politique de sécurité",
  Update_your_Edge_Hub_restart_Schedule:
    "Mettez à jour votre Calendrier de redémarrage du Hub Edge",
  auto_restart: "Redémarrer automatiquement",
  time_24Hrs: "Temps (24 heures)",
  modbus_ping_latency: "Modbus Ping Latence",
  modbus_unhealthy_wait: "Modbus Mauvaise santé",
  edit_written_log_book: "Modifier le journal écrit",
  field_cant_change: "Ce champ ne peut pas changer",
  re_write: "Réécrire",
  write_in_logbook: "écrire dans le journal de bord",
  write: "Écrire",
  download_template: "Télécharger le modèle",
  write_in_logbook_csv: "Écrire dans le livre de connexion par .csv",
  upload_file: "Télécharger le fichier",
  batch: "Lot",
  move_batch: "Déplacer le lot",
  on: "À",
  off: "Arrêt",
  fast_mode: "Mode rapide",
  batch_shipping_details: "Expédition par lots Détails",
  vehicle_no: "Numéro du véhicule",
  net_weight: "Poids net",
  actual_weight: "Poids réel",
  vendor: "Fournisseur",
  loaded_by: "Chargé par",
  verified_by: "Vérifié par",
  document_no: "Numéro du document",
  multiple_machine_param: "Machines et paramètres multiples",
  map_paramid_paramequation_to_row:
    "Carte des équations d'ID ou de Param À la ligne",
  add_current_date_to_sheet: "Ajouter la date actuelle à la feuille",
  create_parameter_from_template: "Créer un paramètre Modèle",
  selected_parameters_count: "Nombre de paramètres sélectionnés",
  restricted_user_access: "Accès réservé aux utilisateurs",
  select_all_parameters: "Sélectionner tous les paramètres",
  edit_angular_gauge_widget: "Modifier le widget de jauge angulaire",
  add_angular_gauge_widget: "Ajouter un widget de jauge angulaire",
  widget_label: "Étiquette Widget",
  widget_width: "Largeur du widget",
  widget_height: "Hauteur du widget",
  param_label: "Étiquette Param",
  choose_parameter: "Choisir le paramètre",
  processing: "Traitement",
  edit_banner_widget: "Modifier le widget de bannière",
  add_banner_widget: "Ajouter une bannière Widget",
  edit_bartd_widget: "Modifier la barre Widget TD",
  add_bar_widget: "Ajouter un widget de barre",
  yaxis_label: "Y - Étiquette Axis",
  xaxis_label: "X - Étiquette Axis",
  y1axis_label: "Y1 - Étiquette de l'axe",
  y1axis: "axe y1 ",
  y2axis: "axe y2 ",
  y2axis_label: "Y2 - Étiquette de l'axe",
  edit_dual_axis_bar_trend_widget:
    "Modifier le widget de tendance de la barre à double axe",
  add_dual_axis_bar_trend_widget:
    "Créer un widget de tendance à barre à deux axes",
  y1_graph_type: "Type de graphique de l'axe Y1",
  y2_graph_type: "Type de graphique de l'axe Y2",
  idealValue: "idéal Valeur",
  idealLabel: "idéal Étiquette",
  add_parameters: "Ajouter des paramètres",
  add_equation: "Ajouter l'équation",
  add_params: "Ajouter des paramètres",
  enter_of_machines: "Entrez le nombre de machines",
  edit_bar_trend_td_widget: "Modifier la tendance de la barre Widget TD",
  add_bar_trend_td_widget: "Ajouter une barre Trend Widget TD",
  graph_type: "Type de graphique",
  aggregation: "Agrégation",
  default_graph_type: "Type de graphique par défaut",
  bar_mode: "Mode barre",
  show_ideal_line: "Afficher la ligne idéale",
  equations: "Équations",
  edit_bitwise_word_widget: "Modifier le widget Word Bitwise",
  add_bitwise_word_widget: "Ajouter un widget Word Bitwise",
  show_bit_status: "Afficher l'état du bit",
  edit_bullet_gauge_widget: "Modifier le widget de jauge de bulle",
  add_bullet_gauge_widget: "Ajouter le widget Bullet Gauge",
  reference_value_for: "Valeur de référence pour",
  gauge_type: "Type de jauge",
  indicator_color: "Couleur de l'indicateur",
  busbar_temperature_graph_widget:
    "Graphique de température de la barre de bus Widget",
  busbar_module: "Module de barre de bus",
  edit_consumption_gauge_widget: "Modifier la consommation Gage Widget",
  add_consumption_gauge_widget: "Ajouter l'indicateur de consommation",
  linearGauge: "Gauge linéaire",
  show_target: "afficher la cible",
  healthy_on_below_target: "Cible inférieure en santé",
  edit_consumption_pie_widget: "Modifier la consommation Widget à tarte",
  add_consumption_pie_widget: "Ajouter le widget de consommation Pie",
  add_color_break_point: "Ajouter un point de rupture de couleur",
  custom_color: "Couleur personnalisée",
  color: "Couleur",
  edit_heat_map_widget: "Modifier le widget de carte de chauffage",
  add_heat_map__widget: "Ajouter une carte de chauffage Widget",
  choose_equation: "Choisir Équation",
  label_star: "Étiquette*",
  color_star: "Couleur*",
  operator1_star: "Opérateur 1*",
  value1_star: "Valeur 1*",
  operator2_star: "Opérateur 2*",
  actions: "Actions",
  condition: "Conditions",
  status_color: "Couleur d'état",
  text_view: "Affichage du texte",
  edit_process_Status_widget: "Éditer l'état du processus Widget",
  add_process_Status_widget: "Ajouter l'état du processus Widget",
  edit_machine_Status_widget: "Modifier l'état de la machine Widget",
  add_machine_Status_widget: "Ajouter l'état de la machine Widget",
  choose_process: "Choisir les processus",
  choose_machine: "Choisir des machines",
  table_header_label: "En-tête de tableau",
  edit_table_TD_widget: "Modifier le tableau Widget TD",
  add_table_TD_widget: "Ajouter le tableau Widget TD",
  configure_table: "Configurer le tableau",
  edit_target_based_trend_widget: "Éditer Trend Widget basé sur la cible",
  add_target_based_trend_widget: "Ajouter un widget Trend basé sur la cible",
  target: "Objectif",
  target_line_label: "Étiquette de ligne cible",
  edit_usage_score_card: "Modifier l'utilisation Carte de pointage Widget",
  add_usage_score_card: "Ajouter une carte de pointage d'utilisation Widget",
  compare_to: "Comparer à",
  assign_color: "Attribuer la couleur",
  enter: "Entrez",
  gauge_to_dashboard: "Tableau de bord",
  widget_types: "Types de widget",
  from_value: "Valeur",
  to_value: "Valeur",
  machine_status: "État de la machine",
  deviceA_type: "Type de périphérique A",
  templates: "Modèles",
  add_triggers_to_parameter: "Ajouter des déclencheurs au paramètre",
  triggers: "Déclencheurs",
  show_filter: "Afficher le filtre",
  view_id: "Afficher l'ID",
  move_up: "En haut",
  move_down: "Déplacer vers le bas",
  multiplier_types: "_Types de multiplicateurs",
  energy_report: "Rapport sur l'énergie",
  add_machine_param: "Ajouter la machine Param",
  aliasing: "Aliénant",
  no_zero_read: "Pas de zéro lire",
  remove_negative_entropy: "Supprimer l'entropie négative",
  calculate_consumption: "Calculer la consommation",
  disabled: "Handicapés",
  limiter_value: "Valeur limitatrice",
  machine_scroll_view_page: "Scroll machine Afficher la page",
  create_mannual_parameter: "Créer un paramètre annuel",
  restrict_user_access: "Restreindre les accès des utilisateurs",
  add_device_parameter: "Ajouter un paramètre de périphérique",
  entropy: "Entropie",
  idle_latency: "Latence de la poche",
  add_OPC_parameter: "Ajouter le paramètre OPC",
  node_id: "ID du nœud",
  byte_format: "Format d'octet",
  param_scroll_view_page: "Param Scroll Afficher la page",
  timeframed_sec_report: "Rapport de la SEC sur les délais",
  prod_unit: "Groupe des produits",
  SEC_unit: "SEC Unité",
  energy_parameters: "Paramètres énergétiques",
  prod_param_type: "Prod Param Type",
  production_parameters: "Paramètres de production",
  no_of_intervals: "Nombre d'intervalles",
  granular_data: "Données granulaires",
  csv: "CSV",
  delete_rule: "Supprimer la règle",
  enable_disable_all_parameters: "Activer/désactiver tous les paramètres",
  disable: "Désactiver",
  enable: "Activer",
  edit_computed_parameter: "Modifier le paramètre calculé",
  quantization: "Quantification",
  command: "Commande",
  register_size: "Taille de l'enregistrement",
  consumer_ID: "Identification du consommateur",
  site_id: "ID du site",
  get_data: "Obtenir des données",
  update_param_type: "Mettre à jour Param Type",
  write_in_data_logbook: "Écrire dans le carnet de données",
  daily_data: "Données quotidiennes",
  log_entry: "Entrée du journal",
  enable_Scroll_mode: "Activer le mode défiler",
  multiple_areas: "Régions multiples",
  time_of_day: "Heure du jour",
  capacity_logger: "Enregistreur de capacité",
  provider: "Fournisseur",
  total_sites_received_bills_MTD: "Total des sites reçus Factures MTD",
  sites_yet_to_receive_bills: "Sites encore à recevoir des projets de loi",
  total_sites: "Total des sites",
  sites_received_bills_MTD: "%Sites reçus des factures MTD",
  total: "Total général",
  total_bills_received_mtd: "Total des factures reçues DMT",
  arrear_1: "Arrée 1",
  arrear_2: "Arrée 2",
  no_reading: "Aucune lecture",
  bills_received_in_5_days: "Projets de loi reçus dans les 5 derniers jours",
  site_wise_status: "Statut sage du site autre que le statut OK",
  site_wise_final_summary: "Site Wise Résumé final",
  source_link: "Lien Sourcce",
  data_type: "Type de données",
  select_an_image_to_upload: "Sélectionnez une image à télécharger",
  user_group_Id: "ID du groupe utilisateur",
  user_group_name: "Nom du groupe d' utilisateurs",
  delay_in_mins: "Délai (minutes)",
  delete_task: "Supprimer la tâche",
  watchers: "Observateurs",
  comments: "Commentaires",
  asset_under_maintenance: "Actifs en cours d'entretien",
  start_time: "Heure de début",
  end_time: "Heure de fin",
  download_latest_usage_table: "Télécharger le dernier tableau d'utilisation",
  tag: "Balise",
  add_target_data: "Ajouter des données cibles",
  edit_target: "Modifier la cible",
  delete_target: "Supprimer la cible",
  prop: "Prop",
  excel_template_based_report: "Rapport basé sur le modèle Excel",
  configured_hub: "Hub configuré",
  configured_relay: "Relais configuré",
  desired_state: "État désiré",
  configured_notification_group: "Groupe de notification configuré",
  email_ids: "Numéros de courrier électronique",
  logs: "journaux",
  unit_id: "Numéro d'unité",
  download_report: "Télécharger le rapport",
  frequency: "Fréquence",
  ip: "IP",
  edit_wifi: "Modifier le Wifi",
  details: "Détails",
  connected_to: "Connecté à",
  software_version: "Version du logiciel",
  mac_address: "Adresse MAC",
  opc_client_enabled: "Client OPC activé",
  MQTT_bridge_enabled: "Pont MQTT activé",
  hub_specification: "Spécification Hub",
  hub_details: "Détails du Hub",
  restart_schedule: "Redémarrer le calendrier",
  restart_device: "Redémarrer le périphérique",
  set_up_restart_time: "Régler l'heure de redémarrage",
  connectivity_logs: "Logs de connectivité",
  internal_cache_size: "Taille du cache interne",
  default: "Par défaut",
  configs: "Configures",
  processed: "Traitement",
  space_available: "Espace disponible",
  kb: "KB",
  mb: "PAYS",
  last_ping: "Dernière Ping",
  toggle: "Basculer",
  configured_creds: "Creds configurés",
  psk: "PSK",
  set_value: "Définir la valeur",
  time_current_time:
    "Temps (l'heure actuelle sera prise si elle n'est pas mentionnée)",
  Set: "Jeu",
  health: "Santé",
  port: "Port",
  mqtt_params: "Mqtt-Params",
  delete_device: "Supprimer le périphérique",
  view_details: "Afficher les détails",
  parameter_id: "ID du paramètre",
  email_address: "Adresse électronique",
  attached_triggers: "Déclencheurs fixés",
  parameter_details: "Détails du paramètre",
  enable_write: "Activer l'écriture",
  disable_write: "Désactiver l'écriture",
  offset: "Dépassement",
  no_of_parameters: "Nombre de paramètres",
  creation_time: "Heure de création",
  not_configured: "Non configuré",
  add_rule: "Ajouter une règle",
  sl_no: "Numéro",
  targeted_by_value: "Cible par valeur",
  configured_action: "Action configurée",
  add_action: "Ajouter une action",
  action_id: "ID action",
  delete_user: "Supprimer l' utilisateur",
  bit: "Un peu",
  low: "Faible",
  high: "Élevé",
  esg_scope_conversion: "ESG Conversion de la portée",
  batch_production_logs: "Production par lots Registres",
  esg_data_logs: "ESG Registres de données",
  remove_from_dashboard: "Supprimer du tableau de bord",
  data_logs: "Registres de données",
  select_device: "Sélectionner le périphérique",
  fetch_connectivity_logs: "Récupération des journaux de connectivité",
  hub_internet_connectivity_logs: "Connectivité Internet Hub Registres",
  modbus_gateways: "Passerelles Modbus",
  device_switched_on: "Le périphérique est activé",
  device_switched_off: "Le périphérique est éteint",
  parameter_report: "Rapport de paramètres",
  timeframed_specific_energy_consumption_report:
    "Rapport sur la consommation d'énergie spécifique dans les délais",
  ebill_distribution_report:
    "Rapport sur la distribution des projets de loi électroniques",
  root_user_management: "Gestion de l'utilisateur racine",
  user_acces_management: "Gestion de l'accès des utilisateurs",
  inference: "Inférence",
  turn_off: "Arrêt",
  turn_on: "Allumer",
  diagram_pallette: "Diagramme Pallette",
  add_component: "Ajouter un composant",
  components: "Composantes",
  add_text: "Ajouter un texte",
  add_realtime_data: "Ajouter des données en temps réel",
  shapes: "Formes",
  lines: "Lignes",
  text_realtime_component: "Composant texte en temps réel",
  shapes_pallette: "Pallettes de formes",
  lines_pallette: "Lignes Pallette",
  click_choose: "Cliquez et choisissez les formes à ajouter au SLD",
  choose_line: "Choisissez Ligne à ajouter au SLD",
  choose_image: "Choisir l'image",
  upload_image_to_add: "Télécharger l'image à ajouter au SLD",
  add_realtime_textdata: "Ajouter des données en temps réel ou texte au SLD",
  update_user: "Mettre à jour l' utilisateur",
  add_watcher: "Ajouter un observateur",
  premise_area: "Zone résidentielle",
  create_computed_param: "créer calculé Param",
  create_derived_param: "créer un paramètre dérivé",
  proceed_to_ship: "Aller au navire",
  esg_data_collection: "ESG Collecte de données",
  esg_scope_details: "ESG Détails de la portée",
  select_custom_date_range: "Sélectionnez Personnalisé Gamme de dates",
  days_365: "365 jours",
  days_180: "180 jours",
  days_30: "30 jours",
  create_user_for_SSO: "Créer un utilisateur pour SSO",
  add_widget: "Ajouter un widget",
  add_machine_status_rules: "Ajouter les règles d'état de la machine",
  add_trigger: "Ajouter le déclencheur",
  add_logged_parameter: "Ajouter un paramètre fixe",
  create_mannual_param: "Créer un param annuel",
  add_opc_param: "Ajouter OPC Param",
  create_write_block: "Créer un bloc d'écriture",
  all_parameters: "Tous les paramètres",
  edit_device: "Éditer le périphérique",
  edit_modbus_device: "Modifier le périphérique Modbus",
  edit_mqtt_device: "Modifier le périphérique Mqtt",
  limit_entropy: "Entropie limite",
  emails: "Courriels",
  power_factor: "Moteur(PF)",
  tarrif_rate: "Tarif",
  apparent_power: "Puissance apparente",
  reactive_power: "Puissance réactive",
  active_power: "Puissance active",
  energy_usage_view_page: "Page de vue sur l'utilisation de l'énergie",
  switch_organisation: "Switch Organisation",
  wireless_creds: "Creds sans fil",
  latest: "Dernière",
  value_is: "Valeur",
  please_take_necessary_action: "S'il vous plaît prendre nécessaire Décision",
  edit_role: "Modifier le rôle",
  delete_role: "Supprimer le rôle",
  today: "Aujourd'hui",
  days_4: "4 jours",
  due: "Attendu",
  move_to_done: "Déplacement vers la fin",
  power_system_fault:
    "Nombre de défaillances du système électrique (la semaine dernière)",
  alarms: "Alarmes",
  electricity_consumption: "Consommation d'électricité (mois jusqu'à la date)",
  average_demand: "Demande moyenne",
  water_use_intensity: "Intensité de l'utilisation de l'eau",
  average_water_cost: "Coût moyen de l'eau (mois)",
  water_use_efficiency: "Efficacité de l'utilisation de l'eau",
  batch_logs: "Registres de lots",
  edit_log: "Modifier le journal",
  delete_log: "Supprimer le journal",
  dates: "Dates",
  log_details: "Détails du journal",
  view: "Affichage",
  scope1_emission: "Portée 1 Émissions",
  scope2_emission: "Portée 2 Émissions",
  lmtd: "LMTD",
  company_data: "Données de l'entreprise",
  historian: "Historien",
  from_optional: "À partir de (facultatif)",
  to_optional: "À (facultatif)",
  trends: "Tendances",
  generate_trend: "Générer une tendance",
  by: "Par",
  check_logs: "Vérifier les journaux",
  workspaces: "Espaces de travail",
  types_of_nodes_connected: "Types de nœuds connectés",
  types_of_modbus_device_connected: "Types de périphériques Modbus connectés",
  no_of_registers: "Nombre de registres",
  write_register: "Inscrivez-vous",
  setting_view: "Configuration de la vue",
  sign_up: "Inscription",
  reset: "Réinitialiser",
  mobile_number: "Numéro mobile",
  nebeskie_labs: "Nebeskie Labs",
  please_register_and_send_otp:
    "Veuillez saisir votre email enregistré Id et cliquez sur Envoyer OTP.",
  reset_password: "Réinitialiser le mot de passe",
  back_to_login: "Retour à la connexion",
  otp: "OTP",
  send_otp: "Envoyer OTP",
  org_alias: "Org Alias",
  please_stand_by: "Veuillez vous tenir prêt",
  total_usage_this_month: "Utilisation totale Ce mois-ci",
  total_usage_last_month: "Utilisation totale le mois dernier",
  generate_report_manually: "Générer le rapport manuellement",
  energy_distribution_detailed_report:
    "Distribution d'énergie Rapport détaillé",
  the_report_is_processing: "Le rapport est en cours de traitement",
  enter_active_energy_manually: "Entrez l'énergie active manuellement",
  include_others: "Inclure les autres",
  bill_amount: "Montant de la facture",
  yes_delete_it: "Oui, supprimer",
  create_write_param: "Créer un paramètre d'écriture",
  report_list: "Liste de rapports",
  plant_id: "Identification de l'installation",
  plant_name: "Nom de la plante",
  plant_address: "Adresse de la plante",
  add_selected: "Ajouter la sélection",
  clear_selection: "Effacer la sélection",
  real_time_value: "Valeur réelle",
  default_status: "État par défaut",
  default_label: "Étiquette par défaut",
  default_color_optional: "Couleur par défaut Facultatif",
  rule_based_status: "Statut fondé sur les règles",
  show_label_only: "Afficher l'étiquette seulement",
  show_unit: "Afficher l'unité",
  status_rules: "Règles de statut",
  configure: "Configuration",
  monthly_usage: "Utilisation mensuelle",
  meter_consumption_kwh: "Consommation du compteur (kWh)",
  total_unit: "Total unité",
  region: "Région",
  average_energy_consumed: "Énergie moyenne consommée",
  average_production: "Production moyenne",
  average_sec: "Moyenne SEC",
  total_production: "Production totale",
  total_energy_consumed: "Total énergie consommée",
  max_sec: "Max SEC",
  device_1: "Appareil 1",
  device_parameter_1: "Paramètres du périphérique 1",
  device_2: "Appareil 2",
  device_parameter_2: "Paramètres du périphérique 2",
  create_param: "Créer un paramètre",
  update_tags: "Mettre à jour les étiquettes",
  remove_from_process: "Supprimer du processus",
  machine_parameters: "Paramètres de la machine",
  energy: "Énergie",
  power: "Puissance",
  remove_from_featured: "Supprimer de l'écran",
  other_parameters: "Autres paramètres",
  add_to_featured: "Ajouter à la présentation",
  logged_parameters: "Paramètres fixes",
  tags: "Étiquettes",
  machine_status_rules: "Règles d'état de la machine",
  connected_communication_devices: "Dispositifs de communication connectés",
  mqtt_devices: "Dispositifs Mqtt",
  modbus_devices: "Dispositifs Modbus",
  total_no_of_params: "Nombre total de params",
  edge_devices: "Dispositifs de bord",
  machines_configured: "Machines configurées",
  reports_configured: "Rapports configurés",
  views_configured: "Vues configurées",
  send_email_notification: "Envoyer une notification par courriel",
  enture_send_email_notification:
    "Enture envoie un e-mail Notification au groupe d'utilisateurs sélectionné",
  agree_and_send: "Accepter & envoyer",
  ebill: "Bill",
  monthly_trend: "Tendance mensuelle",
  param_view: "Vue param",
  calories: "Calories",
  param_count_view: "Comte des params Affichage",
  created: "Créé",
  api_data_count_view: "API Nombre de données Affichage",
  diesel_emissions: "Émissions du diesel",
  eb_emissions: "EB Émissions",
  emissions_kg_co2: "Émissions kg-Co2",
  kpis: "ICR",
  jan_23: "23 janvier",
  feb_23: "Fév-23",
  mar_23: "23 mars",
  apr_23: "23 avril",
  may_23: "23 mai",
  jun_23: "Juin-23",
  lpg_emissions: "Émissions de GPL",
  petrol_emissions: "Émissions d'essence",
  bullet_gauge: "Courbe de balle",
  equipment: "Matériel",
  enter_no_of_machines: "Saisissez le nombre de machines",
  shift: "Déplacement",
  upload: "Envoi",
  image_upload: "Télécharger l'image",
  derived_parameter: "Paramètre dérivé",
  value_type: "_Type de valeur",
  enable_entropy: "Activer l'entropie",
  remove_decimal: "Supprimer Decimal",
  limiter_entropy: "Entropie limite",
  show_sku_id: "Afficher l'identifiant SKU",
  production_start_date: "Date de début de la production (facultatif)",
  production_start_time: "Temps de démarrage de la production (facultatif)",
  time: "Heure",
  temperature_c: "Température (c)",
  set_temperature_c: "Régler la température (C)",
  no_of_people: "Pas d'hommes",
  co2_level: "Niveau Co2",
  aqi_optional: "AQI Facultatif",
  delete_sheets: "Supprimer les feuilles",
  root_login: "Connexion racine",
  uam_login: "UAM Connexion",
  login: "Connexion",
  add_oem_logo: "Ajouter le logo OEM",
  devices: "Dispositifs",
  derived_data_units: "Unités dérivées/données",
  roles: "Rôles",
  engineering: "Génie",
  control_panel: "Panneau de configuration",
  views: "Vues",
  reports_analysis: "Rapports et analyses",
  actions_triggers: "Actions et déclencheurs",
  modules: "Modules",
  processes: "Processus",
  air_quality: "Qualité de l'air",
  manufacturing: "Industrie manufacturière",
  esg: "ESG",
  task_managment: "Gestion des tâches",
  sustainability: "Durabilité",
  groups: "Groupes",
  log_book: "Livre de bord",
  monitor: "Moniteur",
  enture_settings: "Paramètres d'ouverture",
  configuration: "Configuration",
  bar: "Barre",
  line: "Ligne",
  s7_device: "Dispositif S7",
  rack: "Sac",
  slot: "Fente",
  db_number: "Numéro DB",
  byte_offset: "Décalage d'octets",
  bit_offset: "Offset bit",
  data_length: "Longueur des données",
  val_type: "Type de valeur",
  create_s7_device: "Créer un périphérique S7",
  scope_label: "Portée",
  client_safety: "Sécurité des clients",
  edit: "Modifier",
  delete: "Supprimer",
  choose_param_to_monitor: "Choisir Param pour surveiller",
  rules: "Règles",
  add_to_row: "Ajouter à la ligne",
  add_usage: "Ajouter une utilisation",
  notifications: "Notifications",
  organisation_details: "Détails de l'organisation",
  profile: "Profil",
  user_access: "Accès de l'utilisateur",
  admin: "Administrateur",
  shifts: "changement",

  add_pie_TD_widget: "Ajouter un widget de tarte jusqu'à la date",
  edit_pie_TD_widget: "Modifier le widget Tarte jusqu'à la date",
  legend: "Legend",
  pie: "Pie",
  edit_bar_trend_widget: "Modifier le widget de tendance des barres",
  add_bar_trend_widget: "Ajouter un widget de tendance de barre",
  edit_horizantal_bar_trend_widget:
    "Modifier le widget de tendance de la barre horizontale",
  add_horizantal_bar_trend_widget:
    "Ajouter un widget de tendance à barre horizontale",
  too_long: "Trop long",
  trigger_logs: "Trigger Logs",
  create_trigger: "Créer Trigger",
  right_click_to_configure: "Clic droit pour configurer",
  width: "largeur",
  height: "hauteur",
  hide_value: "Masquer la valeur",
  edit_machine: "Modifier l'appareil",
  wait_time: "Temps d'attente",
  production_date: "Date de production",
  connectivity_status: "État de la connectivité",
  production_plans: "Plans de production",
  production_pending: "Production en attente",
  pending_shipment: "Expédition en attente",
  trace_logs: "Journaux de suivi",
  movement_events: "Événements de mouvement par lots",
  entropy_threshold: "Seuil d'entropie",
  threshold_value: "Valeur de seuil",
  pending_dispatch: "En attente d'expédition",
  dispatched: "Expédié",
  dispatch_details: "Détails d'expédition",
  dispatch: "Expédier ceci",
  raw_materials: "Matières premières",
  pour_qty: "Quantité versée",
  summary: "Résumé",
  optional: "Facultatif",
  param_mqtt_map: "Paramètres MQTT Map",
  machine_status_mqtt_map: "Machine Statut MQTT Map",
  edit_trigger_count_table_widget:
    "Widget Modifier le tableau du nombre de déclencheurs",
  add_trigger_count_table_widget:
    "Ajouter un widget de table de nombre de déclencheurs",
  count: "Le décompte",
  real_time_consumption: "Consommation en temps réel",
  search_view: "Vue de recherche",
  multiple_entries: "Plusieurs entrées",
  combined_parameter: "Paramètre combiné",
  oem_devices: "Appareils OEM",
  background_color: "Couleur d'arrière-plan",
  resize: "cambiar el tamaño",
  remove: "Eliminar",
  select_views: "Sélectionnez les vues",
  difference: "différence",
  sum: "somme",
  set_param_type_for_machine: "Set Param Type For machine",
  temperature_parameters: "Paramètres de température",
  area_name: "Nom de la zone",
  maintenance_due_on: "Entretien dû le",
  days_left: "Jours restants",
  enture_insights: "perspectives d'entreprise",
  edit_logged_parameter: "Modifier le paramètre enregistré",
  reports: "Rapports",
  till_date_pie_chart: "Diagramme circulaire à ce jour",
  till_date_bar_chart: "Diagramme en barres à ce jour",
  tod_billing_widget: "Widget de facturation TOD",
  bar_trend_widget: "Widget de tendance en barres",
  target_based_bar_trend_widget:
    "Widget de tendance en barres basé sur des cibles",
  gauge_widget: "Widget de jauge",
  consumption_pie_widget: "Widget de consommation en camembert",
  consumption_gauge_widget: "Widget de jauge de consommation",
  angular_gauge_widget: "Widget de jauge angulaire",
  table_widget: "Widget de table",
  banner_widget: "Widget de bannière",
  param_equation_banner_widget: "Widget de bannière d'équation paramétrique",
  shift_based_usage_table: "Tableau d'utilisation basé sur les équipes",
  bitwise_word_widget: "Widget de mot par bits",
  dynamic_log_widget: "Widget de journal dynamique",
  machine_status_timeline_widget:
    "Widget de chronologie du statut de la machine",
  process_or_machine_status_widget:
    "Widget de statut du processus ou de la machine",
  parameter_insights_widget: "Widget d'aperçus des paramètres",
  heat_map: "Carte thermique",
  usage_score_card_widget: "Widget de carte de score d'utilisation",
  busbar_live_monitoring: "Surveillance en direct du busbar",
  bullet_or_angular_guage_widget: "Widget de jauge angulaire ou de type bullet",
  dual_axis_bar_trend_widget: "Widget de tendance en barres à double axe",
  trigger_count_table_widget: "Tableau de comptage des déclencheurs",
  bar_trend_td_widget: "Widget de tendance en barres TD",
  horizantal_bar_trend_widget: "Widget de tendance en barres horizontales",
  parameter_status_based_usage_status: "Widget basé sur le statut du paramètre",
  choose_from_templates: "choisir parmi les modèles",
  shift_timeframed_trends:
    "Tendances temporelles basées sur les quarts de travail",
  candlestick_trends: "Tendances CandleStick",
  free_ram: "Free RAM",
  item: "Item",
  item_code: "Item Code",
  department: "Department",
  departments: "Departments",
  issue_vouchers: "Issue Vouchers",
  issue_voucher: "Issue Voucher",
  to_be_approved: "To Be Approved",
  to_be_issued: "To Be Issued",
  intended: "Intended",
  approved: "Approved",
  received: "Received",
  receiver: "Receiver",
  material: "material",
  material_group: "material group",
  material_desc: "Material Description",
  uom: "Unit Of Measurement",
  create_item_code: "Create Item Code",
  create_department: "Create Department",
  users: "Users",
  items: "Items",
  users_in_department: "Users in Department",
  authorisers_in_department: "Authorisers in Department",
  issuers_in_department: "Issuers in Department",
  item_issuer_list: "Item Issuer List",
  add_item_issuer: "Add Item Issuer",
  store: "Store",
  create_issue_voucher: "Create Issue Voucher",
  voucher_requests: "Voucher Requests",
  intended_qty: "Intended Qty",
  issued_qty: "Issued Qty",
  issue_items: "Issue Items",
  voucher_receipt: "Voucher Receipt",
  add_stock: "Add Stock",
  attached_targets: "Attached Targets",
  attach_target: "Attach Target",
  add_target_to_parameter: "Add Target to Parameter",
  empty: "Empty",
  add_day_wise_target: "Add Day wise Target",
  day_wise_targets: "Day Wise Targets",
  fetch_targets: "Fetch Targets",
  day_wise_machine_status_widget: "Widget de statut des machines par jour",
  add_day_wise_machine_status_widget:
    "Ajouter un widget de statut des machines par jour",
  edit_day_wise_machine_status_widget:
    "Modifier le widget de statut des machines par jour",
  add_machines: "Ajouter des machines",
  added_machines: "Machines ajoutées",
  consumption_start_time: "Consumption Start time",
};
