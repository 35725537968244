export default {
  checklist_status_sheet: "Checklist Status Sheet",
  all: "All",

  start: "Start",
  filling: "Filling",

  accept: "Accept",
  reject: "Reject",
  move_to_next_stage: "Move to Next Stage",
  save_as_draft: "Save as Draft",
  save_and_submit: "Save and Submit",
  submit_move_next_stage: "Submit and Move to next stage",
  submit_draft: "Submit as Draft",
  closure: "Closure",
  duration: "Duration",
  refill_request: "Request for Refill",
  is_be_cancelled: "is be Cancelled",
  delete_this: "Delete this",
  event: "Event",

  for: "For",
  of: "Of",
  downtime: "Downtime",

  metrics: "Metrics",
  oee_by_day: "OEE by day",

  ideal_cycle_time: "Ideal Cycle Time",

  minutes: "Minutes",
  production_time: "Production Time",
  back: "Back",
  down_times: "Down Times",

  top_5_reasons: "Top 5 Reasons",
  top_5_categories: "Top 5 Categories",
  enter_apparent_energy_manually: "Enter Apparent Energy Manually",

  oee: "OEE",
  reason: "Reason",
  add_downtime_reason: "Add downtime reason",

  default_target: "Default Target",

  enter_in_minutes: "Enter In Minutes",
  custome_fields: "Custom Fields",
  enter_data_in_minutes: "Enter data in minutes",
  enter_data_in_hours: "Enter data in hours",

  create_tod_billing_widget: "Create TOD Billing Widget",
  edit_tod_billing_widget: "Edit TOD Billing Widget",
  choose_from_machine_groups: "Choose From Machine Groups",
  tod_billing: "TOD Billing",
  choose: "Choose",

  create_shift_based_machine_usage_log: "Create Machine Usage Log",
  shift_based_machine_usage_log: "Machine Usage Log",
  completed: "Completed",
  rejected: "Rejected",
  refill_requested: "Refill Requested",
  in_process: "In Process",
  machine_operator: "Machine Operator",
  production_end_timestamp: "Production End Time",
  production_start_timestamp: "Production Start Time",
  running_time: "Running Time",
  idle_time: "Idle Time",
  down_time: "Down Time",

  date_row_index: "Date Row",
  date_position: "Date Position",
  save: "Save",
  discard: "Discard",
  adjust: "Adjust",

  safety_incident_sheet: "Safety Incident Sheet",
  safety_inspection_sheet: "Safety Inspection Sheet",

  ghg_emission_logs: "GHG Emission Logs",
  add_ghg_emission_data: "Add GHG Emission Logs",
  ghg_scope_conversion: "GHG Scope Conversion",
  create_ghg_scope_conversion: "Create GHG Scope Conversion",
  ghg_data_logs: "GHG Emission Data Logs",

  choose_inspection_type_optional: "Choose Inspection Type(Optional)",

  supplier_code: "Supplier Code",
  contact: "Contact",
  point_of_contact: "Point Of Contact",
  quantity_supplied: "Quantity Supplied",
  create_product: "Create Product",
  products: "Products",
  data: "Data",
  timezone: "Timezone",
  tariffs: "Tariffs",

  days_delta: "Days Delta",
  machine_groups: "Machine Groups",
  param_groups: "Parmeter Groups",
  user_groups: "User Groups",

  topic_prefix: "Topic Prefix",
  message_direction: "Message Direction",

  safety_inspection: "Safety Inspection",
  write_inspection_log: "Write Inspection Log",
  inspection_id: "Inspection ID",
  inspector_id: "Inspector ID",
  inspection_date: "Inspection Date",
  inspection_type: "Inspection Type",
  findings: "Findings",
  actions_required: "Actions Required",
  followup_date: "Follow Up Date",
  status: "Status",

  safety_incident: "Safety Incident",
  write_incident: "Write Incident",
  incident_description: "Incident Description",
  severity: "Severity",
  accident: "Accident",
  accident_with_loss: "Accident With Loss",
  closed: "Closed",
  corrective_action: "Corrective Action",
  employee_id: "Employee ID",
  apply: "Apply",
  choose_location_optional: "Choose Location (Optional)",
  locations: "Locations",
  locations_event_types: "Locations Event Types",
  create_tariff: "Create Tariff",
  default_tariff_val: "Default Tariff Value",
  add_tariff_data: "Add Tariff Data",
  edit_tariff: "Edit Tariff",
  delete_tariff: "Delete Tariff",
  tariff_value: "Tariff Value",

  machine_status_timeline: "Machine Status Timeline",
  total_duration: "Total Duration",
  generate: "Generate",
  remove_machine: "Remove Machine",
  edit_machine_status_timeline_widget: "Edit Machine Status Timeline Widget",
  add_machine_Status__timeline_widget: "Add Machine Status Timeline Widget",
  select_timeframe: "Select Timeframe",
  timeframe_type_shift: "Shift",
  timeframe_type_custom: "Custom",
  timeframe_type_yesterday: "Yesterday",
  edit_report_details: "Edit Report Details",
  download_current_template: "Download Current Template",
  date_column_index: "Date Column (Index)",
  select_area: "Select Area",
  select_shift: "Select Shift",
  dashboard_heading: "Dashboard",
  production_params: "Production Params",
  no_modules_configured: "No Modules Configured",
  dashboard_panel: "Dashboard Panel",
  choose_type: "Choose Type",
  name_is_required: "Name is Required",
  description_is_required: " Description is Required",
  required: "Required",
  you_have_to_select_something: "You have to select Something",
  name_must_be_less_than_30_characters: "Name must be less than 30 characters",
  name_must_be_less_than_25_characters: "Name must be less than 25 characters",
  name_must_be_less_than_50_characters: "Name must be less than 50 characters",
  description_must_be_less_than_35_characters:
    "Description must be less than 35 characters",
  description_must_be_less_than_55_characters:
    "Description must be less than 55 characters",
  number_must_be_less_than_6_digits: "Number Must Be Less Than 6 Digits",
  number_must_be_less_than_8_digits: "Number Must Be Less Than 8 digits",
  number_must_be_less_than_7_digits: "Number Must Be Less Than 7 digits",
  panel: "Panel",
  gauge: "Gauge",
  param: "Param",
  machine: "Machine",
  add_panel_to_dashboard: "Add Panel to Dashboard",
  edit_dashboard_panel: "Edit Dashboard Panel",
  param_type: "Param Type",
  workspace: "Workspace",
  device: "Device",
  name: "Name",
  language: "Language",
  time_duration: "Time Duration",
  submit: "Submit",
  cancel: "Cancel",
  close: "Close",
  usage: "Usage",
  peak_value: "Peak Value",
  current_data: "Current Data",
  parameter: "Parameter",
  computed_param: "Computed Param",
  derived_param: "Derived Param",
  t4_days: "4 Days",
  last_7_days: "Last 7 Days",
  last_30_days: "Last 30 Days",
  last_60_days: "Last 60 Days",
  last_90_days: "last 90 Days",
  last_180_days: "Last 180 days",
  last_365_days: "Lat 365 Days",
  last_1_hour: "last 1 hour",
  last_3_hours: "last 3 hours",
  last_6_hours: "last 6 hours",
  last_quarter: "last Quarter",
  reorder_views: "Reorder",
  create_view: "Create",
  clear_view_name_filter: "Clear Filters",
  view_type: "View Type",
  description: "Description",
  table_view: "Table View",
  widget_view: "Widget View",
  dynamic_timeframed_widget_view: "Dynamic Timeframed Widget View",
  diagram_view: "Diagram view",
  energy_usage_view: "Energy Usage View",
  machine_scroll_view: "Machine Scroll View",
  param_scroll_view: "Param scroll View",
  consolidated_view: "Consolidated View",
  air_quality_view: "Air Quality View",
  view_auto_change: "View Auto Change",
  no_views_configured: "No Views Configured",
  add_to_dashboard: "Add To Dashboard",
  edit_view_name: "Edit View Name",
  edit_view: "Edit View",
  refresh: "Refresh",
  create_machine: "Create Machine",
  add_machine: "Add Machine",
  sequence: "Sequence",
  machine_type: "Machine Type",
  physical_machine: "Physical Machine",
  virtual_machine: "Virtual Machine",
  positive_workspace: "Positive Workspace",
  positive_machine: "Positive Machine",
  negative_workspace: "Negative Workspace",
  negative_machine: "Negative Machine",
  label: "Label",
  positive_machine_Ids: "Positive Machine ID's",
  negative_machine_Ids: "Negative Machine ID's",
  trend_analysis_tools: "Trend Analysis Tools",
  energy_consumption_by_machines: "Energy Consumption By Machines",
  Create_report: "Create Report",
  view_report: "View Report",
  tarrif_on: "Tarrif On",
  active_energy: "Active Energy",
  apparent_energy: "Apparent Energy",
  automate_report: "Automate Report",
  add_root_machine: "Add Root Machine",
  add_common_machine: "Add Common Machine",
  notification_groups: "Notification Groups",
  add_machines_to_list: "Add Machines To List",
  lists: "Lists",
  machine_list: "Machine List",
  data_historian: "Data Historian",
  timeframed_trends: "Timeframed Trends",
  dual_axis_trend_comparison: "Dual Axis Trend Comparison",
  granular24_hrs_trend: "Granular24 Hrs Trend",
  machinewise_trend: "Machine Wise Trend",
  basic_statistical_trends: "Basic Statistical Trends",
  log_analysis: "Log Analysis",
  aqi_comparison_panel: "AQI Comparison Panel",
  time_of_day_trend: "Time Of Day Trend",
  param_historical_comparisons: "Param Historical Comparisons",
  energy_usage_trends: "Energy Usage Trends",
  machine_name: "Machine Name",
  param_name: "Param Name",
  trend_view: "Trend View",
  raw: "Raw",
  trend: "Trend",
  normalised: "Normalised",
  from_date: "From Date",
  to_date: "To Date",
  from: "From",
  to: "To",
  hour: "Hour",
  minute: "Minute",
  generate_report: "Generate Report",
  time_range: "Time Range",
  no_data_available: "No data available",
  daily: "Daily",
  monthly: "Monthy",
  weekly: "Weekly",
  week: "Week",
  yearly: "Yearly",
  device_name: "Device Name",
  machine_wise_comparison: "Machine Wise Comparison",
  stats_detailed_report: "Stats Detailed Report",
  log_type: "Log Type",
  choose_Log: "Choose Log",
  area: "Area",
  aq_device: "AQ Device",
  nebeskie_labs_office: "Nebeskie Labs Office",
  date_range: "Date_Range",
  this_year: "This Year",
  "last 6 months": "Last 6 Months",
  "last quater": "Last Quater",
  mtd: "MTD",
  ytd: "YTD",
  yesterday: "Yesterday",
  custom: "Custom",
  run_report: "RUN REPORT",
  create_report: "Create Report",
  usage_report: "Usage Report",
  consolidated_report: "Consolidated Report",
  dynamic_excel_report: "Dynamic Excel Report",
  energy_usage_report: "Energy Usage Report",
  create_action: "Create Action",
  action_type: "Action Type",
  notification_group: "Notification Group",
  address: "Address",
  number_of_registers: "Number Of Registers",
  value: "Value",
  send_notification: "Send Notification",
  write_parameter: "Write Parameter",
  relays: "Relays",
  relay_1: "Relay 1",
  relay_2: "Relay 2",
  relay_3: "Relay 3",
  relay_4: "Relay 4",
  email_must_be_valid: "Email Must Be Valid",
  it_should_be_a_number: "It Should Be A Number",
  it_should_be_a_valid_number: "It Should Be A Valid Number",
  operator: "Operator",
  operand: "Operand",
  targets: "Targets",
  target_value: "Target Value",
  wait_time_seconds: "Wait Time (seconds)",
  greater_than: "Greater Than",
  modulus_greater_than: "Modulus Greater Than",
  lesser_than: "Lesser Than",
  modulus_lesser_than: "Modulus Lesser Than",
  equal_to: "Equal To",
  not_equal_to: "Not Equal to ",
  modulus_equal_to: "Modulus Equal To",
  greater_than_equal_to: "Greater Than Equal",
  modulus_greater_than_equal_to: "Modulus Greater Than Equal To",
  lesser_than_equal_to: "Lesser Than Equal To",
  modulus_lesser_than_equal_to: "Modulus Lesser Than Equal To",
  bit_high: "Bit High",
  bit_low: "Bit Low",
  it_should_be_a_positive_whole_number: "It should be a Positive whole number",
  it_should_be_a_positive: "It should be a Positive",
  create_process: "Create Process",
  edit_process: "Edit Process",
  status_rule: "Status Rule",
  create_process_status_rule: "Create Process Status Rule",
  tag_type: "Tag Type",
  process_status: "Process Status",
  machines: "Machines",
  running: "Running",
  idling: "Idling",
  offline: "Offline",
  no_machines_configured: "No Machines Configured",
  delete_process: "Delete Process",
  add_machine_to_process: "Add Machine To Process",
  delete_premise_area: "Delete Premise Area",
  add: "ADD",
  add_to_list: "ADD TO LIST",
  search: "Search",
  switch_to: "Switch To",
  switch_to_base_org: "Switch To Base Org",
  switch: "Switch",
  create_new_organisation: "Create New Organisation",
  organisation_name: "Organisation Name",
  organisation_type: "Organisation Type",
  country_code: "Country Code",
  country: "Country",
  state: "State",
  clear: "Clear",
  change_organisation: "Change Organisation",
  organisation_id: "Organisation ID",
  update_your_oem_logo: "Update Your OEM Logo",
  select_a_logo_to_upload: "Select A Logo To Upload",
  submit_logo: "Submit Logo",
  create_workspace: "Create Workspace",
  hub_id: "HUB ID",
  name_must_be_less_than_45_characters: "Name Must Be Less Than 45 Characters",
  id_is_required: "ID is Required",
  no_space_allowed: "No Space Allowed",
  id_must_be_less_than_6_characters: "Id Must Be Less than 6 characters",
  edit_workspace: "Edit Workspace",
  delete_workspace: "Delete Workspace",
  add_modbus_device: "Add Modbus Device",
  add_device: "Add Device",
  oops: "Ooops !!",
  ok: "Ok",
  slave_id: "Slave ID",
  device_type: "Device Type",
  connection_type: "Connection Type",
  mGate: "mGate",
  make: "Make",
  model: "Model",
  name_must_be_less_than_33_characters: "Name must be less than 33 characters",
  name_must_be_less_than_11_characters: "Name must be less than 11 characters",
  it_should_be_a_valid_slave_id: "It should be a valid Slave ID",
  name_must_be_less_than_4_digits: "Name must be less than 4 digits",
  modbus_tcp: "Modbus TCP",
  modbus_rtu: "Modbus RTU",
  add_virtual_device: "Add Virtual Device",
  add_mqtt_device: "Add MQtt Device",
  topic: "Topic",
  identifier: "Identifier",
  identifier_value: "Identifier Value",
  name_must_be_less_than_20_characters: "Name must be less than 20 characters",
  no_special_characters_or_space_allowed:
    "No special characters or space allowed",
  must_be_less_than_15_characters: "Must Be less than 15 charcters",
  create_role: "Create Role",
  role: "Role",
  role_is_required: "Role is required",
  role_must_be_less_than_40_characters: "Role must be less than 40 characters",
  role_should_not_contain_white_characters:
    "Role should not contain white characters",
  comparison: "Comparison",
  historical_time_of_day_comparison_parameter:
    "History Time Of Day Comparison - Parameter",
  device_parameter: "Device Parameter",
  date_1: "Date 1",
  date_2: "Date 2",
  tod_comparison_report: "TOD Comparison Report",
  time_frame: "Time Frame",
  download_table: "Download Table",
  table: "Table",
  showMinMax_values: "Show Min & Max Values",
  usage_or_average: "Usage or Average",
  min_value: "Min Value",
  last_12_weeks: "Last 12 weeks",
  last_6_months: "Last 6 months",
  last_12_months: "Last 12 months",
  year_5: "5 years",
  machinewise_comparison: "Machine wise Comparison",
  timeFrame: "Timeframe",
  it_should_be_a_date: "It should be a Date",
  time_of_day_parameter: "Time Of Day- Parameter",
  tod_report: "TOD - Report",
  in_this_timeframe: "In This Timeframe",
  average: "Average",
  maximum: "Maximum",
  minimum: "Minimum",
  energy_usage_trend: "Energy Usage Trend",
  standard_deviation: "Standard Deviation",
  statistical_mode: "Statistical Mode",
  production_logs: "Production Logs",
  capacity_logs: "Capacity Logs",
  dynamic_logs: "Dynamic Logs",
  pollutants: "Pollutants",
  group_by: "Group By",
  generate_grouped_trends: "Generate Grouped Trends",
  tCo2: "tCo2",
  scope_1: "Scope 1",
  scope_2: "Scope 2",
  scope_3: "Scope 3",
  source: "Source",
  type: "Type",
  user_access_log: "User Access Log",
  user_logs: "User Logs",
  fetch_logs: "Fetch Logs",
  update_profile: "Update Profile",
  lang: "Lang",
  theme: "Theme",
  phone: "Phone",
  update: "Update",
  update_password: "Update Password",
  change_password: "Change Password",
  old_password: "Old Password",
  new_password: "New Password",
  confirm_password: "Confirm Password",
  password_must_be_more_than_8_characters:
    "Password must be more than 8 characters",
  passwords_do_not_match: "Passwords do not match",
  add_hub: "Add Hub",
  id_must_be_less_than_4_characters: "ID must be less than 4 characters",
  add_maintainer: "Add Maintainer",
  add_parent_org: "Add Parent Org",
  add_parent_to_organisation: "Add Parent to Organisation",
  parent_org_id: "Parent Org ID",
  add_maintainer_to_organization: "Add Maintainer To Organization",
  maintainer_id: "Maintainer ID",
  update_organisation_details: "Update Organisation Details",
  update_your_organization_details: "Update  Your Organisation Details",
  default_email: "Default Email",
  picker_in_menu: "Picker in Menu",
  param_diagnostics: "Param Diagnostics",
  show_logo: "Show Logo",
  show_oem_logo: "Show OEM Logo",
  organisation_alias: "Organisation Alias",
  update_organisation_alias: "Update Organisation Alias",
  update_org_alias: "Update Org Alias",
  update_your_organization_logo: "Update your organization Logo",
  select_a_logo: "Select A Logo",
  update_logo: "Update Logo",
  create_uam_user: "Create UAM User",
  overview: "Overview",
  batches: "Batches",
  track_batch: "Track Batch",
  batch_movement: "Batch Movement",
  production_planner: "Production Planner",
  show_unshipped_only: "Show Un shipped Only",
  view_logs: "View Logs",
  params: "Params",
  add_filter: "Add Filter",
  filters: "Filters",
  batch_id: "Batch_id",
  show_details: "Show Details",
  tracing_logs: "Tracing Logs",
  quantity: "Quantity",
  location_event_type: "Location Event Type",
  fetch_plans: "Fetch Plans",
  sku: "SKU",
  skus: "Stock Keeping Units",
  code: "Code",
  add_production_plans: "Add Production Plans",
  date: "Date",
  from_time: "From Time",
  to_time: "To Time",
  activities: "Activities",
  task_name: "Task Name",
  task_details: "Task Details",
  start_date: "Start Date",
  end_date: "End Date",
  assignee: "Assignee",
  manager: "Manager",
  activity: "Activity",
  activity_type: "Activity Type",
  escalation_matrix: "Escalation Matrix",
  maintenance_activity: "Maintenance Activity",
  em_level: "EM Level",
  delay: "Delay",
  user_group: "User Group",
  mins_5: "5 minutes",
  mins_10: "10 minutes",
  mins_15: "15 minutes",
  mins_30: "30 minutes",
  hour_1: "1 hour",
  hour_6: "6 hour",
  day_1: "1 day",
  day: " day",
  day_3: "3 day",
  insights: "Insights",
  create_emailer_group: "Create Emailer Group",
  notification_type: "Notification Type",
  email: "Email",
  sms: "SMS",
  create_machine_group: "Create Machine Group",
  create_param_group: "Create Param Group ",
  create_user_group: "Create User Group",
  create_module: "Create Module",
  busbar_temp_module: "BusBar Temp Module",
  power_quality_module: "Power Quality Module",
  esg_module: "ESG Module",
  edge_hub: "Edge Hub",
  tolerance: "Tolerance",
  ambient_temp_input_type: "Ambient Temp Input Type",
  ambient_temp: "Ambient Temp",
  workspace_ambient_temp: "Workspace Ambient Temp",
  device_ambient_temp: "Device Ambient Temp",
  r_temperature: "R Temperature",
  y_temperature: "Y Temperature",
  b_temperature: "B Temperature",
  n_temperature: "N Temperature",
  rated_current: "Rated Current",
  no_load_current: "No Load Current",
  r_current: "R Current",
  y_current: "Y Current",
  b_current: "B Current",
  n_current: "N Current",
  delete_activity: "Delete Activity",
  substation_voltage_kv: "Substation Voltage(KV)",
  transformer_rating_mva: "Transformer Rating(MVA)",
  percentage_impedance_of_the_transformer: "% Impedance of the Transformer",
  voltage_rv_y: "Voltage RV(Y)",
  voltage_yb_v: "Voltage YB(V)",
  voltage_br_v: "Voltage BR(V)",
  current_r_a: "Current R(A)",
  current_y_a: "Current Y(A)",
  current_b_a: "Current B(A)",
  thd_i: "THD I",
  thd_v: "THD V",
  thd_i1: "THD I1",
  active_power_kw: "Active Power (kw)",
  apparent_power_kva: "Apparent Power(KVA)",
  reactive_power_kvar: "Reactive Power(KVAR)",
  active_energy_kwh: "Active Energy (KWH)",
  apparent_energy_kvah: "Apparent Energy (KVAH)",
  pf: "PF",
  text: "Text",
  energy_source: "Energy Source",
  scope: "Scope",
  system_logs: "System Logs",
  system_logbook: "System Log Book",
  esg_data_log: "ESG Data Log",
  add_esg_data: "Add ESG Data",
  add_esg_data_log: "Add ESG Data Log",
  source_type: "Source Type",
  unit_of_measurement: "Unit Of Measurement",
  from_date_optional: "From Date (Optional)",
  emission_factor: "Emission Factor (kgCo2)",
  create_dynamic_logger: "Create Dynamic Logger",
  dynamic_log: "Dynamic Log",
  primary_field_label: "Primary Field Label",
  primary_field_type: "Primary Field Type",
  primary: "Primary",
  additional_fields: "Additional Fields",
  field_label: "Field Label",
  field_type: "Field Type",
  choose_list: "Choose List",
  list: "List",
  create_capacity_logger: "Create Capacity Logger",
  capacity_log: "Capacity Log",
  create: "Create",
  create_production_logger: "Create Production Logger",
  production_log: "Production Log",
  production_logger: "Production Logger",
  input_type: "Input Type",
  input: "Input",
  input_name: "Input Name",
  output_type: "Output Type",
  output: "Output",
  output_name: "Output Name",
  output_params: "Output Params",
  compute_production: "Compute Production",
  compute_energy: "Compute Energy",
  energy_param_type: "Energy Param Type",
  energy_device: "Energy Device",
  energy_param: "Energy Param",
  production_operator: "Production Operator",
  production_correction_factor: "Production Correction Factor",
  output_operator: "Output Operator",
  output_correction_factor: "Output Correction Factor",
  input_operator: "Input Operator",
  input_correction_factor: "Output Correction Factor",
  energy_unit: "Energy Unit",
  production_unit: "Production Unit",
  production: "Production",
  create_data_logger: "Create Data Logger",
  data_log: "Data Log",
  add_param: "Add Param",
  param_id: "Param ID",
  create_batch_production_logger: "Create Batch Production Logger",
  batch_tracing_log: "Batch Tracing Log",
  log_name: "Log Name",
  location: "Location",
  info: "Info",
  remarks: "Remarks",
  code_will_be_available_to_log_by_default:
    "code will be available to log by default",
  fields: "Fields",
  additional_info: "Additional Info",
  action: "Action",
  report_type: "Report Type",
  create_parameter_report: " Create Parameter Report",
  data_processing: "Data Processing",
  addition: "Addition",
  singular: "Singular",
  report: "Report",
  energy_parameters_kwh: "Energy Parameters (KWH)",
  apparent_energy_KVAh: "Apparent Energy KVAh",
  maximum_demand_MD: "Maximum Demand (MD)",
  excel_frequency: "Excel Frequency",
  multiplier: "Multiplier",
  single: "Single",
  incomers: "Incomers",
  sections: "Sections",
  energy_distribution_report: "Energy Distribution Report",
  root_machine: "Root Machine",
  tariff_on: "Tarrif On",
  add_root_common_machine: "Add Root & Common Machine",
  common_machine: "Common Machine",
  energy_bill_distribution_report: "Energy Bill Distribution Report",
  create_consolidated: "Create Consolidated",
  choose_report: "Choose Report",
  id: "Id",
  timeframed_sec: "Time Framed Sec",
  create_dynamic_excel_report: "Create Dynamic Excel Report",
  update_dynamic_excel_report: "Update Dynamic Excel Report",
  add_sheet: "Add Sheet",
  sheet_name: "Sheet Name",
  timestamp: "Timestamp",
  sheet: "Sheet",
  sheets: "Sheets",
  sheet_id: "Sheet ID",
  delete_sheet: "Delete Sheet",
  report_name: "Report Name",
  update_details: "Update Details",
  unit: "Unit",
  parameters: "Parameters",
  send_excel_file: "Send Excel File",
  update_excel_template_based_report: "Update Excel Template Based Report",
  create_excel_template_based_report: "Create Excel Template Based Report",
  upload_excel_template_xlsx: "Upload Excel Template (xlsx)",
  param_equation: "Param Equation",
  param_equations: "Parameter Equations",
  edit_sheets: "Edit Sheets",
  row_no: "Row No",
  column_no: "Column No",
  row_number: "Row Number",
  column_number: "Column Number",
  colummn_number: "Column Number",
  no_sheets_added: "No Sheets Added",
  process_based_usage_sheet: "Process Based Usage Sheet",
  process: "Process",
  process_label: "Process Label",
  add_tag_types: "Add Tag Types",
  add_process_to_list: "Add Process To List",
  machine_tag_based_daily_data_sheet: "Machine Tag Based Daily Data Sheet",
  machine_label: "Machine Label",
  add_machine_to_list: "Add Machine to List",
  add_tag_type_to_list: "Add Tag Type to List",
  shift_based_usage_sheet: "Shift Based Usage Sheet",
  status_for_process: "Process",
  status_for_machine: "Machine",
  esg_summary_item: "Summary",
  esg_details_item: "Details",
  esg_scope1_label: "Scope 1",
  esg_scope2_label: "Scope 2",
  esg_scope3_label: "Scope 3",
  esg_scopes_list_label: "Scopes",
  transpose: "Transpose",
  process_based_timeframed_data_sheet: "Process Based Timeframed Data Sheet",
  add_pocess_to_list: "Add Process to List",
  tag_types: "Tag Types",
  " consolidated_shift_based_usage_data_sheet":
    " Consolidated Shift Based Usage Data Sheet",
  date_format: "Date Format",
  include_process_total: "Include Process Total",
  machine_group_based_usage_sheet: "Machine Group Based Usage Sheet",
  machine_group_label: "Machine Group Label",
  machine_group: "Machine Group",
  add_machine_group_to_list: "Add Machine Group To List",
  parameter_based_usage_data_sheet: "Parameter Based Usage Data Sheet",
  "show_month_start&end_data": "Show Month Start & End Data",
  statistics: "Statistics",
  add_param_to_list: "Add Param To List",
  add_timestamp_to_list: "Add Timestamp To List",
  parameter_group_based_timeframed: "Parameter Group Based Timeframed",
  param_group_label: "Param Group Label",
  param_group: "Param Group",
  add_param_group_to_list: "Add Param Group To List",
  dynamic_logger_based_data_sheet: "Dynamic Logger Based Data Sheet",
  dynamic_log_label: "Dynamic Log Label",
  parameter_equation_based_sheet: "Parameter Equation Based Sheet",
  data_source: "Data Source",
  equation_label: "Equation Label",
  add_equation_to_list: "Add Equation to List",
  absolute_values: "Absolute Values",
  consumption_based_sheet: "Consumption Based Sheet",
  parameter_name: "Parameter Name",
  statistical_analysis_based_sheet: "Statistical Analysis Based Sheet",
  parameter_instantaneous_data_sheet: "Parameter Instantaneous Data Sheet",
  create_sheet_column: "Create Sheet Column",
  column_label: "Column Label",
  machine_id: "Machine ID",
  create_machine_list: "Create Machine List",
  create_param_list: "Create Parameter List",
  create_sheets: "Create Sheets",
  create_shift: "Create Shift",
  description_optional: "Description (optional)",
  create_sku: "Create SKU",
  create_location: "Create Location",
  location_type: "Location Type",
  from_location: "From Location",
  to_location: "To Location",
  event_type: "Event Type",
  create_location_event_type: "Create Location Event Type",
  create_list: "Create List",
  create_param_equation: "Create Param Equation",
  positive_params: "Positive Params",
  params_to_be_substracted: "Params to be Subtracted",
  create_combo_param: "Create Combo Param",
  combo_params: "Combined Parameters",
  calculated_params: "Calculated Parameters",
  inter_param_operator: "Inter Param Operator",
  correction_operator: "Correction Operator",
  correction_operand: "Correcction Operand",
  create_calculated_param: "Create Calculated Param",
  calculated_param: "Calculated Param",
  correction_factor: "Correction Factor",
  param1_type: "Param 1 Type",
  param1: "Param 1",
  param1_operator: "Param 1 operator",
  param1_operand: "Param 1 Operand",
  param2_type: "Param 2 Type",
  param2: "Param 2",
  param2_operator: "Param 2 Operator",
  param2_operand: "Param 2 Operand",
  create_esg_scope_conversion: "Create ESG Scope Conversion ",
  category: "Category",
  esg_scope_logging: "ESG Scope Logging",
  get_from_param: "Get From Param",
  choose_param: "Choose Param",
  create_target: "Create Target",
  create_word: "Create Word",
  words: "Words",
  choose_OEM: "Choose OEM",
  get_the_models: "Get The Models",
  get_oem_devices: "Get OEM Devices",
  oem_devices: "OEM Devices",
  model_number: "Model Number",
  register: "Register",
  add_modbus_gateway: "Add Modbus Gateway",
  " add_modbus_TCP_gateway": " Add Modbus TCP gateway",
  IPAddress: "IP Address",
  port_number: "Port Number",
  HubID: "Hub ID",
  baud_rate: "Baud Rate",
  parity: "Parity",
  stop_bit: "Stop Bit",
  update_wifi: "Update Wifi",
  SSID: "SSID",
  password: "Password",
  restart_enture: "Restart Enture",
  force_update_configs: "Force Update Configs",
  activity_name: "Activity Name",
  board: "Board",
  calender: "Calender",
  maintenance: "Maintenance",
  welcome: "Welcome",
  logout: "Logout",
  help: "Help",
  accounts: "Accounts",
  organisation: "Organisation",
  pointer_color_optional: "Pointer Color (optional)",
  user_id: "User ID",
  confirm_new_password: "Confirm New Password",
  service: "Service",
  send_grid: "Send Grid",
  default_enture_view: "Default Enture View",
  permissions: "Permissions",
  areas: "Areas",
  remark: "Remark",
  days: "Days",
  yesterdays_aqi: "Yesterday's AQI",
  aqi: "AQI",
  choose_air_quality_device: "Choose Air Quality Device",
  delete_this_device: "Delete This Device",
  air_quality_label: "Air Quality Label",
  humidity: "Humidity",
  temperature: "Temperature",
  watcher: "Watcher",
  area_reference: "Area Reference",
  write_data: "Write Data",
  day_of_week: "Day Of the Week",
  month: "Month",
  diagnostics_notification_groups: "Diagnostics Notification Groups",
  hub_name: "Hub Name",
  hub_model: "Hub Model",
  hub_type: "Hub Type",
  hub: "Hub",
  update_your_MQTT_bridge_config: "Update your MQTT Bridge Config",
  MQTT_bridge_mode: "MQTT Bridge Mode",
  broker_address: "Broker Address",
  update_your_OPC_config: "Update your OPC Config",
  OPC_client_mode: "OPC Client Mode",
  OPC_server_URL: "OPC Server URL",
  user_auth: "User Auth",
  mode: "Mode",
  security_policy: "Security Policy",
  Update_your_Edge_Hub_restart_Schedule:
    "Update your Edge Hub restart Schedule",
  auto_restart: "Auto Restart",
  time_24Hrs: "Time (24 Hrs)",
  modbus_ping_latency: "Modbus Ping Latency",
  modbus_unhealthy_wait: "Modbus Unhealthy Wait",
  edit_written_log_book: "Edit Written Log Book",
  field_cant_change: "This field Can't Change",
  re_write: "Re Write",
  write_in_logbook: "write in logbook",
  write: "Write",
  download_template: "Download Template",
  write_in_logbook_csv: "Write in Log book by .csv",
  upload_file: "Upload File",
  batch: "Batch",
  move_batch: "Move Batch",
  on: "On",
  off: "Off",
  fast_mode: "Fast Mode",
  batch_shipping_details: "Batch Shipping Details",
  vehicle_no: "Vehicle No",
  net_weight: "Net Weight",
  actual_weight: "Actual Weight",
  vendor: "Vendor",
  loaded_by: "Loaded By",
  verified_by: "Verified By",
  document_no: "Document No",
  multiple_machine_param: "Multiple Machine & Params",
  map_paramid_paramequation_to_row: "Map Param ID's or Param Equations To Row",
  add_current_date_to_sheet: "Add current date to sheet",
  create_parameter_from_template: "Create Parameter From Template",
  selected_parameters_count: "Selected Parameters Count",
  restricted_user_access: "Restricted User Access",
  select_all_parameters: "Select All Parameters",
  edit_angular_gauge_widget: "Edit Angular Gauge Widget",
  add_angular_gauge_widget: "Add Angular Gauge Widget",
  widget_label: "Widget Label",
  widget_width: "Widget Width",
  widget_height: "Widget Height",
  param_label: "Param Label",
  choose_parameter: "Choose Parameter",
  processing: "Processing",
  edit_banner_widget: "Edit Banner Widget",
  add_banner_widget: "Add Banner Widget",
  edit_bartd_widget: "Edit Bar TD Widget",
  add_bar_widget: "Add Bar Widget",
  yaxis_label: "Y - Axis Label",
  xaxis_label: "X - Axis Label",
  y1axis_label: "Y1 - Axis Label",
  y2axis_label: "Y2 - Axis Label",
  edit_dual_axis_bar_trend_widget: "Edit Dual Axis Bar Trend  Widget",
  add_dual_axis_bar_trend_widget: "Add Dual Axis Bar Trend  Widget",
  y1_graph_type: "Y1 Axis Graph Type",
  y2_graph_type: "Y2 Axis Graph Type",
  idealValue: "idealValue",
  idealLabel: "idealLabel",
  add_parameters: "Add Parameters",
  add_equation: "Add Equation",
  add_params: "Add Params",
  enter_of_machines: "Enter No Of Machines",
  edit_bar_trend_td_widget: "Edit Bar Trend TD Widget",
  add_bar_trend_td_widget: "Add Bar Trend TD Widget",
  graph_type: "Graph Type",
  aggregation: "Aggregation",
  default_graph_type: "Default Graph Type",
  bar_mode: "Bar Mode",
  show_ideal_line: "Show Ideal Line",
  equations: "Equations",
  edit_bitwise_word_widget: "Edit Bitwise Word Widget",
  add_bitwise_word_widget: "Add Bitwise Word Widget",
  show_bit_status: "Show Bit Status",
  edit_bullet_gauge_widget: "Edit Bullet Gauge Widget",
  add_bullet_gauge_widget: "Add Bullet Gauge Widget",
  reference_value_for: "Reference Value For",
  gauge_type: "Gauge Type",
  indicator_color: "Indicator Color",
  busbar_temperature_graph_widget: "Bus Bar Temperature Graph Widget",
  busbar_module: "Bus Bar Module",
  edit_consumption_gauge_widget: "Edit Consumption Gauge Widget",
  add_consumption_gauge_widget: "Add Consumption Gauge Widget",
  linearGauge: "Linear Gauge",
  show_target: "show Target",
  healthy_on_below_target: "Healthy On Below Target",
  edit_consumption_pie_widget: "Edit Consumption Pie Widget",
  add_consumption_pie_widget: "Add Consumption Pie Widget",
  add_color_break_point: "Add Color Break Point",
  custom_color: "Custom Color",
  color: "Color",
  edit_heat_map_widget: "Edit Heat Map Widget",
  add_heat_map__widget: "Add Heat Map Widget",
  choose_equation: "Choose Equation",
  label_star: "Label*",
  color_star: "Color*",
  operator1_star: "Operator 1*",
  value1_star: "Value 1*",
  operator2_star: "Operator 2*",
  actions: "Actions",
  condition: "Conditions",
  status_color: "Status Color",
  text_view: "Text View",
  edit_process_Status_widget: "Edit Process Status Widget",
  add_process_Status_widget: "Add Process Status Widget",
  edit_machine_Status_widget: "Edit Machine Status Widget",
  add_machine_Status_widget: "Add Machine Status Widget",
  choose_process: "Choose Processes",
  choose_machine: "Choose Machines",
  table_header_label: "Table Header Label",
  edit_table_TD_widget: "Edit Table TD Widget",
  add_table_TD_widget: "Add Table TD Widget",
  configure_table: "Configure Table",
  edit_target_based_trend_widget: "Edit Target Based Trend Widget",
  add_target_based_trend_widget: "Add Target Based Trend Widget",
  target: "Target",
  target_line_label: "Target Line Label",
  edit_usage_score_card: "Edit Usage Scorecard Widget",
  add_usage_score_card: "Add Usage Scorecard Widget",
  compare_to: "Compare To",
  assign_color: "Assign Color",
  enter: "Enter",
  gauge_to_dashboard: "Gauge To Dashboard",
  widget_types: "Widget Types",
  from_value: "From Value",
  to_value: "To Value",
  machine_status: "Machine Status",
  deviceA_type: "Device A Type",
  templates: "Templates",
  add_triggers_to_parameter: "Add Triggers to Parameter",
  triggers: "Triggers",
  show_filter: "Show Filter",
  view_id: "View ID",
  move_up: "Move Up",
  move_down: "Move Down",
  multiplier_types: "Multiplier_types",
  energy_report: "Energy Report",
  add_machine_param: "Add Machine Param",
  aliasing: "Aliasing",
  no_zero_read: "No zero read",
  remove_negative_entropy: "Remove Negative Entropy",
  calculate_consumption: "Calculate Consumption",
  disabled: "Disabled",
  limiter_value: "Limiter Value",
  machine_scroll_view_page: "Machine Scroll View Page",
  create_mannual_parameter: "Create Mannual Parameter",
  restrict_user_access: "Restrict user acces",
  add_device_parameter: "Add Device Parameter",
  entropy: "Entropy",
  idle_latency: "Idle Latency",
  add_OPC_parameter: "Add OPC Parameter",
  node_id: "Node ID",
  byte_format: "Byte Format",
  param_scroll_view_page: "Param Scroll View Page",
  timeframed_sec_report: "Time framed SEC Report",
  prod_unit: "Prod Unit",
  SEC_unit: "SEC Unit",
  energy_parameters: "Energy Parameters",
  prod_param_type: "Prod Param Type",
  production_parameters: "Production Parameters",
  no_of_intervals: "No Of Intervals",
  granular_data: "Granular Data",
  csv: "CSV",
  delete_rule: "Delete Rule",
  enable_disable_all_parameters: "Enable/Disable All Parameters",
  disable: "Disable",
  enable: "Enable",
  edit_computed_parameter: "Edit Computed Parameter",
  quantization: "Quantization",
  command: "Command",
  register_size: "Register Size",
  consumer_ID: "Consumer ID",
  site_id: "Site ID",
  get_data: "Get Data",
  update_param_type: "Update Param Type",
  write_in_data_logbook: "Write in Data Logbook",
  daily_data: "Daily Data",
  log_entry: "Log Entry",
  enable_Scroll_mode: "Enable Scroll Mode",
  multiple_areas: "Multiple Areas",
  time_of_day: "Time of Day",
  capacity_logger: "Capacity Logger",
  provider: "Provider",
  total_sites_received_bills_MTD: "Total Sites Received Bills MTD",
  sites_yet_to_receive_bills: "Sites Yet to Receive Bills",
  total_sites: "Total Sites",
  sites_received_bills_MTD: "%Sites received bills MTD",
  total: "Total",
  total_bills_received_mtd: "Total Bills Received MTD",
  arrear_1: "Arrear 1",
  arrear_2: "Arrear 2",
  no_reading: "No Reading",
  bills_received_in_5_days: "Bills Received in last 5 days",
  site_wise_status: "Site wise status of Other than OK status",
  site_wise_final_summary: "Site Wise Final Summary",
  source_link: "Sourcce Link",
  data_type: "Data Type",
  select_an_image_to_upload: "Select an image to upload",
  user_group_Id: "User Group ID",
  user_group_name: "User Group Name",
  delay_in_mins: "Delay in (Minutes)",
  delete_task: "Delete Task",
  watchers: "Watchers",
  comments: "Comments",
  asset_under_maintenance: "Asset Under Maintenance",
  start_time: "Start Time",
  end_time: "End Time",
  download_latest_usage_table: " Download latest usage table",
  tag: "Tag",
  add_target_data: "Add Target Data",
  edit_target: "Edit Target",
  delete_target: "Delete Target",
  prop: "Prop",
  excel_template_based_report: "Excel Template Based Report",
  configured_hub: "Configured Hub",
  configured_relay: "Configured Relay",
  desired_state: "Desired State",
  configured_notification_group: "Configured Notification Group",
  email_ids: "Email IDs",
  logs: "logs",
  unit_id: "Unit ID",
  download_report: "Download Report",
  frequency: "Frequency",
  ip: "IP",
  edit_wifi: "Edit Wifi",
  details: "Details",
  connected_to: "Connected To",
  software_version: "Software Version",
  mac_address: "MAC Address",
  opc_client_enabled: "OPC Client Enabled",
  MQTT_bridge_enabled: "MQTT Bridge Enabled",
  hub_specification: "Hub Specification",
  hub_details: "Hub Details",
  restart_schedule: "Restart Schedule",
  restart_device: "Restart Device",
  set_up_restart_time: "Set Up Restart Time",
  connectivity_logs: "Connectivity Logs",
  internal_cache_size: "Internal Cache Size",
  default: "Default",
  configs: "Configs",
  processed: "Processed",
  space_available: "Space Available",
  kb: "KB",
  mb: "MB",
  last_ping: "Last Ping",
  toggle: "Toggle",
  configured_creds: "Configured creds",
  psk: "PSK",
  set_value: "Set Value",
  time_current_time: "Time (Current time will be taken if not mentioned)",
  Set: "Set",
  health: "Health",
  port: "Port",
  mqtt_params: "mqtt-params",
  delete_device: "Delete Device",
  view_details: "View Details",
  parameter_id: "Parameter ID",
  email_address: "Email Address",
  attached_triggers: "Attached triggers",
  parameter_details: "Parameter Details",
  enable_write: "Enable Write",
  disable_write: "Disable Write",
  offset: "Offset",
  no_of_parameters: "No Of Parameters",
  creation_time: "Creation Time",
  not_configured: "Not Configured",
  add_rule: "Add Rule",
  sl_no: "Sl No",
  targeted_by_value: "Targeted By Value",
  configured_action: "Configured Action",
  add_action: "Add Action",
  action_id: "Action ID",
  delete_user: "Delete User",
  bit: "Bit",
  low: "Low",
  high: "High",
  esg_scope_conversion: "ESG Scope Conversion",
  batch_production_logs: "Batch Production Logs",
  esg_data_logs: "ESG Data Logs",
  remove_from_dashboard: "Remove from Dashboard",
  data_logs: "Data Logs",
  select_device: "Select Device",
  fetch_connectivity_logs: "Fetch Connectivity Logs",
  hub_internet_connectivity_logs: "Hub Internet connectivity Logs",
  modbus_gateways: "Modbus Gateways",
  device_switched_on: "Device is switched On",
  device_switched_off: "Device is switched off",
  parameter_report: "Parameter Report",
  timeframed_specific_energy_consumption_report:
    "Timeframed Specific Energy Consumption Report",
  ebill_distribution_report: "E-Bill Distribution Report",
  root_user_management: "Root User Management",
  user_acces_management: "User Access Management",
  inference: "Inference",
  turn_off: "Turn Off",
  turn_on: "Turn On",
  diagram_pallette: "Diagram Pallette",
  add_component: "Add Component",
  components: "Components",
  add_text: "Add Text",
  add_realtime_data: "Add Realtime Data",
  shapes: "Shapes",
  lines: "Lines",
  text_realtime_component: "Text Realtime Component",
  shapes_pallette: "Shapes Pallette",
  lines_pallette: "Lines Pallette",
  click_choose: "Click and Choose the shapes to add to the SLD",
  choose_line: "Choose Line to add to the SLD",
  choose_image: "Choose Image",
  upload_image_to_add: "Upload Image to add to the SLD",
  add_realtime_textdata: "Add real time or Text data to the SLD",
  update_user: "Update User",
  add_watcher: "Add Watcher",
  premise_area: "Premise Area",
  create_computed_param: "create computed Param",
  create_derived_param: "create Derived Param ",
  proceed_to_ship: "Proceed To Ship",
  esg_data_collection: "ESG Data Collection",
  esg_scope_details: "ESG Scope Details",
  select_custom_date_range: "Select Custom Date Range",
  days_365: "365 days",
  days_180: "180 Days",
  days_30: "30 Days",
  create_user_for_SSO: "Create User For SSO",
  add_widget: "Add Widget",
  add_machine_status_rules: "Add Machine Status Rules",
  add_trigger: "Add Trigger",
  add_logged_parameter: "Add Logged Parameter",
  edit_logged_parameter: "Edit Logged Parameter",
  create_mannual_param: "Create Mannual Param",
  add_opc_param: "Add OPC Param",
  create_write_block: "Create Write Block",
  all_parameters: "All Parameters",
  edit_device: "Edit Device",
  edit_modbus_device: "Edit Modbus Device",
  edit_mqtt_device: "Edit Mqtt Device",
  limit_entropy: "Limit Entropy",
  emails: "Emails",
  power_factor: "PowerFactor(PF)",
  tarrif_rate: "Tarrif Rate",
  apparent_power: "Apparent Power",
  reactive_power: "Reactive Power",
  active_power: "Active Power",
  energy_usage_view_page: "Energy Usage View Page",
  switch_organisation: "Switch Organisation",
  wireless_creds: "Wireless Creds",
  latest: "Latest",
  value_is: "Value Is",
  please_take_necessary_action: "Please take necessary Action",
  edit_role: "Edit Role",
  delete_role: "Delete Role",
  today: "Today",
  days_4: "4 days",
  due: "Due",
  move_to_done: "Move to Done",
  power_system_fault: "Power System Fault count (Last Week)",
  alarms: "Alarms",
  electricity_consumption: " Electricity Consumption (Month Till Date)",
  average_demand: "Average Demand",
  water_use_intensity: " Water Use Intensity (WUI)",
  average_water_cost: "  Average Water Cost (Monthly)",
  water_use_efficiency: " Water Use Efficiency (WUE)",
  batch_logs: "Batch Logs",
  edit_log: "Edit Log",
  delete_log: "Delete Log",
  dates: "Dates",
  log_details: "Log Details",
  view: "View",
  scope1_emission: "Scope 1 Emission",
  scope2_emission: "Scope 2 Emission",
  lmtd: "LMTD",
  company_data: "Company Data",
  historian: "Historian",
  from_optional: "From (optional)",
  to_optional: "To (optional)",
  trends: "Trends",
  generate_trend: "Generate Trend",
  by: "By",
  check_logs: "Check Logs",
  workspaces: "Workspaces",
  types_of_nodes_connected: "Types of Nodes Connected",
  types_of_modbus_device_connected: "Types of Modbus Devices Connected",
  no_of_registers: "No of Registers",
  write_register: "Write Register",
  setting_view: "Setting View",
  sign_up: "Sign Up",
  reset: "Reset",
  mobile_number: "Mobile Number",
  nebeskie_labs: "Nebeskie Labs",
  please_register_and_send_otp:
    "Please Enter your registered email Id and click on Send OTP.",
  reset_password: "Reset Password",
  back_to_login: "Back To Login",
  otp: "OTP",
  send_otp: "Send OTP",
  org_alias: "Org Alias",
  please_stand_by: "Please Stand By",
  total_usage_this_month: "Total Usage This Month",
  total_usage_last_month: "Total Usage Last Month",
  generate_report_manually: "Generate Report Manually",
  energy_distribution_detailed_report: "Energy Distribution Detailed Report",
  the_report_is_processing: "The Report is Processing",
  enter_active_energy_manually: "Enter Active Energy Manually",
  include_others: "Include Others",
  bill_amount: "Bill Amount",
  yes_delete_it: "Yes, Delete It",
  create_write_param: "Create Write Param",
  report_list: "Report List",
  plant_id: "Plant ID",
  plant_name: "Plant Name",
  plant_address: "Plant Address",
  add_selected: "Add Selected",
  clear_selection: "Clear Selection",
  real_time_value: "Real tIme Value",
  default_status: "Default Status",
  default_label: "Default Label",
  default_color_optional: "Default Color Optional",
  rule_based_status: "Rule Based Status",
  show_label_only: "Show Label Only",
  show_unit: "Show Unit",
  status_rules: "Status Rules",
  configure: "Configure",
  monthly_usage: "Monthly Usage",
  meter_consumption_kwh: "Meter Consumption(kwh)",
  total_unit: "Total Unit",
  region: "Region",
  average_energy_consumed: "Average energy consumed",
  average_production: "Average Production",
  average_sec: "Average SEC",
  total_production: "Total production",
  total_energy_consumed: "Total Energy Consumed",
  max_sec: "Max SEC",
  device_1: "Device 1",
  device_parameter_1: "Device Parameter 1",
  device_2: "Device 2",
  device_parameter_2: "Device Parameter 2",
  create_param: "Create Param",
  update_tags: "Update Tags",
  remove_from_process: "Remove From Process",
  machine_parameters: "Machine Parameters",
  energy: "Energy",
  power: "Power",
  remove_from_featured: "Remove From Featured",
  other_parameters: "Other Parameters",
  add_to_featured: "Add To Featured",
  logged_parameters: "Logged Parameters",
  tags: "Tags",
  machine_status_rules: "Machine Status Rules",
  connected_communication_devices: "Connected Communication Devices",
  mqtt_devices: "Mqtt Devices",
  modbus_devices: "Modbus Devices",
  total_no_of_params: "Total No of Params",
  edge_devices: "Edge Devices",
  machines_configured: "Machines Configured",
  reports_configured: "Reports Configured",
  views_configured: "Views Configured",
  send_email_notification: "Send Email Notification",
  enture_send_email_notification:
    "Let's Enture send email Notification to the selected user group",
  agree_and_send: "Agree & Send",
  ebill: "Ebill",
  monthly_trend: "Monthly Trend",
  param_view: "Param View",
  calories: "Calories",
  param_count_view: "Param Count View",
  created: "Created",
  api_data_count_view: "API Data Count View",
  diesel_emissions: "Diesel Emissions",
  eb_emissions: "EB Emissions",
  emissions_kg_co2: "Emissions kg-Co2",
  kpis: "KPIs",
  jan_23: "Jan-23",
  feb_23: "Feb-23",
  mar_23: "Mar-23",
  apr_23: "Apr-23",
  may_23: "May-23",
  jun_23: "Jun-23",
  lpg_emissions: "LPG Emissions",
  petrol_emissions: "Petrol Emissions",
  bullet_gauge: "Bullet Gauge",
  equipment: "Equipment",
  enter_no_of_machines: "Enter No of Machines",
  shift: "Shift",
  upload: "Upload",
  image_upload: "Image Upload",
  derived_parameter: "Derived Parameter",
  value_type: "Value_type",
  enable_entropy: "Enable Entropy",
  remove_decimal: "Remove Decimal",
  limiter_entropy: "Limiter Entropy",
  show_sku_id: "Show SKU ID",
  production_start_date: "Production Start Date(Optional)",
  production_start_time: "Production start Time(Optional)",
  time: "Time",
  temperature_c: "Temperature (c)",
  set_temperature_c: "Set Temperature (C)",
  no_of_people: "No oF people",
  co2_level: "Co2 Level",
  aqi_optional: "AQI Optional",
  delete_sheets: "Delete Sheets",
  root_login: "Root Login",
  uam_login: "UAM Login",
  login: "Login",
  add_oem_logo: "Add OEM Logo",
  devices: "Devices",
  derived_data_units: "Derived/Data Units",
  roles: "Roles",
  engineering: "Engineering",
  control_panel: "Control Panel",
  views: "Views",
  reports_analysis: "Reports & Analysis",
  actions_triggers: "Actions & Triggers",
  modules: "Modules",
  processes: "Processes",
  air_quality: "Air Quality",
  manufacturing: "Manufacturing",
  esg: "ESG",
  task_managment: "Task Management",
  sustainability: "Sustainability",
  groups: "Groups",
  log_book: "Log Book",
  monitor: "Monitor",
  enture_settings: "Enture Settings",
  configuration: "Configuration",
  bar: "Bar",
  line: "Line",
  s7_device: "S7 Device",
  rack: "Rack",
  slot: "Slot",
  db_number: "DB Number",
  byte_offset: "Byte Offset",
  bit_offset: "Bit Offset",
  data_length: "Data Length",
  val_type: "Value Type",
  create_s7_device: "Add S7 Device",
  scope_label: "Scopes",
  client_safety: "Client Safety",
  edit: "Edit",
  delete: "Delete",
  choose_param_to_monitor: "Choose Param To Monitor",
  rules: "Rules",
  add_to_row: "Add To Row",
  add_usage: "Add Usage",
  notifications: "Notifications",
  organisation_details: "Organisation Details",
  profile: "Profile",
  user_access: "User Access",
  admin: "Admin",
  shifts: "Shifts",
  add_pie_TD_widget: "Add Pie TD Widget",
  edit_pie_TD_widget: "Edit Pie TD Widget",
  legend: "Legend",
  pie: "Pie",
  edit_bar_trend_widget: "Edit Bar Trend Widget",
  add_bar_trend_widget: "Add Bar Trend Widget",
  edit_horizantal_bar_trend_widget: "Edit Horizantal Bar Trend Widget",
  add_horizantal_bar_trend_widget: "Add Horizantal Bar Trend Widget",
  too_long: "Too Long",
  trigger_logs: "Trigger Logs",
  create_trigger: "Create Trigger",
  right_click_to_configure: "Right Click to Configure",
  width: "width",
  height: "height",
  hide_value: "Hide Value",
  edit_machine: "Edit Machine",
  wait_time: "Wait Time",
  production_date: "Production Date",
  connectivity_status: "Connectivity Status",
  production_plans: "Production Plans",
  production_pending: "Production Pending",
  pending_shipment: "Pending Shipment",
  trace_logs: "Trace Logs",
  movement_events: "Movement Events",
  kgco2: "kgCo2",
  entropy_threshold: "Entropy Threshold",
  threshold_value: "Threshold Value",
  pending_dispatch: "Pending Dispatch",
  dispatched: "Dispatched",
  dispatch_details: "Dispatch Details",
  dispatch: "Dispatch",
  raw_materials: "Raw Materials",
  pour_qty: "Pour Quantity",
  summary: "Summary",
  optional: "Optional",
  param_mqtt_map: "Param MQTT Map",
  machine_status_mqtt_map: "Machine Status MQTT Map",
  edit_trigger_count_table_widget: "Edit Trigger Count Table Widget",
  add_trigger_count_table_widget: "Add Trigger Count Table Widget",
  count: "Count",
  real_time_consumption: "Real Time Consumption",
  search_view: "Search View",
  multiple_entries: "Multiple Entries",
  combined_parameter: "Combined Parameter",
  background_color: "Background Color",
  resize: "Resize",
  remove: "Remove",
  select_views: "Select Views",
  difference: "Difference",
  sum: "Sum",
  set_param_type_for_machine: "Set Param Type For machine",
  y1axis: "y1 Axis",
  y2axis: "y2 Axis",
  temperature_parameters: "Temperature Parameters",
  area_name: "Area Name",
  maintenance_due_on: "Maintenance_Due On",
  days_left: "Days Left",
  enture_insights: "Enture Insights",
  reports: "Reports",
  till_date_pie_chart: "Till Date Pie Chart",
  till_date_bar_chart: "Till Date Bar Chart",
  tod_billing_widget: "TOD Billing Widget",
  bar_trend_widget: "Bar Trend Widget",
  target_based_bar_trend_widget: "Target Based Bar Trend Widget",
  consumption_pie_widget: "Consumption Pie Widget",
  consumption_gauge_widget: "Consumption Gauge Widget",
  angular_gauge_widget: "Angular Gauge Widget",
  table_widget: "Table Widget",
  banner_widget: "Banner Widget",
  param_equation_banner_widget: "Param Equation Banner Widget",
  shift_based_usage_table: "Shift Based Usage Table",
  bitwise_word_widget: "Bitwise Word Widget",
  dynamic_log_widget: "Dynamic Log Widget",
  machine_status_timeline_widget: "Machine Status Timeline Widget",
  process_or_machine_status_widget: "Process/Machine Status Widget",
  parameter_status_based_usage_status: "parameterStatusBasedWidget",
  parameter_insights_widget: "Parameter Insights Widget",
  heat_map: "Heat Map",
  usage_score_card_widget: "Usage Score Card Widget",
  busbar_live_monitoring: "Busbar Live Monitoring",
  bullet_or_angular_guage_widget: "Bullet/Angular Guage Widget",
  dual_axis_bar_trend_widget: "Dual Axis Bar Trend Widget",
  trigger_count_table_widget: "Trigger Count Table Widget",
  bar_trend_td_widget: "Bar Trend TD Widget",
  horizantal_bar_trend_widget: "Horizantal Bar Trend Widget",
  choose_from_templates: "choose_from_templates",
  gauge_widget: "Gauge Widget",
  shift_timeframed_trends: "Shift Based Timeframed Trends",
  candlestick_trends: "CandleStick Trends",
  free_ram: "Free RAM",
  item: "Item",
  item_code: "Item Code",
  department: "Department",
  departments: "Departments",
  issue_vouchers: "Issue Vouchers",
  issue_voucher: "Issue Voucher",
  to_be_approved: "To Be Approved",
  to_be_issued: "To Be Issued",
  intended: "Intended",
  approved: "Approved",
  received: "Received",
  receiver: "Receiver",
  material: "material",
  material_group: "material group",
  material_desc: "Material Description",
  uom: "Unit Of Measurement",
  create_item_code: "Create Item Code",
  create_department: "Create Department",
  users: "Users",
  items: "Items",
  users_in_department: "Users in Department",
  authorisers_in_department: "Authorisers in Department",
  issuers_in_department: "Issuers in Department",
  item_issuer_list: "Item Issuer List",
  add_item_issuer: "Add Item Issuer",
  store: "Store",
  create_issue_voucher: "Create Issue Voucher",
  voucher_requests: "Voucher Requests",
  intended_qty: "Intended Qty",
  issued_qty: "Issued Qty",
  issue_items: "Issue Items",
  voucher_receipt: "Voucher Receipt",
  add_stock: "Add Stock",
  attached_targets: "Attached Targets",
  attach_target: "Attach Target",
  add_target_to_parameter: "Add Target to Parameter",
  empty: "Empty",
  add_day_wise_target: "Add Day wise Target",
  day_wise_targets: "Day Wise Targets",
  fetch_targets: "Fetch Targets",
  day_wise_machine_status_widget: "Daywise Machine Status Widget",
  add_day_wise_machine_status_widget: "Add Daywise Machine Status Widget",
  edit_day_wise_machine_status_widget: "Edit Daywise Machine Status Widget",
  add_machines: "Add Machines",
  added_machines: "Added Machines",
  consumption_start_time: "Consumption Start time",
  add_consolidated_daywise_machine_status_widget:
    "Add Consolidated Day Wise Machine Status Widget",
  edit_consolidated_daywise_machine_status_widget:
    "Edit Consolidated Day Wise Machine Status Widget",
  consolidated_day_wise_machine_status_widget:
    "Consolidated Daywise Machine Status Widget",
  day_wise_machine_status_table_widget: "Daywise Machine Status Table Widget",
  add_day_wise_machine_status_table_widget:
    "Add Daywise Machine Status Table Widget",
  edit_day_wise_machine_status_table_widget:
    "Edit Daywise Machine Status Table Widget",
  shift_wise_machine_status_widget: "Shiftwise Machine Status Widget",
  add_shift_wise_machine_status_widget: "Add Shiftwise Machine Status Widget",
  edit_shift_wise_machine_status_widget: "Edit Shiftwise Machine Status Widget",
  check_list: "Checklist",
  create_checklist: "Create Checklist",
  checklists: "Checklists",
  field_name: "Field Name",
  type_rule: "Rule Required",
  create_checklist_template: "Create Checklist Template",
  edit_checklist_template: "Edit Checklist Template",
  delete_checklist_template: "Delete Checklist Template",
  checklist_templates: "Checklist Templates",
  template: "Template",
  schedule: "Schedule",
  maker: "Maker",
  checker: "Checker",
  approver: "Approver",
  issuer: "Issuer",
  edit_checklist: "Edit Checklist",
  delete_checklist: "Delete Checklist",
  stage: "Stage",
  designation: "Designation",
  default_landing_page: "Default Landing Page",
  image_widget: "Image Widget",
  add_image_widget: "Add Image Widget",
  upload_image: "Upload Image",
  download: "Download",
  total_checklists_submitted: "Total Checklists Submitted",
  Total_auto_submitted_checklists: "Total Checklists auto Submitted By system",
  auto_submitted_empty_checklists: "Auto Submitted empty checklists",
  submission_summary: "Subbmission Summary",
  submission_adherence: "Submission Adherence",
  checklist_status_stats: "Checklist Status Stats",
  arrange: "Arrange",
  show_total: "show Total",
  show_avg: "show Average",
  table_second: "Table Second",
  version_no: "Version Number",
  version_date: "Version Date",
  factor: "Factor",
  order: "Order",
  order_fields: "Order Fields",
  finish: "Finish",
};
