import English from "@/utillities/languages/English";

export default {
  ...English,
  checklist_status_sheet: "சரிபார்ப்பு பட்டியல் நிலை தாள்",
  all: "அனைத்து",

  start: "தொடங்கு",
  filling: "நிரப்புதல்",

  accept: "ஒப்புக்கொள்",
  reject: "நிராகரி",
  move_to_next_stage: "அடுத்த கட்டத்திற்குச் செல்லவும்",
  save_as_draft: "வரைவு பதிப்பாக சேமிக்கவும்",
  save_and_submit: "சேமித்து சமர்ப்பிக்கவும்",
  closure: "முடிவடைவது",
  duration: "கால அளவு",
  refill_request: "மீண்டும் நிரப்ப கோரிக்கை",
  is_be_cancelled: "ரத்து செய்யப்படும்",
  delete_this: "இதை நீக்கு",
  event: "நிகழ்வு",

  for: "க்கு",
  of: "இன்",
  downtime: "இடைநிலை நேரம்",

  metrics: "மெட்ரிக்ஸ்",
  oee_by_day: "ஒரு நாளுக்கு OEE",

  ideal_cycle_time: "சிறந்த சுழற்சி நேரம்",

  minutes: "நிமிடங்கள்",
  production_time: "உற்பத்தி நேரம்",
  back: "மீண்டும்",
  down_times: "இடைவெளி நேரங்கள்",

  top_5_reasons: "சிறந்த 5 காரணங்கள்",
  top_5_categories: "சிறந்த 5 வகைகள்",
  enter_apparent_energy_manually: "தோற்றமான ஆற்றலை கைமுறையாக உள்ளிடவும்",

  oee: "OEE",
  reason: "காரணம்",
  add_downtime_reason: "இடையூறு காரணத்தைச் சேர்க்கவும்",

  default_target: "இயல்புநிலை இலக்கு",

  enter_in_minutes: "நிமிடங்களில் உள்ளிடவும்",
  custome_fields: "விருப்ப துறைகள்",
  enter_data_in_minutes: "நிமிடங்களில் தரவை உள்ளிடவும்",
  enter_data_in_hours: "மணிநேரத்தில் தரவை உள்ளிடவும்",

  create_tod_billing_widget: "TOD பில்லிங் விட்ஜெட்டை உருவாக்கவும்",
  edit_tod_billing_widget: "TOD பில்லிங் விட்ஜெட்டைத் திருத்து",
  choose_from_machine_groups: "இயந்திரக் குழுக்களில் இருந்து தேர்வுசெய்க",
  tod_billing: "TOD பில்லிங்",
  choose: "தேர்வுசெய்க",

  create_shift_based_machine_usage_log: "இயந்திர பயன்பாட்டு பதிவை உருவாக்கவும்",
  shift_based_machine_usage_log: "இயந்திர பயன்பாட்டு பதிவு",
  rejected: "நிராகரிக்கப்பட்டது",
  machine_operator: "இயந்திர இயக்குபவர்",
  production_end_timestamp: "உற்பத்தி முடிவு நேரம்",
  production_start_timestamp: "உற்பத்தி தொடக்க நேரம்",
  running_time: "நடப்பு நேரம்",
  idle_time: "செயலற்ற நேரம்",
  down_time: "சரிவிலங்கு நேரம்",

  date_row_index: "தேதி வரிசை",
  date_position: "தேதி நிலை",
  language: "மொழி",

  safety_incident_sheet: "பாதுகாப்பு சம்பவத் தாள்",
  safety_inspection_sheet: "பாதுகாப்பு ஆய்வு தாள்",

  ghg_emission_logs: "ஜிஹெச்ஜி உமிழ்வு பதிவு",
  add_ghg_emission_data: "ஜிஹெச்ஜி உமிழ்வு பதிவுகளைச் சேர்க்கவும்",
  ghg_scope_conversion: "ஜிஹெச்ஜி வீச்சு மாற்றம்",
  create_ghg_scope_conversion: "ஜிஹெச்ஜி வீச்சு மாற்றத்தை உருவாக்கு",
  ghg_data_logs: "ஜிஹெச்ஜி உமிழ்வு தரவுப் பதிவுகள்",

  choose_inspection_type_optional: "ஆய்வு வகையைத் தேர்வுசெய்க (விருப்பம்)",

  supplier_code: "வழங்குபவர் குறியீடு",
  contact: "தொடர்பு",
  point_of_contact: "தொடர்பு புள்ளி",
  quantity_supplied: "வழங்கிய அளவு",
  create_product: "தயாரிப்பு உருவாக்கு",
  products: "தயாரிப்புகள்",
  data: "தரவு",
  timezone: "நேரம் மண்டலம்",
  tariffs: "கட்டணங்கள்",

  days_delta: "நாட்களின் மாற்றம்",
  machine_groups: "யந்திரக் குழுக்கள்",
  param_groups: "அளவுருக் குழுக்கள்",
  user_groups: "பயனர் குழுக்கள்",

  topic_prefix: "தலைமுறை முன்னோட்டம்",
  message_direction: "செய்தி திசை",

  safety_inspection: "பாதுகாப்பு ஆய்வு",
  write_inspection_log: "ஆய்வு பதிவு எழுதுங்கள்",
  inspection_id: "ஆய்வு அடையாளம்",
  inspector_id: "ஆய்வாளர் அடையாளம்",
  inspection_date: "ஆய்வு தேதி",
  inspection_type: "ஆய்வு வகை",
  findings: "கண்டுபிடிப்புகள்",
  actions_required: "தேவையான நடவடிக்கைகள்",
  followup_date: "தொடர்ச்சி தேதி",
  status: "நிலை",

  safety_incident: "பாதுகாப்பு சம்பவம்",
  write_incident: "சம்பவத்தை எழுதுங்கள்",
  incident_description: "சம்பவத்தின் விளக்கம்",
  severity: "தீவிரம்",
  accident: "விபத்து",
  accident_with_loss: "நஷ்டத்துடன் விபத்து",
  closed: "மூடப்பட்டது",
  corrective_action: "திருத்த நடவடிக்கை",
  employee_id: "உழைப்பாளர் அடையாளம்",
  apply: "விண்ணப்பிக்க",
  choose_location_optional: "இடத்தைத் தேர்வுசெய்க (விருப்பம்)",

  create_tariff: "பிரீமியம் உருவாக்கவும்",
  default_tariff_val: "இயல்புநிலை பிரீமியம் மதிப்பு",
  add_tariff_data: "பிரீமியம் தரவைச் சேர்க்கவும்",
  edit_tariff: "பிரீமியம் திருத்தவும்",
  delete_tariff: "பிரீமியம் நீக்கவும்",
  tariff_value: "பிரீமியம் மதிப்பு",

  machine_status_timeline: "இயந்திர நிலை காலவரிசை",
  total_duration: "மொத்த காலம்",
  generate: "உருவாக்கு",
  remove_machine: "இயந்திரத்தை அகற்று",
  edit_machine_status_timeline_widget:
    "இயந்திர நிலை காலவரிசை விட்ஜெட்டைத் திருத்து",
  add_machine_Status__timeline_widget:
    "இயந்திர நிலை காலவரிசை விட்ஜெட்டைச் சேர்க்கவும்",
  select_timeframe: "காலக்கெடுவைத் தேர்ந்தெடுக்கவும்",
  timeframe_type_shift: "ஷிப்ட்",
  timeframe_type_custom: "தனிப்பயன்",
  timeframe_type_yesterday: "நேற்று",
  edit_report_details: "அறிக்கை விவரங்களைத் திருத்தவும்",
  download_current_template: "தற்போதைய டெம்ப்ளேட்டைப் பதிவிறக்கவும்",
  date_column_index: "தேதி நெடுவரிசை (இண்டெக்ஸ்)",
  select_area: "பகுதியைத் தேர்ந்தெடுக்கவும்",
  dashboard_heading: "டாஷ்போர்டு",
  production_params: "உற்பத்தி அளவுருக்கள்",
  no_modules_configured: "தொகுதிகள் எதுவும் கட்டமைக்கப்படவில்லை",
  choose_type: "வகை தேர்வு",
  panel: "குழு",
  gauge: "அளவு",
  param: "பரம்",
  machine: "இயந்திரம்",
  edit_dashboard_panel: "டாஷ்போர்டு பேனலைத் திருத்தவும்",
  workspace: "பணியிடம்",
  device: "சாதனம்",
  name: "பெயர்",
  time_duration: "கால அளவு",
  submit: "சமர்ப்பிக்க",
  cancel: "ரத்து செய்",
  close: "மூடு",
  reorder_views: "காட்சிகளை மறுவரிசைப்படுத்து",
  create_view: "காட்சியை உருவாக்க",
  view_type: "பார்வை வகை",
  description: "விளக்கம்",
  table_view: "அட்டவணை பார்வை",
  widget_view: "விட்ஜெட் காட்சி",
  dynamic_timeframed_widget_view: "டைனமிக் காலகட்ட விட்ஜெட் காட்சி",
  diagram_view: "வரைபடக் காட்சி",
  energy_usage_view: "ஆற்றல் பயன்பாடு பார்வை",
  machine_scroll_view: "இயந்திர சுருள் பார்வை",
  view_auto_change: "தானாக மாற்றத்தைக் காண்க",
  add_to_dashboard: "டாஷ்போர்டில் சேர்க்கவும்",
  edit_view_name: "காட்சி பெயரைத் திருத்தவும்",
  edit_view: "காட்சித் திருத்தவும்",
  save: "சேமிக்கவும்",
  refresh: "புதுப்பிப்பு",
  delete_view: "பார்வையை நீக்கு",
  create_machine: "இயந்திரத்தை உருவாக்க",
  sequence: "வரிசை",
  machine_type: "இயந்திர வகை",
  physical_machine: "உடல் இயந்திரம்",
  virtual_machine: "மெய்நிகர் இயந்திரம்",
  trend_analysis_tools: "போக்கு பகுப்பாய்வு கருவிகள்",
  energy_consumption_by_machines: "இயந்திரங்கள் மூலம் ஆற்றல் நுகர்வு",
  Create_report: "அறிக்கையை உருவாக்கவும்",
  sheets: "தாள்கள்",
  esg_scope_logging: "ESG ஸ்கோப் பதிவு",
  shifts: "வேலை நேர பகுதி",
  ebill_distribution_report: "இபில் விநியோக அறிக்கை",
  view_report: "அறிக்கையைப் பார்க்கவும்",
  discard: "நிராகரிக்கவும்",
  adjust: "சீர்படுத்து",
  tariff_on: "மீது கட்டணம்",
  active_energy: "செயலில் ஆற்றல்",
  apparent_energy: "வெளிப்படையான ஆற்றல்",
  automate_report: "தானியங்கு அறிக்கை",
  add_root_machine: "ரூட் இயந்திரத்தைச் சேர்க்கவும்",
  add_common_machine: "பொதுவான இயந்திரத்தைச் சேர்க்கவும்",
  notification_groups: "அறிவிப்பு குழுக்கள்",
  add_machines_to_list: "பட்டியலில் இயந்திரங்களைச் சேர்க்கவும்",
  machine_list: "இயந்திர பட்டியல்",
  data_historian: "தரவு வரலாற்றாசிரியர்",
  timeframed_trends: "காலக்கெடு போக்குகள்",
  dual_axis_trend_comparison: "இரட்டை அச்சு போக்கு ஒப்பீடு",
  granular24_hrs_trend: "சிறுமணி 24 மணிநேர போக்கு",
  machinewise_trend: "இயந்திரம் சார்ந்த போக்கு",
  basic_statistical_trends: "அடிப்படை புள்ளியியல் போக்கு",
  log_analysis: "பதிவு பகுப்பாய்வு",
  aqi_comparison_panel: "AQI ஒப்பீட்டு குழு",
  time_of_day_trend: "நாள் போக்கு நேரம்",
  param_historical_comparisons: "அளவுரு வரலாற்று ஒப்பீடுகள்",
  energy_usage_trends: "ஆற்றல் பயன்பாடு போக்குகள்",
  machine_name: "இயந்திரத்தின் பெயர்",
  param_name: "பரம் பெயர்",
  trend_view: "போக்கு பார்வை",
  raw: "மூல",
  trend: "போக்கு",
  normalised: "இயல்பாக்கப்பட்டது",
  from_date: "தேதியிலிருந்து",
  to_date: "இன்றுவரை",
  from: "இருந்து",
  to: "வரை",
  hour: "மணி",
  minute: "நிமிடம்",
  generate_report: "அறிக்கையை உருவாக்கவும்",
  time_range: "Time Range",
  no_data_available: "'தரவு எதுவும் கிடைக்கவில்லை",
  daily: "தினசரி",
  monthly: "மாதாந்திர",
  weekly: "வாரந்தோறும்",
  week: "வாரம்",
  yearly: "ஆண்டுதோறும்",
  device_name: "சாதனத்தின் பெயர்",
  machine_wise_comparison: "இயந்திர வாரியான ஒப்பீடு",
  stats_detailed_report: "புள்ளிவிவரங்கள் விரிவான அறிக்கை",
  log_type: "பதிவு வகை",
  choose_Log: "பதிவைத் தேர்ந்தெடுக்கவும்",
  area: "பகுதி",
  aq_device: "AQ சாதனம்",
  nebeskie_labs_office: "Nebeskie ஆய்வக அலுவலகம்",
  date_range: "தேதி வரம்பு",
  this_year: "இந்த வருடம்",
  "last 6 months": "கடந்த 6 மாதங்கள் ",
  "last quater": "கடந்த காலாண்டில்",
  mtd: "MTD",
  ytd: "YTD",
  yesterday: "நேற்று",
  custom: "தனிப்பயன்",
  run_report: "அறிக்கையை இயக்கவும்",
  create_report: "அறிக்கையை உருவாக்கவும்",
  usage_report: "பயன்பாட்டு அறிக்கை",
  consolidated_report: "ஒருங்கிணைந்த அறிக்கை",
  dynamic_excel_report: "டைனமிக் எக்செல் அறிக்கை",
  energy_usage_report: "ஆற்றல் பயன்பாட்டு அறிக்கை",
  create_action: "செயலை உருவாக்கவும்",
  action_type: "செயல் வகை",
  notification_group: "அறிவிப்பு குழு",
  address: "முகவரி",
  number_of_registers: "பதிவுகளின் எண்ணிக்கை",
  value: "மதிப்பு",
  send_notification: "அறிவிப்பை அனுப்பவும்",
  write_parameter: "அளவுருவை எழுதுங்கள்",
  relays: "ரிலேக்கள்",
  relay_1: "ரிலே 1",
  relay_2: "ரிலே 2",
  relay_3: "ரிலே 3",
  relay_4: "ரிலே 4",
  email_must_be_valid: "மின்னஞ்சல் செல்லுபடியாகும்",
  it_should_be_a_number: "இது ஒரு எண்ணாக இருக்க வேண்டும்",
  it_should_be_a_valid_number: "இது சரியான எண்ணாக இருக்க வேண்டும்",
  operator: "ஆபரேட்டர்",
  operand: "ஓபராண்ட்",
  target_value: "இலக்கு மதிப்பு",
  wait_time_seconds: "காத்திருப்பு நேரம் (வினாடிகள்)",
  greater_than: "விட பெரியது",
  modulus_greater_than: "மாடுலஸ் பெரியதை விட",
  lesser_than: "விட குறைவாக",
  modulus_lesser_than: "மாடுலஸ் குறைவானது",
  equal_to: "சமமாக",
  not_equal_to: " சமமாக இல்லை",
  modulus_equal_to: "மாடுலஸ் சமம்",
  greater_than_equal_to: "சமமானதை விட பெரியது",
  modulus_greater_than_equal_to: "மாடுலஸ் சமமானதை விட பெரியது",
  lesser_than_equal_to: "சமமானதை விட குறைவானது",
  modulus_lesser_than_equal_to: "மாடுலஸ் லெஸ்ஸர் டன் ஈக்வல் டு",
  bit_high: "பிட் ஹை",
  bit_low: "பிட் குறைந்த",
  it_should_be_a_positive_whole_number:
    "இது நேர்மறை முழு எண்ணாக இருக்க வேண்டும்",
  it_should_be_a_positive: "அது நேர்மறையாக இருக்க வேண்டும்",
  create_process: "செயல்முறையை உருவாக்கவும்",
  edit_process: "திருத்து செயல்முறை",
  status_rule: "நிலை விதி",
  create_process_status_rule: "செயல்முறை நிலை விதியை உருவாக்கவும்",
  tag_type: "குறிச்சொல் வகை",
  process_status: "செயல்முறை நிலை",
  machines: "இயந்திரங்கள்",
  running: "ஓடுதல்",
  idling: "சும்மா இருப்பது",
  offline: "ஆஃப்லைன்",
  no_machines_configured: "இயந்திரங்கள் எதுவும் கட்டமைக்கப்படவில்லை",
  delete_process: "செயல்முறையை நீக்கு",
  add_machine_to_process: "செயல்முறைக்கு இயந்திரத்தைச் சேர்க்கவும்",
  add_machine: " இயந்திரத்தைச் சேர்",
  delete_premise_area: "வளாகப் பகுதியை நீக்கு",
  add: "கூட்டு",
  add_to_list: "பட்டியலில் சேர்க்கவும்",
  search: "தேடு",
  switch_to: "மாறிக்கொள்ளுங்கள்",
  switch_to_base_org: "அடிப்படை அமைப்பிற்கு மாறவும்",
  switch: "சொடுக்கி",
  create_new_organisation: "புதிய அமைப்பை உருவாக்கவும்",
  organisation_name: "அமைப்பின் பெயர்",
  organisation_type: "அமைப்பு வகை",
  country: "நாடு",
  country_code: "நாட்டின் குறியீடு",
  state: "மாநிலம்",
  clear: "தெளிவு",
  change_organisation: "அமைப்பை மாற்றவும்",
  organisation_id: "நிறுவன ஐடி",
  update_your_oem_logo: "உங்கள் OEM லோகோவைப் புதுப்பிக்கவும்",
  select_a_logo_to_upload: "பதிவேற்ற ஒரு லோகோவைத் தேர்ந்தெடுக்கவும்",
  submit_logo: "லோகோவைச் சமர்ப்பிக்கவும்",
  create_workspace: "பணியிடத்தை உருவாக்கவும்",
  hub_id: "ஹப் ஐடி",
  name_must_be_less_than_45_characters:
    "பெயர் 45 எழுத்துகளுக்குக் குறைவாக இருக்க வேண்டும்",
  id_is_required: "ஐடி அவசியம்",
  no_space_allowed: "இடம் அனுமதிக்கப்படவில்லை",
  id_must_be_less_than_6_characters:
    "ஐடி 6 எழுத்துகளுக்குக் குறைவாக இருக்க வேண்டும்",
  edit_workspace: "பணியிடத்தைத் திருத்தவும்",
  delete_workspace: "பணியிடத்தை நீக்கு",
  add_modbus_device: "மோட்பஸ் சாதனத்தைச் சேர்க்கவும்",
  add_device: "சாதனத்தைச் சேர்க்கவும்",
  oops: "அச்சச்சோ !!",
  ok: "சரி",
  slave_id: "அடிமை ஐடி",
  device_type: "கருவியின் வகை",
  connection_type: "இணைப்பு வகை",
  mGate: "mகேட்",
  make: "செய்ய",
  model: "மாதிரி",
  name_must_be_less_than_33_characters:
    "பெயர் 33 எழுத்துகளுக்கு குறைவாக இருக்க வேண்டும்",
  name_must_be_less_than_11_characters:
    "பெயர் 11 எழுத்துகளுக்கு குறைவாக இருக்க வேண்டும்",
  it_should_be_a_valid_slave_id:
    "இது செல்லுபடியாகும் ஸ்லேவ் ஐடியாக இருக்க வேண்டும்",
  name_must_be_less_than_4_digits:
    "பெயர் 4 இலக்கங்களுக்கு குறைவாக இருக்க வேண்டும்",
  modbus_tcp: "மோட்பஸ் டிசிபி",
  modbus_rtu: "மோட்பஸ் RTU",
  add_virtual_device: "மெய்நிகர் சாதனத்தைச் சேர்க்கவும்",
  add_mqtt_device: "MQtt சாதனத்தைச் சேர்க்கவும்",
  topic: "தலைப்பு",
  identifier: "அடையாளங்காட்டி",
  identifier_value: "அடையாளங்காட்டி மதிப்பு",
  name_must_be_less_than_20_characters:
    "பெயர் 20 எழுத்துகளுக்கு குறைவாக இருக்க வேண்டும்",
  no_special_characters_or_space_allowed:
    "சிறப்பு எழுத்துகள் அல்லது இடம் அனுமதிக்கப்படவில்லை",
  must_be_less_than_15_characters: "15 எழுத்துகளுக்கு குறைவாக இருக்க வேண்டும்",
  create_role: "பாத்திரத்தை உருவாக்கவும்",
  role: "பங்கு",
  role_is_required: "பங்கு தேவை",
  role_must_be_less_than_40_characters:
    "பாத்திரம் 40 எழுத்துகளுக்கு குறைவாக இருக்க வேண்டும்",
  role_should_not_contain_white_characters:
    "பாத்திரத்தில் வெள்ளை எழுத்துக்கள் இருக்கக்கூடாது",
  comparison: "ஒப்பீடு",
  historical_time_of_day_comparison_parameter: "வரலாறு நேரம் ஒப்பீடு - அளவுரு",
  device_parameter: "சாதன அளவுரு",
  date_1: "தேதி 1",
  date_2: "தேதி 2",
  tod_comparison_report: "TOD ஒப்பீட்டு அறிக்கை",
  time_frame: "கால கட்டம்",
  download_table: "அட்டவணையைப் பதிவிறக்கவும்",
  table: "மேசை",
  showMinMax_values: "குறைந்தபட்ச மற்றும் அதிகபட்ச மதிப்புகளைக் காட்டு",
  usage_or_average: "பயன்பாடு அல்லது சராசரி",
  min_value: "குறைந்தபட்ச மதிப்பு",
  last_12_weeks: "கடந்த 12 வாரங்கள்",
  last_6_months: "கடந்த 6 மாதங்கள்",
  last_12_months: "கடந்த 12 மாதங்கள்",
  year_5: "5 ஆண்டுகள்",
  machinewise_comparison: "இயந்திர வாரியான ஒப்பீடு",
  timeFrame: "காலக்கெடு",
  it_should_be_a_date: "அது ஒரு தேதியாக இருக்க வேண்டும்",
  time_of_day_parameter: "நாள் நேரம் - அளவுரு",
  tod_report: "TOD - அறிக்கை",
  in_this_timeframe: "இந்த காலக்கட்டத்தில்",
  average: "சராசரி",
  maximum: "அதிகபட்சம்",
  minimum: "குறைந்தபட்சம்",
  energy_usage_trend: "ஆற்றல் பயன்பாடு போக்கு",
  standard_deviation: "நிலையான விலகல்",
  statistical_mode: "புள்ளியியல் பயன்முறை",
  production_logs: "உற்பத்தி பதிவுகள்",
  capacity_logs: "திறன் பதிவுகள்",
  dynamic_logs: "டைனமிக் பதிவுகள்",
  pollutants: "மாசுபடுத்திகள்",
  group_by: "குழு மூலம்",
  generate_grouped_trends: "குழுவான போக்குகளை உருவாக்கவும்",
  tCo2: "tCo2",
  scope_1: "நோக்கம் 1",
  scope_2: "நோக்கம் 2",
  scope_3: "நோக்கம் 3",
  source: "ஆதாரம்",
  type: "வகை",
  user_access_log: "பயனர் அணுகல் பதிவு",
  user_logs: "பயனர் பதிவுகள்",
  fetch_logs: "பதிவுகளைப் பெறவும்",
  update_profile: "சுயவிவரத்தைப் புதுப்பிக்கவும்",
  lang: "மொழி",
  theme: "தீம்",
  phone: "தொலைபேசி",
  update: "புதுப்பிக்கவும்",
  update_password: "கடவுச்சொல்லை புதுப்பிக்கவும்",
  change_password: "கடவுச்சொல்லை மாற்று",
  old_password: "பழைய கடவுச்சொல்",
  new_password: "புதிய கடவுச்சொல்",
  confirm_password: "கடவுச்சொல்லை உறுதிப்படுத்தவும்",
  password_must_be_more_than_8_characters:
    "கடவுச்சொல் 8 எழுத்துகளுக்கு மேல் இருக்க வேண்டும்",
  passwords_do_not_match: "கடவுச்சொற்கள் பொருந்தவில்லை",
  add_hub: "மையத்தைச் சேர்",
  id_must_be_less_than_4_characters:
    "ஐடி 4 எழுத்துகளுக்கு குறைவாக இருக்க வேண்டும்",
  add_maintainer: "பராமரிப்பாளரைச் சேர்க்கவும்",
  add_parent_org: "பெற்றோர் அமைப்பைச் சேர்க்கவும்",
  add_parent_to_organisation: "நிறுவனத்தில் பெற்றோரைச் சேர்க்கவும்",
  parent_org_id: "பெற்றோர் அமைப்பு ஐடி",
  add_maintainer_to_organization: "நிறுவனத்தில் பராமரிப்பாளரைச் சேர்க்கவும்",
  maintainer_id: "பராமரிப்பாளர் ஐடி",
  update_organisation_details: "நிறுவன விவரங்களைப் புதுப்பிக்கவும்",
  update_your_organization_details: "உங்கள் நிறுவன விவரங்களைப் புதுப்பிக்கவும்",
  default_email: "இயல்பு மின்னஞ்சல்",
  picker_in_menu: "மெனுவில் பிக்கர்",
  param_diagnostics: "பரம் கண்டறிதல்",
  show_logo: "லோகோவைக் காட்டு",
  show_oem_logo: "OEM லோகோவைக் காட்டு",
  organisation_alias: "அமைப்பு மாற்றுப்பெயர்",
  update_organisation_alias: "அமைப்பு மாற்றுப் பெயரைப் புதுப்பிக்கவும்",
  update_org_alias: "Org மாற்றுப் பெயரைப் புதுப்பிக்கவும்",
  update_your_organization_logo: "உங்கள் நிறுவனத்தின் லோகோவைப் புதுப்பிக்கவும்",
  select_a_logo: "ஒரு லோகோவைத் தேர்ந்தெடுக்கவும்",
  update_logo: "லோகோவைப் புதுப்பிக்கவும்",
  create_uam_user: "UAM பயனரை உருவாக்கவும்",
  overview: "கண்ணோட்டம்",
  batches: "தொகுதிகள்",
  track_batch: "ட்ராக் பேட்ச்",
  batch_movement: "தொகுதி இயக்கம்",
  production_planner: "உற்பத்தி திட்டமிடுபவர்",
  show_unshipped_only: "அனுப்பப்படாததை மட்டும் காட்டு",
  view_logs: "பதிவுகளைப் பார்க்கவும்",
  params: "பரம்ஸ்",
  add_filter: "வடிகட்டியைச் சேர்க்கவும்",
  filters: "வடிப்பான்கள்",
  batch_id: "தொகுதி ஐடி",
  show_details: "விவரங்களை காட்டு",
  tracing_logs: "ட்ரேசிங் பதிவுகள்",
  quantity: "அளவு",
  location_event_type: "இடம் நிகழ்வு வகை",
  fetch_plans: "திட்டங்களைப் பெறுங்கள்",
  sku: "SKU",
  skus: "பங்கு வைப்பு அலகுகள்",

  code: "குறியீடு",
  add_production_plans: "உற்பத்தித் திட்டங்களைச் சேர்க்கவும்",
  date: "தேதி",
  from_time: "காலத்திலிருந்து",
  to_time: "நேரத்திற்கு",
  activities: "செயல்பாடுகள்",
  task_name: "பணி பெயர்",
  task_details: "பணி விவரங்கள்",
  start_date: "தொடக்க தேதி",
  end_date: "கடைசி தேதி",
  assignee: "ஒதுக்கப்பட்டவர்",
  manager: "மேலாளர்",
  activity: "செயல்பாடு",
  activity_type: "செயல்பாட்டு வகை",
  escalation_matrix: "விரிவாக்க மேட்ரிக்ஸ்",
  maintenance_activity: "பராமரிப்பு செயல்பாடு",
  em_level: "EM நிலை",
  delay: "தாமதம்",
  user_group: "பயனர் குழு",
  mins_5: "5 நிமிடம்",
  mins_10: "10 நிமிடம்",
  mins_15: "15 நிமிடம்",
  mins_30: "30 நிமிடம்",
  hour_1: "1 மணி",
  hour_6: "6 மணி",
  day_1: "1 நாள்",
  day: "நாள்",
  day_3: "3 நாள்",
  insights: "நுண்ணறிவு",
  create_emailer_group: "மின்னஞ்சல் குழுவை உருவாக்கவும்",
  notification_type: "அறிவிப்பு வகை",
  email: "மின்னஞ்சல்",
  sms: "எஸ்எம்எஸ்",
  create_machine_group: "இயந்திரக் குழுவை உருவாக்கவும்",
  create_param_group: "பரம் குழுவை உருவாக்கவும்",
  create_user_group: "பயனர் குழுவை உருவாக்கவும்",
  create_module: "தொகுதியை உருவாக்கவும்",
  busbar_temp_module: "பஸ்பார் தற்காலிக தொகுதி",
  power_quality_module: "சக்தி தர தொகுதி",
  esg_module: "ESG தொகுதி",
  edge_hub: "எட்ஜ் ஹப்",
  tolerance: "சகிப்புத்தன்மை",
  ambient_temp_input_type: "சுற்றுப்புற வெப்பநிலை உள்ளீட்டு வகை",
  ambient_temp: "சுற்றுப்புற வெப்பநிலை",
  workspace_ambient_temp: "பணியிட சுற்றுப்புற வெப்பநிலை",
  device_ambient_temp: "சாதனத்தின் சுற்றுப்புற வெப்பநிலை",
  r_temperature: "R வெப்பநிலை",
  y_temperature: "Y வெப்பநிலை",
  b_temperature: "B வெப்பநிலை",
  n_temperature: "N வெப்பநிலை",
  rated_current: "கணக்கிடப்பட்ட மின் அளவு",
  no_load_current: "சுமை மின்னோட்டம் இல்லை",
  r_current: "R மின்சக்தி",
  y_current: "Y மின்சக்தி",
  b_current: "B மின்சக்தி",
  n_current: "N மின்சக்தி",
  delete_activity: "செயல்பாட்டை நீக்கு",
  substation_voltage_kv: "துணை மின்நிலைய மின்னழுத்தம்(KV)",
  transformer_rating_mva: "மின்மாற்றி மதிப்பீடு(MVA)",
  percentage_impedance_of_the_transformer: "% மின்மாற்றியின் மின்மறுப்பு",
  voltage_rv_y: "மின்னழுத்தம் RV(Y)",
  voltage_yb_v: "மின்னழுத்தம் YB(V)",
  voltage_br_v: "மின்னழுத்தம் BR(V)",
  current_r_a: "மின்னழுத்தம் R(A)",
  current_y_a: "மின்சக்தி Y(A)",
  current_b_a: "மின்சக்தி B(A)",
  thd_i: "THD I",
  thd_v: "THD V",
  thd_i1: "THD I1",
  active_power_kw: "செயலில் ஆற்றல் (kw)",
  apparent_power_kva: "வெளிப்படையான சக்தி(KVA)",
  reactive_power_kvar: "எதிர்வினை சக்தி(KVAR)",
  active_energy_kwh: "செயலில் ஆற்றல் (KWH)",
  apparent_energy_kvah: "வெளிப்படையான ஆற்றல் (KVAH)",
  pf: "PF",
  text: "உரை",
  energy_source: "ஆற்றல் ஆதாரம்",
  scope: "வாய்ப்பு",
  system_logs: "கணினி பதிவுகள்",
  system_logbook: "கணினி பதிவு புத்தகம்",
  esg_data_log: "ESG தரவு பதிவு",
  add_esg_data: "ESG தரவைச் சேர்க்கவும்",
  add_esg_data_log: "ESG தரவு பதிவைச் சேர்க்கவும்",
  source_type: "மூல வகை",
  unit_of_measurement: "அளவீட்டு அலகு",
  from_date_optional: "தேதியிலிருந்து (விரும்பினால்)",
  emission_factor: "உமிழ்வு காரணி (kgCO2)",
  create_dynamic_logger: "டைனமிக் லாகரை உருவாக்கவும்",
  dynamic_log: "டைனமிக் பதிவு",
  primary_field_label: "முதன்மை புல லேபிள்",
  primary_field_type: "முதன்மை புல வகை",
  primary: "முதன்மை",
  additional_fields: "கூடுதல் புலங்கள்",
  field_label: "புல லேபிள்",
  field_type: "புல வகை",
  choose_list: "பட்டியலைத் தேர்ந்தெடுக்கவும்",
  list: "பட்டியல்",
  create_capacity_logger: "திறன் பதிவை உருவாக்கவும்",
  capacity_log: "Capacity Log",
  create: "உருவாக்கு",
  create_production_logger: "உற்பத்தி பதிவை உருவாக்கவும்",
  production_log: "உற்பத்தி பதிவு",
  production_logger: "உற்பத்தி பதிவர்",
  input_type: "உள்ளீடு வகை",
  input: "உள்ளீடு",
  input_name: "உள்ளீடு பெயர்",
  output_type: "வெளியீட்டு வகை",
  output: "வெளியீடு",
  output_name: "வெளியீட்டு பெயர்",
  output_params: "வெளியீட்டு அளவுருக்கள்",
  compute_production: "கணக்கீடு உற்பத்தி",
  compute_energy: "ஆற்றலைக் கணக்கிடுங்கள்",
  energy_param_type: "ஆற்றல் பரம் வகை",
  energy_device: "ஆற்றல் சாதனம்",
  energy_param: "ஆற்றல் பரம்",
  production_operator: "உற்பத்தி ஆபரேட்டர்",
  production_correction_factor: "உற்பத்தி திருத்தம் காரணி",
  output_operator: "அவுட்புட் ஆபரேட்டர்",
  output_correction_factor: "வெளியீடு திருத்தம் காரணி",
  input_operator: "உள்ளீடு ஆபரேட்டர்",
  input_correction_factor: "வெளியீடு திருத்தம் காரணி",
  energy_unit: "ஆற்றல் அலகு",
  production_unit: "உற்பத்தி அலகு",
  production: "உற்பத்தி",
  create_data_logger: "தரவு பதிவை உருவாக்கவும்",
  data_log: "தரவு பதிவு",
  add_param: "பரம் சேர்",
  param_id: "பரம் ஐடி",
  create_batch_production_logger: "தொகுதி உற்பத்தி லாகரை உருவாக்கவும்",
  batch_tracing_log: "தொகுதி தடமறிதல் பதிவு",
  log_name: "பதிவு பெயர்",
  location: "இடம்",
  lists: "பட்டியல்கள்",
  param_equations: "பாராமீட்டர் சமன்பாடுகள்",
  combo_params: "ஒருங்கிணைந்த பாராமீட்டர்கள்",
  locations: "இடங்கள்",
  locations_event_types: "இடங்கள் நிகழ்வு வகைகள்",
  info: "தகவல்",
  remarks: "கருத்துக்கள்",
  code_will_be_available_to_log_by_default:
    "இயல்புநிலையாக உள்நுழைய குறியீடு கிடைக்கும்",
  fields: "வயல்வெளிகள்",
  additional_info: "கூடுதல் தகவல்",
  action: "செயல்",
  report_type: "அறிக்கை வகை",
  create_parameter_report: "அளவுரு அறிக்கையை உருவாக்கவும் ",
  data_processing: "தரவு செயலாக்கம்",
  addition: "கூட்டல்",
  singular: "ஒருமை",
  report: "அறிக்கை",
  energy_parameters_kwh: "ஆற்றல் அளவுருக்கள் (KWH)",
  apparent_energy_KVAh: "வெளிப்படையான ஆற்றல் KVAh",
  maximum_demand_MD: "அதிகபட்ச தேவை (MD)",
  excel_frequency: "எக்செல் அதிர்வெண்",
  multiplier: "பெருக்கி",
  single: "ஒற்றை",
  incomers: "உள்வருபவர்கள்",
  sections: "பிரிவுகள்",
  energy_distribution_report: "ஆற்றல் விநியோக அறிக்கை",
  root_machine: "ரூட் மெஷின்",
  add_root_common_machine: "ரூட் & பொதுவான மெஷின் சேர்க்கவும்",
  common_machine: "பொதுவான இயந்திரம்",
  energy_bill_distribution_report: "எரிசக்தி பில் விநியோக அறிக்கை",
  create_consolidated: "ஒருங்கிணைந்த உருவாக்கவும்",
  choose_report: "அறிக்கையைத் தேர்ந்தெடுக்கவும்",
  id: "ஐடி",
  timeframed_sec: "நேரம் கட்டமைக்கப்பட்ட நொடி",
  create_dynamic_excel_report: "டைனமிக் எக்செல் அறிக்கையை உருவாக்கவும்",
  update_dynamic_excel_report: "டைனமிக் எக்செல் அறிக்கையைப் புதுப்பிக்கவும்",
  add_sheet: "தாளைச் சேர்க்கவும்",
  sheet_name: "தாள் பெயர்",
  timestamp: "நேர முத்திரை",
  sheet: "தாள்",
  sheet_id: "தாள்கள் ஐடி",
  delete_sheet: "தாளை நீக்கு",
  report_name: "அறிக்கை பெயர்",
  update_details: "புதுப்பிப்பு விவரங்கள்",
  unit: "அலகு",
  parameters: "அளவுருக்கள்",
  send_excel_file: "எக்செல் கோப்பை அனுப்பவும்",
  update_excel_template_based_report:
    "எக்செல் டெம்ப்ளேட் அடிப்படையிலான அறிக்கையைப் புதுப்பிக்கவும்",
  create_excel_template_based_report:
    "எக்செல் டெம்ப்ளேட் அடிப்படையிலான அறிக்கையை உருவாக்கவும்",
  upload_excel_template_xlsx: "எக்செல் டெம்ப்ளேட்டை பதிவேற்றவும் (xlsx)",
  param_equation: "எக்செல் டெம்ப்ளேட்டை பதிவேற்றவும் (xlsx)",
  edit_sheets: "தாள்களைத் திருத்தவும்",
  row_no: "வரிசை எண்",
  column_no: "நெடுவரிசை எண்",
  row_number: "வரிசை எண்",
  column_number: "நெடுவரிசை எண்",
  no_sheets_added: "தாள்கள் எதுவும் சேர்க்கப்படவில்லை",
  process_based_usage_sheet: "செயல்முறை அடிப்படையிலான பயன்பாட்டுத் தாள்",
  process: "செயல்முறை",
  process_label: "செயல்முறை லேபிள்",
  add_tag_types: "குறிச்சொல் வகைகளைச் சேர்",
  add_process_to_list: "பட்டியலில் செயல்முறையைச் சேர்",
  machine_tag_based_daily_data_sheet:
    "மெஷின் டேக் அடிப்படையிலான தினசரி தரவு தாள்",
  machine_label: "மெஷின் லேபிள்",
  add_machine_to_list: "பட்டியலில் இயந்திரத்தைச் சேர்",
  add_tag_type_to_list: "பட்டியலில் டேக் வகையைச் சேர்",
  shift_based_usage_sheet: "ஷிப்ட் அடிப்படையிலான பயன்பாட்டு தாள்",
  status_for_process: "செயல்முறை",
  status_for_machine: "இயந்திரம்",
  esg_summary_item: "சுருக்கம்",
  esg_details_item: "விவரங்கள்",
  esg_scope1_label: "நோக்கம் 1",
  esg_scope2_label: "நோக்கம் 2",
  esg_scope3_label: "நோக்கம் 3",
  esg_scopes_list_label: "நோக்கங்கள்",
  transpose: "இடமாற்றம்",
  process_based_timeframed_data_sheet:
    "செயல்முறை அடிப்படையிலான காலவரையறை தரவு தாள்",
  add_pocess_to_list: "பட்டியலில் செயல்முறையைச் சேர்",
  " consolidated_shift_based_usage_data_sheet":
    "ஒருங்கிணைந்த ஷிப்ட் அடிப்படையிலான பயன்பாட்டு தரவு தாள்",
  date_format: "தேதி வடிவம்",
  include_process_total: "செயல்முறை மொத்தத்தையும் சேர்",
  machine_group_based_usage_sheet:
    "இயந்திரக் குழு அடிப்படையிலான பயன்பாட்டுத் தாள்",
  machine_group_label: "மெஷின் குரூப் லேபிள்",
  machine_group: "இயந்திரக் குழு",
  add_machine_group_to_list: "பட்டியலில் இயந்திரக் குழுவைச் சேர்",
  parameter_based_usage_data_sheet: "அளவுரு அடிப்படையிலான பயன்பாட்டு தரவு தாள்",
  "show_month_start&end_data": "மாத தொடக்க & முடிவு தரவைக் காட்டு",
  statistics: "புள்ளிவிவரங்கள்",
  add_param_to_list: "பட்டியலில் பரம் சேர்",
  add_timestamp_to_list: "பட்டியலில் நேரமுத்திரையைச் சேர்க்கவும்",
  parameter_group_based_timeframed: "அளவுரு குழு அடிப்படையிலான காலகட்டம்",
  param_group_label: "பரம் குழு லேபிள்",
  param_group: "பரம் குழு",
  add_param_group_to_list: "பட்டியலில் பரம் குழுவைச் சேர்க்கவும்",
  dynamic_logger_based_data_sheet: "டைனமிக் லாகர் அடிப்படையிலான தரவு தாள்",
  dynamic_log_label: "டைனமிக் லாக் லேபிள்",
  parameter_equation_based_sheet: "அளவுரு சமன்பாடு அடிப்படையிலான தாள்",
  data_source: "தரவு மூலம்",
  equation_label: "சமன்பாடு லேபிள்",
  add_equation_to_list: "பட்டியலில் சமன்பாட்டைச் சேர்க்கவும்",
  absolute_values: "முழுமையான மதிப்புகள்",
  consumption_based_sheet: "நுகர்வு அடிப்படையிலான தாள்",
  parameter_name: "அளவுரு பெயர்",
  statistical_analysis_based_sheet:
    "புள்ளியியல் பகுப்பாய்வு அடிப்படையிலான தாள்",
  parameter_instantaneous_data_sheet: "அளவுரு உடனடி தரவு தாள்",
  create_sheet_column: "தாள் நெடுவரிசையை உருவாக்கு",
  column_label: "நெடுவரிசை லேபிள்",
  machine_id: "மெஷின் ஐடி",
  create_machine_list: "மெஷின் பட்டியலை உருவாக்கு",
  create_param_list: "அளவுரு பட்டியலை உருவாக்கு",
  create_sheets: "தாள்களை உருவாக்கவும்",
  create_shift: "ஷிப்டை உருவாக்கு",
  description_optional: "விளக்கம் (விரும்பினால்)",
  create_sku: "SKU ஐ உருவாக்கு",
  create_location: "இடத்தை உருவாக்கு",
  location_type: "இருப்பிட வகை",
  from_location: "இடத்திலிருந்து",
  to_location: "இடத்திற்கு",
  event_type: "நிகழ்வு வகை",
  create_location_event_type: "இருப்பிட நிகழ்வு வகையை உருவாக்கு",
  create_list: "பட்டியல் உருவாக்கு",
  create_param_equation: "பாராமீட்டர் சமன்பாட்டை உருவாக்கு",
  positive_params: "நேர்மறை அளவுருக்கள்",
  params_to_be_substracted: "கழிக்கப்பட வேண்டிய அளவுருக்கள்",
  create_combo_param: "காம்போ பாரம் உருவாக்கு",
  inter_param_operator: "இன்டர் பரம் ஆபரேட்டர்",
  correction_operator: "திருத்த ஆபரேட்டர்",
  correction_operand: "கரெக்ஷன் ஆபராண்ட்",
  create_calculated_param: "கணக்கிடப்பட்ட பரத்தை உருவாக்கு",
  calculated_params: "கணக்கிடப்பட்ட பாராமீட்டர்கள்",
  correction_factor: "திருத்தும் காரணி",
  param1_type: "பரம் 1 வகை",
  param1: "பாரம் 1",
  param1_operator: "பாரம் 1 ஆபரேட்டர்",
  param1_operand: "பரம் 1 ஓபராண்ட்",
  param2_type: "பரம் 2 வகை",
  param2: "பாரம் 2",
  param2_operator: "பரம் 2 ஆபரேட்டர்",
  param2_operand: "பாரம் 2 ஓபராண்ட்",
  create_esg_scope_conversion: "ESG ஸ்கோப் மாற்றத்தை உருவாக்கு",
  category: "வகை",
  get_from_param: "பரமத்திலிருந்து பெறு",
  choose_param: "பரமை தேர்ந்தெடு",
  create_target: "இலக்கை உருவாக்கு",
  create_word: "வார்த்தையை உருவாக்கு",
  words: "சொற்கள்",
  choose_OEM: "OEM ஐ தேர்ந்தெடு",
  get_the_models: "மாடல்களைப் பெறு",
  get_oem_devices: "OEM சாதனங்களைப் பெறு",
  oem_devices: "OEM சாதனங்கள்",
  model_number: "மாடல் எண்",
  register: "பதிவு",
  add_modbus_gateway: "மோட்பஸ் கேட்வேயைச் சேர்",
  " add_modbus_TCP_gateway": "Modbus TCP நுழைவாயிலைச் சேர்",
  IPAddress: "ஐபி முகவரி",
  port_number: "போர்ட் எண்",
  HubID: "ஹப் ஐடி",
  hub: "ஹப்",
  baud_rate: "பாட் விகிதம்",
  parity: "சமநிலை",
  stop_bit: "ஸ்டாப் பிட்",
  update_wifi: "வைஃபை புதுப்பிக்கவும்",
  SSID: "சேவை அமைப்பு அடையாளங்காட்டி",
  password: "கடவுச்சொல்",
  restart_enture: "எண்டூரை மறுதொடக்கம்",
  force_update_configs: "கட்டமைப்பை கட்டாய புதுப்பித்தல்",
  activity_name: "செயல்பாட்டின் பெயர்",
  board: "பலகை",
  calender: "நாட்காட்டி",
  maintenance: "பராமரிப்பு",
  welcome: "வரவேற்பு",
  logout: "வெளியேறு",
  help: "உதவி",
  accounts: "Accounts",
  organisation: "நிறுவனங்கள்",
  pointer_color_optional: "சுட்டி நிறம் (விரும்பினால்)",
  user_id: "பயனர் ஐடி",
  confirm_new_password: "புதிய கடவு சொல்லை உறுதி செய்",
  service: "சேவை",
  send_grid: "கட்டத்தை அனுப்பு",
  default_enture_view: "இயல்புநிலை Enture பார்வை",
  permissions: "அனுமதிகள்",
  areas: "பகுதிகள்",
  remark: "கருத்து",
  days: "நாட்களில்",
  t4_days: "4-நாட்களில்",
  yesterdays_aqi: "நேற்று AQI",
  aqi: "AQI",
  choose_air_quality_device: "காற்றுத் தர சாதனத்தைத் தேர்ந்தெடு",
  delete_this_device: "இந்தச் சாதனத்தை நீக்கு",
  air_quality_label: "காற்று தர லேபிள்",
  humidity: "ஈரப்பதம்",
  temperature: "வெப்ப நிலை",
  watcher: "பார்வையாளர்",
  area_reference: "பகுதி குறிப்பு",
  write_data: "தரவை எழுது",
  day_of_week: "வாரம் ஒரு நாள்",
  month: "மாதம்",
  diagnostics_notification_groups: "கண்டறிதல் அறிவிப்புக் குழுக்கள்",
  hub_name: "மையப் பெயர்",
  hub_model: "மைய மாதிரி",
  hub_type: "மைய வகை",
  update_your_MQTT_bridge_config:
    "உங்கள் MQTT பாலம் கட்டமைப்பைப் புதுப்பிக்கவும்",
  MQTT_bridge_mode: "MQTT பாலம் முறை",
  broker_address: "தரகர் முகவரி",
  update_your_OPC_config: "உங்கள் OPC கட்டமைப்பைப் புதுப்பிக்கவும்",
  OPC_client_mode: "OPC கிளையண்ட் பயன்முறை",
  OPC_server_URL: "OPC சர்வர் URL",
  user_auth: "பயனர் அங்கீகாரம்",
  mode: "முறை",
  security_policy: "பாதுகாப்பு கொள்கை",
  Update_your_Edge_Hub_restart_Schedule:
    "உங்கள் எட்ஜ் ஹப் மறுதொடக்க அட்டவணையைப் புதுப்பிக்கவும்",
  auto_restart: "தானாக மறுதொடக்கம்",
  time_24Hrs: "நேரம் (24 மணிநேரம்)",
  modbus_ping_latency: "மோட்பஸ் பிங் லேட்டன்சி",
  modbus_unhealthy_wait: "மோட்பஸ் ஆரோக்கியமற்ற காத்திருப்பு",
  edit_written_log_book: "எழுதப்பட்ட பதிவு புத்தகத்தை திருத்து",
  field_cant_change: "இந்த புலத்தை மாற்ற முடியாது",
  re_write: "மீண்டும் எழுது",
  write_in_logbook: "பதிவு புத்தகத்தில் எழுது",
  write: "எழுது",
  download_template: "டெம்ப்ளேட்டைப் பதிவிறக்கு",
  write_in_logbook_csv: ". .csv மூலம் பதிவு புத்தகத்தில் எழுது",
  upload_file: "கோப்பைப் பதிவேற்று",
  batch: "தொகுதி",
  move_batch: "மூவ் பேட்ச்",
  on: "ஆன்",
  off: "ஆஃப்",
  fast_mode: "வேகமான பயன்முறை",
  batch_shipping_details: "பேட்ச் ஷிப்பிங் விவரங்கள்",
  vehicle_no: "வாகன எண்",
  net_weight: "நிகர எடை",
  actual_weight: "உண்மையான எடை",
  vendor: "விற்பனையாளர்",
  loaded_by: "ஏற்றப்பட்டது",
  verified_by: "சரிபார்க்கப்பட்டது",
  document_no: "ஆவண எண்",
  multiple_machine_param: "பல இயந்திரம் & பரிமாணங்கள்",
  map_paramid_paramequation_to_row:
    "வரைபடம் பரம் ஐடிகள் அல்லது வரிசைக்கான பரம் சமன்பாடுகள்",
  add_current_date_to_sheet: "தற்போதைய தேதியை தாளில் சேர்",
  create_parameter_from_template: "வார்ப்புருவிலிருந்து அளவுருவை உருவாக்கு",
  selected_parameters_count: "தேர்ந்தெடுக்கப்பட்ட அளவுருக்கள் எண்ணிக்கை",
  restricted_user_access: "கட்டுப்படுத்தப்பட்ட பயனர் அணுகல்",
  select_all_parameters: "அனைத்து அளவுருக்களையும் தேர்ந்தெடு",
  edit_angular_gauge_widget: "ஆங்கிலர் கேஜ் விட்ஜெட்டைத் திருத்து",
  add_angular_gauge_widget: "ஆங்கிலர் கேஜ் விட்ஜெட்டைத் திருத்து",
  widget_label: "விட்ஜெட் லேபிள்",
  widget_width: "விட்ஜெட் அகலம்",
  widget_height: "விட்ஜெட் உயரம்",
  param_label: "பரம் லேபிள்",
  choose_parameter: "அளவுருவை தேர்ந்தெடு",
  processing: "செயலாக்கம்",
  edit_banner_widget: "பேனர் விட்ஜெட்டைத் திருத்து",
  add_banner_widget: "பேனர் விட்ஜெட்டைச் சேர்",
  edit_bartd_widget: "திருத்து பட்டை TD விட்ஜெட்டை",
  add_bar_widget: "பார் விட்ஜெட்டைச் சேர்",
  yaxis_label: "Y - அச்சு லேபிள்",
  xaxis_label: "X - அச்சு லேபிள்",
  y1axis_label: "Y1 - அச்சு லேபிள்",
  y2axis_label: "Y2 - அச்சு லேபிள்",
  y1axis: "Y1 - அச்சு",
  y2axis: "Y2 - அச்சு",
  edit_dual_axis_bar_trend_widget:
    "இரட்டை அச்சு பார் போக்கு விட்ஜெட்டைத் திருத்தவும்",
  add_dual_axis_bar_trend_widget:
    "இரட்டை அச்சு பார் போக்கு விட்ஜெட்டைச் சேர்க்கவும்",
  y1_graph_type: "Y1 அச்சு வரைபட வகை",
  y2_graph_type: "Y2 அச்சு வரைபட வகை",
  idealValue: "சிறந்த மதிப்பு",
  idealLabel: "ஐடியல் லேபிள்",
  add_parameters: "அளவுருகளைச் சேர்",
  add_equation: "சமன்பாட்டைச் சேர்",
  add_params: "அளவைச் சேர்",
  enter_of_machines: "இயந்திரங்களின் எண்ணிக்கையை உள்ளிடவும்",
  edit_bar_trend_td_widget: "பார் ட்ரெண்ட் டிடி விட்ஜெட்டைத் திருத்து",
  add_bar_trend_td_widget: "பார் ட்ரெண்ட் TD விட்ஜெட்டைச் சேர்",
  graph_type: "வரைபட வகை",
  aggregation: "ஒருங்கிணைத்தல்",
  default_graph_type: "இயல்புநிலை வரைபட வகை",
  bar_mode: "பார் முறை",
  show_ideal_line: "ஐடியல் லைனைக் காட்டு",
  equations: "சமன்பாடுகள்",
  edit_bitwise_word_widget: "பிட்வைஸ் வேர்ட் விட்ஜெட்டைத் திருத்து",
  add_bitwise_word_widget: "பிட்வைஸ் வேர்ட் விட்ஜெட்டைச் சேர்",
  show_bit_status: "பிட் நிலையைக் காட்டு",
  edit_bullet_gauge_widget: "புல்லட் கேஜ் விட்ஜெட்டைத் திருத்து",
  add_bullet_gauge_widget: "புல்லட் கேஜ் விட்ஜெட்டைச் சேர்",
  reference_value_for: "குறிப்பு மதிப்பு",
  gauge_type: "கேஜ் வகை",
  indicator_color: "காட்டி நிறம்",
  busbar_temperature_graph_widget: "பஸ் பார் வெப்பநிலை வரைபட விட்ஜெட்",
  busbar_module: "பஸ் பார் தொகுதி",
  edit_consumption_gauge_widget: "நுகர்வு அளவீட்டு விட்ஜெட்டைத் திருத்து",
  add_consumption_gauge_widget: "நுகர்வு அளவீட்டு விட்ஜெட்டைச் சேர்",
  linearGauge: "லீனியர் கேஜ்",
  show_target: "இலக்கை காட்டு",
  healthy_on_below_target: "இலக்குக்குக் கீழே ஆரோக்கியமானது",
  edit_consumption_pie_widget: "நுகர்வு பை விட்ஜெட்டைத் திருத்து",
  add_consumption_pie_widget: "நுகர்வு பை விட்ஜெட்டைச் சேர்",
  add_color_break_point: "கலர் பிரேக் பாயிண்ட்டைச் சேர்",
  custom_color: "தனிப்பயன் நிறம்",
  color: "நிறம்",
  edit_heat_map_widget: "ஹீட் மேப் விட்ஜெட்டைத் திருத்து",
  add_heat_map__widget: "ஹீட் மேப் விட்ஜெட்டைச் சேர்",
  choose_equation: "சமன்பாட்டை தேர்ந்தெடு",
  label_star: "லேபிள்*",
  color_star: "நிறம்*",
  operator1_star: "ஆபரேட்டர் 1*",
  value1_star: "மதிப்பு 1*",
  operator2_star: "ஆபரேட்டர் 2*",
  actions: "செயல்கள்",
  condition: "நிபந்தனைகள்",
  status_color: "நிலை நிறம்",
  text_view: "உரைக் காட்சி",
  edit_process_Status_widget: "செயல்முறை நிலை விட்ஜெட்டைத் திருத்து",
  add_process_Status_widget: "செயல்முறை நிலை விட்ஜெட்டைச் சேர்",
  edit_machine_Status_widget: "இயந்திர நிலை விட்ஜெட்டைத் திருத்து",
  add_machine_Status_widget: "மெஷின் நிலை விட்ஜெட்டைச் சேர்",
  choose_process: "செயல்முறைகளைத் தேர்ந்தெடு",
  choose_machine: "இயந்திரங்களைத் தேர்ந்தெடு",
  table_header_label: "டேபிள் ஹெடர் லேபிள்",
  edit_table_TD_widget: "அட்டவணை TD விட்ஜெட்டைத் திருத்து",
  add_table_TD_widget: "டேபிள் TD விட்ஜெட்டைச் சேர்",
  configure_table: "அட்டவணையை உள்ளமை",
  edit_target_based_trend_widget:
    "இலக்கு அடிப்படையிலான போக்கு விட்ஜெட்டைத் திருத்து",
  add_target_based_trend_widget:
    "இலக்கு அடிப்படையிலான போக்கு விட்ஜெட்டைச் சேர்",
  target: "இலக்கு",
  target_line_label: "இலக்கு வரி லேபிள்",
  edit_usage_score_card: "பயன்பாட்டு மதிப்பெண் அட்டை விட்ஜெட்டைத் திருத்து",
  add_usage_score_card: "பயன்பாட்டு ஸ்கோர்கார்டு விட்ஜெட்டைச் சேர்",
  compare_to: "ஒப்பிடும் பொழுது",
  assign_color: "வண்ணத்தை ஒதுக்கு",
  enter: "உள்ளிடவும்",
  gauge_to_dashboard: "கேஜ் டு டாஷ்போர்டு",
  widget_types: "விட்ஜெட் வகைகள்",
  from_value: "மதிப்பிலிருந்து",
  to_value: "மதிப்புக்கு",
  machine_status: "இயந்திர நிலை",
  deviceA_type: "இயந்திர நிலை",
  templates: "வார்ப்புருக்கள்",
  add_triggers_to_parameter: "அளவுருவில் தூண்டுதல்களைச் சேர்",
  triggers: "தூண்டுதல்கள்",
  show_filter: "வடிப்பானை காட்டு",
  view_id: "ஐடியைக் காண்க",
  move_up: "மேலே நகர்த்து",
  move_down: "கீழே இறங்கு",
  multiplier_types: "பெருக்கி_வகைகள்",
  energy_report: "ஆற்றல் அறிக்கை",
  add_machine_param: "மெஷின் பரம் சேர்",
  aliasing: "அலியாசிங்",
  no_zero_read: "பூஜ்ஜிய வாசிப்பு இல்லை",
  remove_negative_entropy: "எதிர்மறை என்ட்ரோபியை அகற்று",
  calculate_consumption: "நுகர்வு கணக்கீடு",
  disabled: "ஊனமுற்றோர்",
  limiter_value: "லிமிட்டர் மதிப்பு",
  machine_scroll_view_page: "மெஷின் ஸ்க்ரோல் வியூ பக்கம்",
  create_mannual_parameter: "மேனுவல் அளவுருவை உருவாக்கு",
  restrict_user_access: "பயனர் அணுகலைக் கட்டுப்படுத்து",
  add_device_parameter: "சாதன அளவுருவைச் சேர்",
  entropy: "என்ட்ரோபி",
  idle_latency: "செயலற்ற தாமதம்",
  add_OPC_parameter: "OPC அளவுருவைச் சேர்",
  node_id: "நோட் ஐடி",
  byte_format: "பைட் வடிவம்",
  param_scroll_view_page: "பரம் ஸ்க்ரோல் வியூ பக்கம்",
  param_scroll_view: "பரம் ஸ்க்ரோல் வியூ",
  consolidated_view: "ஒருங்கிணைந்த பார்வை",
  timeframed_sec_report: "நேரம் கட்டமைக்கப்பட்ட SEC அறிக்கை",
  prod_unit: "தயாரிப்பு அலகு",
  SEC_unit: "SEC Unit",
  energy_parameters: "ஆற்றல் அளவுருக்கள்",
  prod_param_type: "தயாரிப்பு பரம் வகை",
  production_parameters: "உற்பத்தி அளவுருக்கள்",
  no_of_intervals: "இடைவெளிகள் இல்லை",
  granular_data: "கிரானுலர் தரவு",
  csv: "CSV",
  delete_rule: "விதியை நீக்கு",
  enable_disable_all_parameters: "அனைத்து அளவுருக்களையும் இயக்கு/முடக்கு",
  disable: "முடக்கு",
  enable: "இயக்கு",
  edit_computed_parameter: "கணிக்கப்பட்ட அளவுருவை திருத்து",
  quantization: "அளவுப்படுத்தல்",
  command: "கட்டளை",
  register_size: "பதிவு அளவு",
  consumer_ID: "நுகர்வோர் ஐடி",
  site_id: "தள ஐடி",
  get_data: "தரவைப் பெறு",
  update_param_type: "பரம் வகையைப் புதுப்பிக்கவும்",
  write_in_data_logbook: "தரவு பதிவு புத்தகத்தில் எழுது",
  daily_data: "தினசரி தரவு",
  log_entry: "பதிவு நுழைவு",
  enable_Scroll_mode: "ஸ்க்ரோல் பயன்முறையை இயக்கு",
  multiple_areas: "பல பகுதிகள்",
  time_of_day: "நாள் நேரம்",
  capacity_logger: "திறன் பதிவர்",
  provider: "வழங்குபவர்",
  total_sites_received_bills_MTD: "மொத்த தளங்கள் பெறப்பட்ட பில்கள் MTD",
  sites_yet_to_receive_bills: "இன்னும் பில்களைப் பெற வேண்டிய தளங்கள்",
  total_sites: "மொத்த தளங்கள்",
  sites_received_bills_MTD: "%தளங்கள் MTD பில்களைப் பெற்றன",
  total: "மொத்தம்",
  total_bills_received_mtd: "எம்டிடி பெறப்பட்ட மொத்த பில்கள்",
  arrear_1: "பாக்கி 1",
  arrear_2: "பாக்கி 2",
  no_reading: "வாசிப்பு இல்லை",
  bills_received_in_5_days: "கடந்த 5 நாட்களில் பெறப்பட்ட பில்கள்",
  site_wise_status: "சரி நிலையைத் தவிர தள வாரியான நிலை",
  site_wise_final_summary: "தளம் வாரியான இறுதிச் சுருக்கம்",
  source_link: "மூல இணைப்பு",
  data_type: "தரவு வகை",
  select_an_image_to_upload: "பதிவேற்ற ஒரு படத்தைத் தேர்ந்தெடுக்கவும்",
  user_group_Id: "பயனர் குழு ஐடி",
  user_group_name: "பயனர் குழுவின் பெயர்",
  delay_in_mins: "தாமதம் (நிமிடங்கள்)",
  delete_task: "பணியை நீக்கு",
  watchers: "கண்காணிப்பாளர்கள்",
  comments: "கருத்துகள்",
  asset_under_maintenance: "பராமரிப்பு கீழ் சொத்து",
  start_time: "ஆரம்பிக்கும் நேரம்",
  end_time: "முடிவு நேரம்",
  download_latest_usage_table:
    "சமீபத்திய பயன்பாட்டு அட்டவணையைப் பதிவிறக்கவும் ",
  tag: "குறியிடவும்",
  add_target_data: "இலக்கு தரவைச் சேர்க்கவும்",
  edit_target: "இலக்கைத் திருத்து",
  delete_target: "இலக்கை நீக்கு",
  prop: "ப்ராப்",
  excel_template_based_report: "எக்செல் டெம்ப்ளேட் அடிப்படையிலான அறிக்கை",
  configured_hub: "கட்டமைக்கப்பட்ட மையம்",
  configured_relay: "கட்டமைக்கப்பட்ட ரிலே",
  desired_state: "விரும்பிய மாநிலம்",
  configured_notification_group: "உள்ளமைக்கப்பட்ட அறிவிப்புக் குழு",
  email_ids: "மின்னஞ்சல் ஐடிகள்",
  logs: "பதிவுகள்",
  unit_id: "யூனிட் ஐடி",
  download_report: "அறிக்கையைப் பதிவிறக்கவும்",
  frequency: "அதிர்வெண்",
  ip: "ஐபி",
  edit_wifi: "வைஃபை திருத்தவும்",
  details: "விவரங்கள்",
  connected_to: "இணைக்கப்பட்டுவிட்டது",
  software_version: "மென்பொருள் பதிப்பு",
  mac_address: "Mac முகவரி",
  opc_client_enabled: "OPC கிளையண்ட் இயக்கப்பட்டது",
  MQTT_bridge_enabled: "MQTT பாலம் இயக்கப்பட்டது",
  hub_specification: "ஹப் விவரக்குறிப்பு",
  hub_details: "மைய விவரங்கள்",
  restart_schedule: "மறுதொடக்கம் அட்டவணை",
  restart_device: "சாதனத்தை மறுதொடக்கம் செய்யுங்கள்",
  set_up_restart_time: "மறுதொடக்க நேரத்தை அமைக்கவும்",
  connectivity_logs: "இணைப்பு பதிவுகள்",
  internal_cache_size: "உள் தற்காலிக சேமிப்பு அளவு",
  default: "இயல்புநிலை",
  configs: "கட்டமைப்புகள்",
  processed: "செயலாக்கப்பட்டது",
  space_available: "இடம் கிடைக்கிறது",
  kb: "KB",
  mb: "MB",
  last_ping: "கடைசி பிங்",
  toggle: "நிலைமாற்று",
  configured_creds: "கட்டமைக்கப்பட்ட வரவுகள்",
  psk: "PSK",
  set_value: "மதிப்பை அமைக்கவும்",
  time_current_time:
    "நேரம் (தற்போதைய நேரம் குறிப்பிடப்படாவிட்டால் எடுத்துக்கொள்ளப்படும்)",
  Set: "அமைக்கவும்",
  health: "ஆரோக்கியம்",
  port: "துறைமுகம்",
  mqtt_params: "Mqtt அளவுருக்கள்",
  delete_device: "சாதனத்தை நீக்கு",
  view_details: "விபரங்களை பார்",
  parameter_id: "அளவுரு ஐடி",
  email_address: "மின்னஞ்சல் முகவரி",
  attached_triggers: "இணைக்கப்பட்ட தூண்டுதல்கள்",
  parameter_details: "அளவுரு விவரங்கள்",
  enable_write: "எழுதுவதை இயக்கு",
  disable_write: "எழுதுவதை முடக்கு",
  offset: "ஆஃப்செட்",
  no_of_parameters: "அளவுருக்கள் எண்ணிக்கை",
  creation_time: "உருவாக்கும் நேரம்",
  not_configured: "கட்டமைக்கப்படவில்லை",
  add_rule: "விதியைச் சேர்க்கவும்",
  sl_no: "எஸ்எல் எண்",
  targeted_by_value: "மதிப்பின் மூலம் இலக்கு வைக்கப்பட்டது",
  configured_action: "கட்டமைக்கப்பட்ட செயல்",
  add_action: "செயலைச் சேர்க்கவும்",
  action_id: "செயல் ஐடி",
  delete_user: "பயனரை நீக்கு",
  bit: "பிட்",
  low: "குறைந்த",
  high: "உயர்",
  esg_scope_conversion: "ESG ஸ்கோப் மாற்றம்",
  targets: "இலக்குகள்",
  batch_production_logs: "தொகுதி உற்பத்தி பதிவுகள்",
  esg_data_logs: "ESG தரவு பதிவுகள்",
  remove_from_dashboard: "டாஷ்போர்டிலிருந்து அகற்று",
  data_logs: "தரவு பதிவுகள்",
  select_device: "சாதனத்தைத் தேர்ந்தெடுக்கவும்",
  fetch_connectivity_logs: "இணைப்புப் பதிவுகளைப் பெறவும்",
  hub_internet_connectivity_logs: "ஹப் இணைய இணைப்பு பதிவுகள்",
  modbus_gateways: "மோட்பஸ் கேட்வேஸ்",
  device_switched_on: "சாதனம் இயக்கப்பட்டது",
  device_switched_off: "சாதனம் அணைக்கப்பட்டுள்ளது",
  parameter_report: "அளவுரு அறிக்கை",
  timeframed_specific_energy_consumption_report:
    "காலவரையறை குறிப்பிட்ட ஆற்றல் நுகர்வு அறிக்கை",
  root_user_management: "ரூட் பயனர் மேலாண்மை",
  user_acces_management: "பயனர் அணுகல் மேலாண்மை",
  inference: "அனுமானம்",
  turn_off: "அணைக்கவும்",
  turn_on: "இயக்கவும்",
  diagram_pallette: "வரைபடத் தட்டு",
  add_component: "கூறு சேர்க்கவும்",
  components: "கூறுகள்",
  add_text: "உரையைச் சேர்க்கவும்",
  add_realtime_data: "நிகழ்நேரத் தரவைச் சேர்க்கவும்",
  shapes: "வடிவங்கள்",
  lines: "கோடுகள்",
  text_realtime_component: "நிகழ்நேர கூறு உரை",
  shapes_pallette: "வடிவ தட்டு",
  lines_pallette: "லைனர் தட்டு",
  click_choose: "SLD இல் சேர்க்க வடிவங்களைக் கிளிக் செய்து தேர்வு செய்யவும்",
  choose_line: "SLD இல் சேர்க்க வரியைத் தேர்ந்தெடுக்கவும்",
  choose_image: "படத்தை தேர்வு செய்யவும்",
  upload_image_to_add: "SLD இல் சேர்க்க படத்தைப் பதிவேற்றவும்",
  add_realtime_textdata:
    "எஸ்எல்டியில் நிகழ்நேரம் அல்லது உரைத் தரவைச் சேர்க்கவும்",
  update_user: "பயனரைப் புதுப்பிக்கவும்",
  add_watcher: "கண்காணிப்பாளரைச் சேர்க்கவும்",
  premise_area: "வளாக பகுதி",
  create_computed_param: "கணக்கிடப்பட்ட பரம் உருவாக்கவும்",
  create_derived_param: "பெறப்பட்ட பரம் உருவாக்கவும்",
  proceed_to_ship: "அனுப்ப தொடரவும்",
  esg_data_collection: "ESG தரவு சேகரிப்பு",
  esg_scope_details: "ESG ஸ்கோப் விவரங்கள்",
  select_custom_date_range: "தனிப்பயன் தேதி வரம்பைத் தேர்ந்தெடுக்கவும்",
  days_365: "365 நாட்களில்",
  days_180: "180 நாட்களில்",
  days_30: "30 நாட்களில்",
  create_user_for_SSO: "SSO க்கான பயனரை உருவாக்கவும்",
  add_widget: "விட்ஜெட்டைச் சேர்க்கவும்",
  add_machine_status_rules: "இயந்திர நிலை விதிகளைச் சேர்க்கவும்",
  add_trigger: "தூண்டுதலைச் சேர்க்கவும்",
  add_logged_parameter: "உள்நுழைந்த அளவுருவைச் சேர்க்கவும்",
  create_mannual_param: "மேனுவல் பரம் உருவாக்கவும்",
  add_opc_param: "OPC Param ஐச் சேர்க்கவும்",
  create_write_block: "எழுதும் தொகுதியை உருவாக்கவும்",
  all_parameters: "அனைத்து அளவுருக்கள்",
  edit_device: "சாதனத்தைத் திருத்து",
  edit_modbus_device: "மோட்பஸ் சாதனத்தைத் திருத்து",
  edit_mqtt_device: "Mqtt சாதனத்தைத் திருத்து",
  limit_entropy: "வரம்பு என்ட்ரோபி",
  emails: "மின்னஞ்சல்கள்",
  power_factor: "திறன் காரணி",
  tarrif_rate: "கட்டண விகிதம்",
  apparent_power: "வெளிப்படையான சக்தி",
  reactive_power: "எதிர்வினை சக்தி",
  active_power: "செயலில் ஆற்றல்",
  energy_usage_view_page: "ஆற்றல் பயன்பாடு பார்வை பக்கம்",
  switch_organisation: "அமைப்பு மாறவும்",
  wireless_creds: "வயர்லெஸ் கிரெட்ஸ்",
  latest: "சமீபத்திய",
  value_is: "மதிப்பு உள்ளது",
  please_take_necessary_action: "தயவு செய்து தேவையான நடவடிக்கை எடுங்கள்",
  edit_role: "பாத்திரத்தைத் திருத்து",
  delete_role: "பாத்திரத்தை நீக்கு",
  today: "இன்று",
  days_4: "4 நாட்களில்",
  due: "காரணமாக",
  move_to_done: "முடிந்தது என்பதற்கு நகர்த்தவும்",
  power_system_fault: "பவர் சிஸ்டம் பிழை எண்ணிக்கை (கடந்த வாரம்)",
  alarms: "அலாரங்கள்",
  electricity_consumption: "மின்சார நுகர்வு (மாதம் வரை தேதி) ",
  average_demand: "சராசரி தேவை",
  water_use_intensity: "நீர் பயன்பாட்டு தீவிரம் (WUI) ",
  average_water_cost: "சராசரி தண்ணீர் செலவு (மாதாந்திரம்) ",
  water_use_efficiency: "நீர் பயன்பாட்டு திறன் (WUE)",
  batch_logs: "தொகுதி பதிவுகள்",
  edit_log: "பதிவைத் திருத்து",
  delete_log: "பதிவை நீக்கு",
  dates: "தேதிகள்",
  log_details: "பதிவு விவரங்கள்",
  view: "காண்க",
  scope1_emission: "நோக்கம் 1 உமிழ்வு",
  scope2_emission: "நோக்கம் 2 உமிழ்வு",
  lmtd: "LMTD",
  company_data: "நிறுவனத்தின் தரவு",
  historian: "வரலாற்றாசிரியர்",
  from_optional: "இதிலிருந்து (விரும்பினால்)",
  to_optional: "பெற (விரும்பினால்)",
  trends: "போக்குகள்",
  generate_trend: "போக்கு உருவாக்கவும்",
  by: "மூலம்",
  check_logs: "பதிவுகளை சரிபார்க்கவும்",
  workspaces: "பணியிடங்கள்",
  types_of_nodes_connected: "இணைக்கப்பட்ட முனைகளின் வகைகள்",
  types_of_modbus_device_connected: "இணைக்கப்பட்ட மோட்பஸ் சாதனங்களின் வகைகள்",
  no_of_registers: "பதிவுகளின் எண்ணிக்கை",
  write_register: "பதிவு எழுது",
  setting_view: "காட்சியை அமைத்தல்",
  sign_up: "பதிவு செய்யவும்",
  reset: "மீட்டமை",
  mobile_number: "கைபேசி எண்",
  nebeskie_labs: "நெபெஸ்கி ஆய்வகங்கள்",
  please_register_and_send_otp:
    "உங்கள் பதிவு செய்யப்பட்ட மின்னஞ்சல் ஐடியை உள்ளிட்டு அனுப்பு OTP என்பதைக் கிளிக் செய்யவும்.",
  reset_password: "கடவுச்சொல்லை மீட்டமைக்க",
  back_to_login: "உள்நுழைவுக்குத் திரும்பு",
  otp: "OTP",
  send_otp: "OTP அனுப்பவும்",
  org_alias: "Org மாற்றுப்பெயர்",
  please_stand_by: "கொஞ்சம் பொறுங்கள்",
  total_usage_this_month: "இந்த மாதத்தின் மொத்த பயன்பாடு",
  total_usage_last_month: "கடந்த மாதம் மொத்த பயன்பாடு",
  generate_report_manually: "கைமுறையாக அறிக்கையை உருவாக்கவும்",
  energy_distribution_detailed_report:
    "ஆற்றல் விநியோகம் பற்றிய விரிவான அறிக்கை",
  the_report_is_processing: "அறிக்கை செயலாக்கப்படுகிறது",
  enter_active_energy_manually: "செயலில் உள்ள ஆற்றலை கைமுறையாக உள்ளிடவும்",
  include_others: "மற்றவர்களைச் சேர்க்கவும்",
  bill_amount: "பில் தொகை",
  yes_delete_it: "ஆம், அதை நீக்கு",
  create_write_param: "எழுது பராமை உருவாக்கவும்",
  report_list: "அறிக்கை பட்டியல்",
  plant_id: "தாவர ஐடி",
  plant_name: "தாவர பெயர்",
  plant_address: "தாவர முகவரி",
  add_selected: "தேர்ந்தெடுக்கப்பட்டதைச் சேர்க்கவும்",
  clear_selection: "தேர்வை அழிக்கவும்",
  real_time_value: "உண்மையான நேர மதிப்பு",
  default_status: "இயல்புநிலை நிலை",
  default_label: "இயல்புநிலை லேபிள்",
  default_color_optional: "இயல்புநிலை வண்ணம் விருப்பமானது",
  rule_based_status: "விதி அடிப்படையிலான நிலை",
  show_label_only: "லேபிளை மட்டும் காட்டு",
  show_unit: "ஷோ யூனிட்",
  status_rules: "நிலை விதிகள்",
  configure: "கட்டமைக்கவும்",
  monthly_usage: "மாதாந்திர பயன்பாடு",
  meter_consumption_kwh: "மீட்டர் நுகர்வு(kwh)",
  total_unit: "மொத்த அலகு",
  region: "பிராந்தியம்",
  average_energy_consumed: "சராசரி ஆற்றல் நுகரப்படும்",
  average_production: "சராசரி உற்பத்தி",
  average_sec: "சராசரி SEC",
  total_production: "மொத்த உற்பத்தி",
  total_energy_consumed: "மொத்த ஆற்றல் நுகர்வு",
  max_sec: "அதிகபட்ச எஸ்இசி",
  device_1: "சாதனம் 1",
  device_parameter_1: "சாதன அளவுரு 1",
  device_2: "சாதனம் 2",
  device_parameter_2: "சாதன அளவுரு 2",
  create_param: "பரம் உருவாக்கவும்",
  update_tags: "குறிச்சொற்களைப் புதுப்பிக்கவும்",
  remove_from_process: "செயல்முறையிலிருந்து நீக்கு",
  machine_parameters: "இயந்திர அளவுருக்கள்",
  energy: "ஆற்றல்",
  power: "சக்தி",
  remove_from_featured: "அம்சத்திலிருந்து அகற்று",
  other_parameters: "பிற அளவுருக்கள்",
  add_to_featured: "சிறப்புடன் சேர்க்கவும்",
  logged_parameters: "உள்நுழைந்த அளவுருக்கள்",
  tags: "குறிச்சொற்கள்",
  machine_status_rules: "இயந்திர நிலை விதிகள்",
  connected_communication_devices: "இணைக்கப்பட்ட தகவல் தொடர்பு சாதனங்கள்",
  mqtt_devices: "Mqtt சாதனங்கள்",
  modbus_devices: "மோட்பஸ் சாதனங்கள்",
  total_no_of_params: "பரிமாணங்களின் மொத்த எண்ணிக்கை",
  edge_devices: "விளிம்பு சாதனங்கள்",
  machines_configured: "இயந்திரங்கள் கட்டமைக்கப்பட்டுள்ளன",
  reports_configured: "அறிக்கைகள் கட்டமைக்கப்பட்டுள்ளன",
  views_configured: "காட்சிகள் கட்டமைக்கப்பட்டுள்ளன",
  send_email_notification: "மின்னஞ்சல் அறிவிப்பை அனுப்பவும்",
  enture_send_email_notification:
    "தேர்ந்தெடுக்கப்பட்ட பயனர் குழுவிற்கு மின்னஞ்சல் அறிவிப்பை அனுப்புவோம்",
  agree_and_send: "ஒப்புக்கொண்டு அனுப்பவும்",
  ebill: "மின் மசோதா",
  monthly_trend: "மாதாந்திர போக்கு",
  param_view: "பரம் பார்வை",
  calories: "கலோரிகள்",
  param_count_view: "பரம் எண்ணிக்கை பார்வை",
  created: "உருவாக்கப்பட்டது",
  api_data_count_view: "API தரவு எண்ணிக்கை பார்வை",
  diesel_emissions: "டீசல் உமிழ்வுகள்",
  eb_emissions: "EB உமிழ்வுகள்",
  emissions_kg_co2: "உமிழ்வுகள் கிலோ-கோ2",
  kpis: "KPIகள்",
  jan_23: "Jan-23",
  feb_23: "Feb-23",
  mar_23: "Mar-23",
  apr_23: "Apr-23",
  may_23: "May-23",
  jun_23: "Jun-23",
  lpg_emissions: "LPG உமிழ்வுகள்",
  petrol_emissions: "பெட்ரோல் வெளியேற்றம்",
  bullet_gauge: "புல்லட் கேஜ்",
  equipment: "உபகரணங்கள்",
  enter_no_of_machines: "இயந்திரங்களின் எண்ணிக்கையை உள்ளிடவும்",
  shift: "ஷிப்ட்",
  upload: "பதிவேற்றவும்",
  image_upload: "படப் பதிவேற்றம்",
  derived_parameter: "பெறப்பட்ட அளவுரு",
  value_type: "மதிப்பு_வகை",
  enable_entropy: "என்ட்ரோபியை இயக்கு",
  remove_decimal: "தசமத்தை அகற்று",
  limiter_entropy: "லிமிட்டர் என்ட்ரோபி",
  show_sku_id: "SKU ஐடியைக் காட்டு",
  production_start_date: "உற்பத்தி தொடக்க தேதி (விரும்பினால்)",
  production_start_time: "உற்பத்தி தொடக்க நேரம் (விரும்பினால்)",
  time: "நேரம்",
  temperature_c: "வெப்பநிலை (c)",
  set_temperature_c: "வெப்பநிலையை அமைக்கவும் (C)",
  no_of_people: "மக்கள் எண்ணிக்கை",
  co2_level: "Co2 நிலை",
  aqi_optional: "AQI விருப்பமானது",
  delete_sheets: "தாள்களை நீக்கு",
  root_login: "ரூட் உள்நுழைவு",
  uam_login: "UAM உள்நுழைவு",
  login: "உள்நுழைய",
  add_oem_logo: "OEM லோகோவைச் சேர்க்கவும்",
  devices: "சாதனங்கள்",
  derived_data_units: "பெறப்பட்ட/தரவு அலகுகள்",
  roles: "பாத்திரங்கள்",
  engineering: "பொறியியல்",
  control_panel: "கண்ட்ரோல் பேனல்",
  views: "காட்சிகள்",
  reports_analysis: "அறிக்கைகள் & பகுப்பாய்வு",
  actions_triggers: "செயல்கள் & தூண்டுதல்கள்",
  modules: "தொகுதிகள்",
  processes: "செயல்முறைகள்",
  air_quality: "காற்று தரம்",
  air_quality_view: "காற்று தர பார்வை ",
  manufacturing: "உற்பத்தி",
  esg: "ESG",
  task_managment: "பணி மேலாண்மை",
  sustainability: "நிலைத்தன்மை",
  groups: "குழுக்கள்",
  log_book: "பதிவு புத்தகம்",
  monitor: "கண்காணிக்கவும்",
  enture_settings: "Enture அமைப்புகள்",
  configuration: "கட்டமைப்பு",
  bar: "பார் ",
  line: "வரிசை",
  s7_device: "S7 சாதனம்",
  rack: "ரேக்",
  slot: "ஸ்லாட்",
  db_number: "டிபி எண்",
  byte_offset: "பைட் ஆஃப்செட்",
  bit_offset: "பிட் ஆஃப்செட்",
  data_length: "தரவு நீளம்",
  val_type: "மதிப்பு வகை",
  create_s7_device: "S7 சாதனத்தை உருவாக்கவும்",
  scope_label: "Scopes",
  client_safety: "வாடிக்கையாளர் பாதுகாப்பு",
  edit: "தொகு",
  delete: "அழி",
  choose_param_to_monitor: "கண்காணிக்க பரம் என்பதைத் தேர்ந்தெடுக்கவும்",
  rules: "விதிகள்",
  add_to_row: "வரிசையில் சேர்",
  add_usage: "பயன்பாட்டைச் சேர்க்கவும்",
  notifications: "அறிவிப்புகள்",
  organisation_details: "அமைப்பின் விவரங்கள்",
  profile: "சுயவிவரம்",
  user_access: "பயனர் அணுகல்",
  admin: "நிர்வாகம்",
  add_pie_TD_widget: "பை டிடி விட்ஜெட்டைச் சேர்க்கவும்",
  edit_pie_TD_widget: "பை TD விட்ஜெட்டைத் திருத்து",
  legend: "புராண",
  pie: "பை",
  edit_bar_trend_widget: "பார் ட்ரெண்ட் விட்ஜெட்டைத் திருத்து",
  add_bar_trend_widget: "பார் ட்ரெண்ட் விட்ஜெட்டைச் சேர்க்கவும்",
  edit_horizantal_bar_trend_widget:
    "கிடைமட்ட பட்டை போக்கு விட்ஜெட்டைத் திருத்து",
  add_horizantal_bar_trend_widget:
    "கிடைமட்ட பட்டை போக்கு விட்ஜெட்டைச் சேர்க்கவும்",
  too_long: "மிக நீண்டது",
  trigger_logs: "தூண்டுதல் பதிவுகள்",
  create_trigger: "தூண்டுதலை உருவாக்கவும்",
  right_click_to_configure: "கட்டமைக்க வலது கிளிக் செய்யவும்",
  width: "அகலம்",
  height: "உயரம்",
  hide_value: "மதிப்பை மறை",
  edit_machine: "எடிட் மெஷின்",
  wait_time: "காத்திருக்கும் நேரம்",
  production_date: "தயாரிப்பு தேதி",
  connectivity_status: "இணைப்பு நிலை",
  production_plans: "உற்பத்தித் திட்டங்கள்",
  production_pending: "உற்பத்தி நிலுவையில் உள்ளது",
  pending_shipment: "நிலுவையில் உள்ள ஏற்றுமதி",
  trace_logs: "சுவடு பதிவுகள்",
  movement_events: "தொகுதி இயக்க நிகழ்வுகள்",
  entropy_threshold: "என்ட்ரோபி வரம்பு",
  threshold_value: "வரம்பு மதிப்பு",
  pending_dispatch: "நிலுவையில் உள்ள அனுப்புதல்",
  dispatched: "அனுப்பப்பட்டது",
  dispatch_details: "அனுப்புதல் விவரங்கள்",
  dispatch: "இதை அனுப்பு",
  raw_materials: "மூல பொருட்கள்",
  pour_qty: "அளவு ஊற்றப்பட்டது",
  summary: "சுருக்கம்",
  optional: "விருப்பமானது",
  param_mqtt_map: "பரம் MQTT வரைபடம்",
  machine_status_mqtt_map: "இயந்திர நிலை MQTT வரைபடம்",
  edit_trigger_count_table_widget:
    "தூண்டுதல் எண்ணிக்கை அட்டவணை விட்ஜெட்டைத் திருத்து",
  add_trigger_count_table_widget:
    "தூண்டுதல் எண்ணிக்கை அட்டவணை விட்ஜெட்டைச் சேர்க்கவும்",
  count: "எண்ணிக்கை",
  real_time_consumption: "உண்மையான நேர நுகர்வு",
  search_view: "காட்சி தேடல்",
  clear_view_name_filter: "வடிப்பான்களை அகற்றவும்",
  multiple_entries: "பல உள்ளீடுகள்",
  combined_parameter: "ஒருங்கிணைந்த அளவுரு",
  background_color: "பின்னணி நிறம்",
  resize: "அளவை மாற்றவும்",
  remove: "அகற்றவும்",
  usage: "பயன்பாடு",
  select_views: "காட்சிகளைத் தேர்ந்தெடுக்கவும்",
  difference: "வித்தியாசம்",
  sum: "கூடுதல்",
  positive_workspace: "நேர்மறை பணியிடம்",
  positive_machine: "நேர்மறை இயந்திரம்",
  negative_workspace: "எதிர்மறை பணியிடம்",
  negative_machine: "எதிர்மறை இயந்திரம்",
  set_param_type_for_machine: "இயந்திரத்திற்கான பராம் வகையை அமைக்கவும்",
  param_type: "பாரம் வகை",
  temperature_parameters: "வெப்பநிலை அளவுருக்கள்",
  area_name: "பகுதி பெயர்",
  maintenance_due_on: "பராமரிப்பு நிலுவையில் உள்ள தேதி",
  days_left: "மீதமுள்ள நாட்கள்",
  enture_insights: "துணிகர நுண்ணறிவு",
  edit_logged_parameter: "உள்நுழைந்த அளவுருவைத் திருத்தவும்",
  reports: "அறிக்கைகள்",
  till_date_pie_chart: "தற்காலிக வட்டப்படம்",
  till_date_bar_chart: "தற்காலிக தடி வரைப்படம்",
  tod_billing_widget: "TOD பில்லிங் விட்ஜெட்",
  bar_trend_widget: "தடி பரிணாம விட்ஜெட்",
  target_based_bar_trend_widget: "நோக்கு அடிப்படையிலான தடி பரிணாம விட்ஜெட்",
  gauge_widget: "கேஜ் விட்ஜெட்",
  consumption_pie_widget: "நுகர்வு வட்டப்படம் விட்ஜெட்",
  consumption_gauge_widget: "நுகர்வு கேஜ் விட்ஜெட்",
  angular_gauge_widget: "கோணம் கேஜ் விட்ஜெட்",
  table_widget: "அட்டவணை விட்ஜெட்",
  banner_widget: "பேனர் விட்ஜெட்",
  param_equation_banner_widget: "அளவுரு சமவிகித பேனர் விட்ஜெட்",
  shift_based_usage_table: "ஷிப்ட் அடிப்படையிலான பயன்பாட்டு அட்டவணை",
  bitwise_word_widget: "பிட்வைஸ் வார்த்தை விட்ஜெட்",
  dynamic_log_widget: "மாற்றம் செய்யக்கூடிய பதிவு விட்ஜெட்",
  machine_status_timeline_widget: "இயந்திர நிலை நேரவரிசை விட்ஜெட்",
  process_or_machine_status_widget: "செயல்முறை/இயந்திர நிலை விட்ஜெட்",
  parameter_insights_widget: "அளவுரு பார்வைகள் விட்ஜெட்",
  heat_map: "வெப்ப வரைபடம்",
  usage_score_card_widget: "பயன்பாடு மதிப்பெண் அட்டவணை விட்ஜெட்",
  busbar_live_monitoring: "பஸ் பார் நேரடி கண்காணிப்பு",
  bullet_or_angular_guage_widget: "புல்லட்/கோண கேஜ் விட்ஜெட்",
  dual_axis_bar_trend_widget: "இரட்டை அச்சு தடி பரிணாம விட்ஜெட்",
  trigger_count_table_widget: "முடிவு எண்ணிக்கை அட்டவணை விட்ஜெட்",
  bar_trend_td_widget: "தடி பரிணாம TD விட்ஜெட்",
  horizantal_bar_trend_widget: "கிடைமட்ட தடி பரிணாம விட்ஜெட்",
  parameter_status_based_usage_status: "அளவுரு நிலை அடிப்படையிலான விட்ஜெட்",
  choose_from_templates: "வார்ப்புருக்கள் இடமிருந்து தேர்வு செய்க",
  shift_timeframed_trends: "ஷிப்ட் அடிப்படையிலான காலகட்டம் கொண்ட போக்குகள்",
  candlestick_trends: "கேண்டில்ஸ்டிக் போக்குகள்",
  free_ram: "Free RAM",
  item: "Item",
  item_code: "Item Code",
  department: "Department",
  departments: "Departments",
  issue_vouchers: "Issue Vouchers",
  issue_voucher: "Issue Voucher",
  to_be_approved: "To Be Approved",
  to_be_issued: "To Be Issued",
  intended: "Intended",
  approved: "Approved",
  received: "Received",
  receiver: "Receiver",
  material: "material",
  material_group: "material group",
  material_desc: "Material Description",
  uom: "Unit Of Measurement",
  create_item_code: "Create Item Code",
  create_department: "Create Department",
  users: "Users",
  items: "Items",
  users_in_department: "Users in Department",
  authorisers_in_department: "Authorisers in Department",
  issuers_in_department: "Issuers in Department",
  item_issuer_list: "Item Issuer List",
  add_item_issuer: "Add Item Issuer",
  store: "Store",
  create_issue_voucher: "Create Issue Voucher",
  voucher_requests: "Voucher Requests",
  intended_qty: "Intended Qty",
  issued_qty: "Issued Qty",
  issue_items: "Issue Items",
  voucher_receipt: "Voucher Receipt",
  add_stock: "Add Stock",
  attached_targets: "Attached Targets",
  attach_target: "Attach Target",
  add_target_to_parameter: "Add Target to Parameter",
  empty: "Empty",
  add_day_wise_target: "Add Day wise Target",
  day_wise_targets: "Day Wise Targets",
  fetch_targets: "Fetch Targets",

  day_wise_machine_status_widget: "நாளோடு இயந்திர நிலை விஜெட்",
  add_day_wise_machine_status_widget: "நாளோடு இயந்திர நிலை விஜெட் சேர்க்கவும்",
  edit_day_wise_machine_status_widget: "நாளோடு இயந்திர நிலை விஜெட் திருத்தவும்",
  add_machines: "இயந்திரங்களை சேர்க்கவும்",
  added_machines: "சேர்க்கப்பட்ட இயந்திரங்கள்",
  consumption_start_time: "Consumption Start time",
};
