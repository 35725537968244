import English from "@/utillities/languages/English";

export default {
  ...English,
  checklist_status_sheet: "তালিকা স্থিতি শীট",
  all: "সব",

  start: "শুরু",
  filling: "পূরণ করা",

  accept: "গ্রহণ করুন",
  reject: "প্রত্যাখ্যান করুন",
  move_to_next_stage: "পরবর্তী ধাপে যান",
  save_as_draft: "খসড়া হিসাবে সংরক্ষণ করুন",
  save_and_submit: "সংরক্ষণ করুন এবং জমা দিন",
  closure: "বন্ধ",
  duration: "সময়কাল",
  refill_request: "পুনরায় পূরণের জন্য অনুরোধ",
  is_be_cancelled: "বাতিল করা হবে",
  delete_this: "এটি মুছুন",
  event: "ঘটনা",

  for: "জন্য",
  of: "এর",
  downtime: "ডাউনটাইম",

  metrics: "মেট্রিক্স",
  oee_by_day: "দিন অনুযায়ী OEE",

  ideal_cycle_time: "আদর্শ চক্র সময়",

  minutes: "মিনিট",
  production_time: "উৎপাদন সময়",
  back: "ফিরে",
  down_times: "ডাউন টাইমস",

  top_5_reasons: "শীর্ষ 5 কারণ",
  top_5_categories: "শীর্ষ 5 বিভাগ",
  enter_apparent_energy_manually: "প্রকাশ্য শক্তি ম্যানুয়ালি প্রবেশ করান",

  oee: "OEE",
  reason: "কারণ",
  add_downtime_reason: "ডাউনটাইমের কারণ যোগ করুন",

  default_target: "ডিফল্ট লক্ষ্য",

  enter_in_minutes: "মিনিটে প্রবেশ করুন",
  custome_fields: "কাস্টম ক্ষেত্র",
  enter_data_in_minutes: "কয়েক মিনিটে ডেটা প্রবেশ করান",
  enter_data_in_hours: "ঘন্টাগুলিতে ডেটা লিখুন",
  create_tod_billing_widget: "টড বিলিং উইজেট তৈরি করুন",
  edit_tod_billing_widget: "টড বিলিং উইজেট সম্পাদনা করুন",
  choose_from_machine_groups: "মেশিন গ্রুপ থেকে চয়ন করুন",
  tod_billing: "টড বিলিং",
  choose: "চয়ন করুন",
  create_shift_based_machine_usage_log: "মেশিন ব্যবহার লগ তৈরি করুন",
  shift_based_machine_usage_log: "মেশিন ব্যবহার লগ",
  rejected: "প্রত্যাখ্যান",
  machine_operator: "মেশিন অপারেটর",
  production_end_timestamp: "উত্পাদন শেষ সময়",
  production_start_timestamp: "উত্পাদন শুরুর সময়",
  running_time: "চলমান সময়",
  idle_time: "অলস সময়",
  down_time: "ডাউন সময়",
  date_row_index: "তারিখ সারি",
  date_position: "তারিখের অবস্থান",
  save: "সংরক্ষণ করুন",
  discard: "ফেলে দিন",
  adjust: "সামঞ্জস্য করুন",
  safety_incident_sheet: "সুরক্ষা ঘটনা শীট",
  safety_inspection_sheet: "সুরক্ষা পরিদর্শন শীট",
  ghg_emission_logs: "জিএইচজি নির্গমন লগ",
  add_ghg_emission_data: "জিএইচজি নির্গমন লগ যুক্ত করুন",
  ghg_scope_conversion: "জিএইচজি স্কোপ রূপান্তর",
  create_ghg_scope_conversion: "জিএইচজি স্কোপ রূপান্তর তৈরি করুন",
  ghg_data_logs: "জিএইচজি নির্গমন ডেটা লগ",
  choose_inspection_type_optional: "পরিদর্শন প্রকার চয়ন করুন (al চ্ছিক)",
  supplier_code: "সরবরাহকারী কোড",
  contact: "যোগাযোগ",
  point_of_contact: "যোগাযোগের পয়েন্ট",
  quantity_supplied: "পরিমাণ সরবরাহ করা",
  create_product: "পণ্য তৈরি করুন",
  products: "পণ্য",
  data: "ডেটা",
  timezone: "টাইমজোন",
  tariffs: "শুল্ক",
  days_delta: "দিন ডেল্টা",
  machine_groups: "মেশিন গ্রুপ",
  param_groups: "পারমিটার গ্রুপ",
  user_groups: "ব্যবহারকারী গোষ্ঠী",
  topic_prefix: "বিষয় উপসর্গ",
  message_direction: "বার্তার দিকনির্দেশ",
  safety_inspection: "সুরক্ষা পরিদর্শন",
  write_inspection_log: "পরিদর্শন লগ লিখুন",
  inspection_id: "পরিদর্শন আইডি",
  inspector_id: "পরিদর্শক আইডি",
  inspection_date: "পরিদর্শন তারিখ",
  inspection_type: "পরিদর্শন প্রকার",
  findings: "অনুসন্ধান",
  actions_required: "ক্রিয়া প্রয়োজন",
  followup_date: "তারিখ অনুসরণ করুন",
  status: "স্থিতি",
  safety_incident: "সুরক্ষা ঘটনা",
  write_incident: "ঘটনা লিখুন",
  incident_description: "ঘটনার বিবরণ",
  severity: "তীব্রতা",
  accident: "দুর্ঘটনা",
  accident_with_loss: "ক্ষতির সাথে দুর্ঘটনা",
  closed: "বন্ধ",
  corrective_action: "সংশোধনমূলক ক্রিয়া",
  employee_id: "কর্মচারী আইডি",
  apply: "প্রয়োগ করুন",
  choose_location_optional: "অবস্থান চয়ন করুন (al চ্ছিক)",
  locations: "অবস্থান",
  locations_event_types: "অবস্থান ইভেন্টের ধরণ",
  create_tariff: "শুল্ক তৈরি করুন",
  default_tariff_val: "ডিফল্ট শুল্ক মান",
  add_tariff_data: "শুল্কের ডেটা যুক্ত করুন",
  edit_tariff: "ট্যারিফ সম্পাদনা করুন",
  delete_tariff: "শুল্ক মুছুন",
  tariff_value: "শুল্ক মান",
  machine_status_timeline: "মেশিনের স্থিতি টাইমলাইন",
  total_duration: "মোট সময়কাল",
  generate: "উত্পন্ন",
  remove_machine: "মেশিন সরান",
  edit_machine_status_timeline_widget:
    "মেশিনের স্থিতি টাইমলাইন উইজেট সম্পাদনা করুন",
  add_machine_Status__timeline_widget:
    "মেশিনের স্থিতি টাইমলাইন উইজেট যুক্ত করুন",
  select_timeframe: "সময়সীমার নির্বাচন করুন",
  timeframe_type_shift: "শিফট",
  timeframe_type_custom: "কাস্টম",
  timeframe_type_yesterday: "গতকাল",
  edit_report_details: "রিপোর্টের বিশদ সম্পাদনা করুন",
  download_current_template: "বর্তমান টেম্পলেট ডাউনলোড করুন",
  date_column_index: "তারিখ কলাম (সূচক)",
  select_area: "অঞ্চল নির্বাচন করুন",
  select_shift: "শিফট নির্বাচন করুন",
  dashboard_heading: "ড্যাশবোর্ড",
  production_params: "উত্পাদন পরামিতি",
  no_modules_configured: "কোনও মডিউল কনফিগার করা হয়নি",
  dashboard_panel: "ড্যাশবোর্ড প্যানেল",
  choose_type: "প্রকার চয়ন করুন",
  name_is_required: "নাম প্রয়োজন",
  description_is_required: "বিবরণ প্রয়োজন",
  required: "প্রয়োজনীয়",
  you_have_to_select_something: "আপনাকে কিছু নির্বাচন করতে হবে",
  name_must_be_less_than_30_characters:
    "নাম অবশ্যই 30 টি অক্ষরের চেয়ে কম হতে হবে",
  name_must_be_less_than_25_characters:
    "নাম অবশ্যই 25 টি অক্ষরের চেয়ে কম হতে হবে",
  name_must_be_less_than_50_characters:
    "নাম অবশ্যই 50 টি অক্ষরের চেয়ে কম হতে হবে",
  description_must_be_less_than_35_characters:
    "বিবরণ অবশ্যই 35 টি অক্ষরের চেয়ে কম হতে হবে",
  description_must_be_less_than_55_characters:
    "বিবরণ অবশ্যই 55 টি অক্ষরের চেয়ে কম হতে হবে",
  number_must_be_less_than_6_digits: "সংখ্যা অবশ্যই 6 অঙ্কের চেয়ে কম হতে হবে",
  number_must_be_less_than_8_digits:
    "সংখ্যাটি অবশ্যই 8 ডিজিটের চেয়ে কম হতে হবে",
  number_must_be_less_than_7_digits:
    "সংখ্যাটি অবশ্যই 7 ডিজিটের চেয়ে কম হতে হবে",
  panel: "প্যানেল",
  gauge: "গেজ",
  param: "পরম",
  machine: "মেশিন",
  add_panel_to_dashboard: "ড্যাশবোর্ডে প্যানেল যুক্ত করুন",
  edit_dashboard_panel: "ড্যাশবোর্ড প্যানেল সম্পাদনা করুন",
  param_type: "পরম প্রকার",
  workspace: "কর্মক্ষেত্র",
  device: "ডিভাইস",
  name: "নাম",
  language: "ভাষা",
  time_duration: "সময় সময়কাল",
  submit: "জমা দিন",
  cancel: "বাতিল",
  close: "বন্ধ",
  usage: "ব্যবহার",
  peak_value: "শীর্ষ মান",
  current_data: "বর্তমান ডেটা",
  parameter: "প্যারামিটার",
  computed_param: "গণিত পরম",
  derived_param: "প্রাপ্ত পরম",
  t4_days: "4 দিন",
  last_7_days: "গত 7 দিন",
  last_30_days: "শেষ 30 দিন",
  last_60_days: "গত 60 দিন",
  last_90_days: "গত 90 দিন",
  last_180_days: "গত 180 দিন",
  last_365_days: "ল্যাট 365 দিন",
  last_1_hour: "শেষ 1 ঘন্টা",
  last_3_hours: "শেষ 3 ঘন্টা",
  last_6_hours: "শেষ 6 ঘন্টা",
  reorder_views: "পুনরায় অর্ডার",
  create_view: "তৈরি করুন",
  clear_view_name_filter: "ফিল্টার সাফ করুন",
  view_type: "প্রকার দেখুন",
  description: "বর্ণনা",
  table_view: "টেবিল ভিউ",
  widget_view: "উইজেট ভিউ",
  dynamic_timeframed_widget_view: "গতিশীল সময়সীমার উইজেট ভিউ",
  diagram_view: "ডায়াগ্রাম ভিউ",
  energy_usage_view: "শক্তি ব্যবহার দেখুন",
  machine_scroll_view: "মেশিন স্ক্রোল ভিউ",
  param_scroll_view: "প্যারাম স্ক্রোল ভিউ",
  consolidated_view: "একীভূত দৃশ্য",
  air_quality_view: "বায়ু মানের দেখুন",
  view_auto_change: "অটো পরিবর্তন দেখুন",
  no_views_configured: "কোনও ভিউ কনফিগার করা হয়নি",
  add_to_dashboard: "ড্যাশবোর্ডে যুক্ত করুন",
  edit_view_name: "ভিউ নাম সম্পাদনা করুন",
  edit_view: "ভিউ সম্পাদনা করুন",
  refresh: "রিফ্রেশ",
  create_machine: "মেশিন তৈরি করুন",
  add_machine: "মেশিন যুক্ত করুন",
  sequence: "ক্রম",
  machine_type: "মেশিনের ধরণ",
  physical_machine: "শারীরিক মেশিন",
  virtual_machine: "ভার্চুয়াল মেশিন",
  positive_workspace: "ইতিবাচক কর্মক্ষেত্র",
  positive_machine: "ইতিবাচক মেশিন",
  negative_workspace: "নেতিবাচক কর্মক্ষেত্র",
  negative_machine: "নেতিবাচক মেশিন",
  label: "লেবেল",
  positive_machine_Ids: "ইতিবাচক মেশিন আইডি",
  negative_machine_Ids: "নেতিবাচক মেশিন আইডি",
  trend_analysis_tools: "ট্রেন্ড বিশ্লেষণ সরঞ্জাম",
  energy_consumption_by_machines: "মেশিন দ্বারা শক্তি খরচ",
  Create_report: "প্রতিবেদন তৈরি করুন",
  view_report: "রিপোর্ট দেখুন",
  tarrif_on: "শুল্ক চালু",
  active_energy: "সক্রিয় শক্তি",
  apparent_energy: "আপাত শক্তি",
  automate_report: "স্বয়ংক্রিয় প্রতিবেদন",
  add_root_machine: "রুট মেশিন যুক্ত করুন",
  add_common_machine: "সাধারণ মেশিন যুক্ত করুন",
  notification_groups: "বিজ্ঞপ্তি গ্রুপ",
  add_machines_to_list: "তালিকাতে মেশিন যুক্ত করুন",
  lists: "তালিকা",
  machine_list: "মেশিন তালিকা",
  data_historian: "ডেটা ইতিহাসবিদ",
  timeframed_trends: "সময়সীমার প্রবণতা",
  dual_axis_trend_comparison: "দ্বৈত অক্ষ প্রবণতা তুলনা",
  granular24_hrs_trend: "গ্রানুলার 24 ঘন্টা ট্রেন্ড",
  machinewise_trend: "মেশিন বুদ্ধিমান প্রবণতা",
  basic_statistical_trends: "বেসিক পরিসংখ্যান প্রবণতা",
  log_analysis: "লগ বিশ্লেষণ",
  aqi_comparison_panel: "একিউআই তুলনা প্যানেল",
  time_of_day_trend: "দিনের ট্রেন্ডের সময়",
  param_historical_comparisons: "পরম historical তিহাসিক তুলনা",
  energy_usage_trends: "শক্তি ব্যবহারের প্রবণতা",
  machine_name: "মেশিনের নাম",
  param_name: "পরম নাম",
  trend_view: "ট্রেন্ড ভিউ",
  raw: "কাঁচা",
  trend: "প্রবণতা",
  normalised: "স্বাভাবিক",
  from_date: "তারিখ থেকে",
  to_date: "আজ অবধি",
  from: "থেকে",
  to: "থেকে",
  hour: "ঘন্টা",
  minute: "মিনিট",
  generate_report: "রিপোর্ট উত্পন্ন",
  time_range: "সময়সীমা",
  no_data_available: "কোন ডেটা উপলব্ধ",
  daily: "প্রতিদিন",
  monthly: "মাসিক",
  weekly: "সাপ্তাহিক",
  week: "সপ্তাহ",
  yearly: "বার্ষিক",
  device_name: "ডিভাইসের নাম",
  machine_wise_comparison: "মেশিন অনুসারে তুলনা",
  stats_detailed_report: "পরিসংখ্যান বিস্তারিত প্রতিবেদন",
  log_type: "লগ টাইপ",
  choose_Log: "লগ চয়ন করুন",
  area: "অঞ্চল",
  aq_device: "আক ডিভাইস",
  nebeskie_labs_office: "নেবেস্কি ল্যাবস অফিস",
  date_range: "তারিখ_আরেঞ্জ",
  this_year: "এই বছর",
  "last 6 months": "গত 6 মাস",
  "last quater": "শেষ কোয়ার্টার",
  mtd: "এমটিডি",
  ytd: "Ytd",
  yesterday: "গতকাল",
  custom: "কাস্টম",
  run_report: "রিপোর্ট চালান",
  create_report: "প্রতিবেদন তৈরি করুন",
  usage_report: "ব্যবহারের প্রতিবেদন",
  consolidated_report: "একীভূত প্রতিবেদন",
  dynamic_excel_report: "গতিশীল এক্সেল রিপোর্ট",
  energy_usage_report: "শক্তি ব্যবহারের প্রতিবেদন",
  create_action: "ক্রিয়া তৈরি করুন",
  action_type: "অ্যাকশন টাইপ",
  notification_group: "বিজ্ঞপ্তি গ্রুপ",
  address: "ঠিকানা",
  number_of_registers: "রেজিস্টার সংখ্যা",
  value: "মান",
  send_notification: "বিজ্ঞপ্তি প্রেরণ",
  write_parameter: "প্যারামিটার লিখুন",
  relays: "রিলে",
  relay_1: "রিলে 1",
  relay_2: "রিলে 2",
  relay_3: "রিলে 3",
  relay_4: "রিলে 4",
  email_must_be_valid: "ইমেল অবশ্যই বৈধ হতে হবে",
  it_should_be_a_number: "এটি একটি সংখ্যা হওয়া উচিত",
  it_should_be_a_valid_number: "এটি একটি বৈধ সংখ্যা হওয়া উচিত",
  operator: "অপারেটর",
  operand: "অপারেন্ড",
  targets: "লক্ষ্য",
  target_value: "লক্ষ্য মান",
  wait_time_seconds: "অপেক্ষা করার সময় (সেকেন্ড)",
  greater_than: "এর চেয়ে বড়",
  modulus_greater_than: "মডুলাস এর চেয়ে বড়",
  lesser_than: "এর চেয়ে কম",
  modulus_lesser_than: "মডুলাস চেয়ে কম",
  equal_to: "সমান",
  not_equal_to: "সমান না",
  modulus_equal_to: "মডুলাস সমান",
  greater_than_equal_to: "সমান চেয়ে বড়",
  modulus_greater_than_equal_to: "মডুলাস সমান চেয়ে বড়",
  lesser_than_equal_to: "সমান চেয়ে কম",
  modulus_lesser_than_equal_to: "মডুলাস সমান চেয়ে কম",
  bit_high: "বিট উচ্চ",
  bit_low: "বিট কম",
  it_should_be_a_positive_whole_number:
    "এটি একটি ইতিবাচক পুরো সংখ্যা হওয়া উচিত",
  it_should_be_a_positive: "এটি একটি ইতিবাচক হওয়া উচিত",
  create_process: "প্রক্রিয়া তৈরি করুন",
  edit_process: "প্রক্রিয়া সম্পাদনা",
  status_rule: "স্থিতি নিয়ম",
  create_process_status_rule: "প্রক্রিয়া স্থিতি নিয়ম তৈরি করুন",
  tag_type: "ট্যাগ প্রকার",
  process_status: "প্রক্রিয়া স্থিতি",
  machines: "মেশিন",
  running: "চলমান",
  idling: "অলস",
  offline: "অফলাইন",
  no_machines_configured: "কোনও মেশিন কনফিগার করা হয়নি",
  delete_process: "প্রক্রিয়া মুছুন",
  add_machine_to_process: "প্রক্রিয়াতে মেশিন যুক্ত করুন",
  delete_premise_area: "প্রিমিস অঞ্চল মুছুন",
  add: "যোগ করুন",
  add_to_list: "তালিকায় যুক্ত করুন",
  search: "অনুসন্ধান",
  switch_to: "স্যুইচ করুন",
  switch_to_base_org: "বেস org এ স্যুইচ করুন",
  switch: "স্যুইচ",
  create_new_organisation: "নতুন সংস্থা তৈরি করুন",
  organisation_name: "সংস্থার নাম",
  organisation_type: "সংস্থার ধরণ",
  country_code: "দেশ কোড",
  country: "দেশ",
  state: "রাষ্ট্র",
  clear: "পরিষ্কার",
  change_organisation: "সংস্থা পরিবর্তন",
  organisation_id: "সংস্থা আইডি",
  update_your_oem_logo: "আপনার OEM লোগো আপডেট করুন",
  select_a_logo_to_upload: "আপলোড করতে একটি লোগো নির্বাচন করুন",
  submit_logo: "লোগো জমা দিন",
  create_workspace: "কর্মক্ষেত্র তৈরি করুন",
  hub_id: "হাব আইডি",
  name_must_be_less_than_45_characters:
    "নাম অবশ্যই 45 টি অক্ষরের চেয়ে কম হতে হবে",
  id_is_required: "আইডি প্রয়োজন",
  no_space_allowed: "কোনও জায়গা অনুমোদিত নয়",
  id_must_be_less_than_6_characters:
    "আইডি অবশ্যই 6 টি অক্ষরের চেয়ে কম হতে হবে",
  edit_workspace: "ওয়ার্কস্পেস সম্পাদনা করুন",
  delete_workspace: "কর্মক্ষেত্র মুছুন",
  add_modbus_device: "মোডবাস ডিভাইস যুক্ত করুন",
  add_device: "ডিভাইস যুক্ত করুন",
  oops: "ওফস !!",
  ok: "ঠিক আছে",
  slave_id: "স্লেভ আইডি",
  device_type: "ডিভাইসের ধরণ",
  connection_type: "সংযোগের ধরণ",
  mGate: "এমগেট",
  make: "তৈরি",
  model: "মডেল",
  name_must_be_less_than_33_characters:
    "নাম অবশ্যই 33 টি অক্ষরের চেয়ে কম হতে হবে",
  name_must_be_less_than_11_characters:
    "নাম অবশ্যই 11 টি চরিত্রের চেয়ে কম হতে হবে",
  it_should_be_a_valid_slave_id: "এটি একটি বৈধ স্লেভ আইডি হওয়া উচিত",
  name_must_be_less_than_4_digits: "নাম অবশ্যই 4 টিরও কম হতে হবে",
  modbus_tcp: "মোডবাস টিসিপি",
  modbus_rtu: "মোডবাস আরটিইউ",
  add_virtual_device: "ভার্চুয়াল ডিভাইস যুক্ত করুন",
  add_mqtt_device: "এমকিউটিটি ডিভাইস যুক্ত করুন",
  topic: "বিষয়",
  identifier: "সনাক্তকারী",
  identifier_value: "সনাক্তকারী মান",
  name_must_be_less_than_20_characters: "নাম অবশ্যই 20 টির চেয়ে কম হতে হবে",
  no_special_characters_or_space_allowed:
    "কোনও বিশেষ অক্ষর বা স্থান অনুমোদিত নয়",
  must_be_less_than_15_characters: "15 টি চরিত্রের চেয়ে কম হতে হবে",
  create_role: "ভূমিকা তৈরি করুন",
  role: "ভূমিকা",
  role_is_required: "ভূমিকা প্রয়োজন",
  role_must_be_less_than_40_characters:
    "ভূমিকা অবশ্যই 40 টি চরিত্রের চেয়ে কম হতে হবে",
  role_should_not_contain_white_characters:
    "ভূমিকার মধ্যে সাদা অক্ষর থাকা উচিত নয়",
  comparison: "তুলনা",
  historical_time_of_day_comparison_parameter:
    "দিনের তুলনা ইতিহাসের সময় - প্যারামিটার",
  device_parameter: "ডিভাইস প্যারামিটার",
  date_1: "তারিখ 1",
  date_2: "তারিখ 2",
  tod_comparison_report: "টড তুলনা প্রতিবেদন",
  time_frame: "সময় ফ্রেম",
  download_table: "টেবিল ডাউনলোড করুন",
  table: "টেবিল",
  showMinMax_values: "ন্যূনতম এবং সর্বোচ্চ মান দেখান",
  usage_or_average: "ব্যবহার বা গড়",
  min_value: "ন্যূনতম মান",
  last_12_weeks: "গত 12 সপ্তাহ",
  last_6_months: "গত 6 মাস",
  last_12_months: "গত 12 মাস",
  year_5: "5 বছর",
  machinewise_comparison: "মেশিন অনুসারে তুলনা",
  timeFrame: "সময়সীমা",
  it_should_be_a_date: "এটি একটি তারিখ হওয়া উচিত",
  time_of_day_parameter: "দিনের সময়- প্যারামিটার",
  tod_report: "টড - রিপোর্ট",
  in_this_timeframe: "এই সময়সীমার মধ্যে",
  average: "গড়",
  maximum: "সর্বাধিক",
  minimum: "সর্বনিম্ন",
  energy_usage_trend: "শক্তি ব্যবহারের প্রবণতা",
  standard_deviation: "স্ট্যান্ডার্ড বিচ্যুতি",
  statistical_mode: "পরিসংখ্যান মোড",
  production_logs: "উত্পাদন লগ",
  capacity_logs: "ক্ষমতা লগ",
  dynamic_logs: "গতিশীল লগ",
  pollutants: "দূষণকারী",
  group_by: "গ্রুপ দ্বারা",
  generate_grouped_trends: "গোষ্ঠীযুক্ত প্রবণতা উত্পন্ন করুন",
  tCo2: "টিসিও 2",
  scope_1: "স্কোপ 1",
  scope_2: "স্কোপ 2",
  scope_3: "স্কোপ 3",
  source: "উত্স",
  type: "প্রকার",
  user_access_log: "ব্যবহারকারী অ্যাক্সেস লগ",
  user_logs: "ব্যবহারকারী লগ",
  fetch_logs: "লগ আনুন",
  update_profile: "আপডেট প্রোফাইল",
  lang: "ল্যাং",
  theme: "থিম",
  phone: "ফোন",
  update: "আপডেট",
  update_password: "পাসওয়ার্ড আপডেট করুন",
  change_password: "পাসওয়ার্ড পরিবর্তন করুন",
  old_password: "পুরানো পাসওয়ার্ড",
  new_password: "নতুন পাসওয়ার্ড",
  confirm_password: "পাসওয়ার্ড নিশ্চিত করুন",
  password_must_be_more_than_8_characters:
    "পাসওয়ার্ড অবশ্যই 8 টিরও বেশি অক্ষর হতে হবে",
  passwords_do_not_match: "পাসওয়ার্ড মেলে না",
  add_hub: "হাব যোগ করুন",
  id_must_be_less_than_4_characters:
    "আইডি অবশ্যই 4 টি অক্ষরের চেয়ে কম হতে হবে",
  add_maintainer: "রক্ষণাবেক্ষণকারী যুক্ত করুন",
  add_parent_org: "পিতামাতার org যোগ করুন",
  add_parent_to_organisation: "সংস্থায় পিতামাতাকে যুক্ত করুন",
  parent_org_id: "পিতামাতার org আইডি",
  add_maintainer_to_organization: "সংস্থায় রক্ষণাবেক্ষণকারী যুক্ত করুন",
  maintainer_id: "রক্ষণাবেক্ষণ আইডি",
  update_organisation_details: "সংস্থার বিশদ আপডেট করুন",
  update_your_organization_details: "আপনার সংস্থার বিশদ আপডেট করুন",
  default_email: "ডিফল্ট ইমেল",
  picker_in_menu: "মেনুতে বাছাইকারী",
  param_diagnostics: "প্যারাম ডায়াগনস্টিকস",
  show_logo: "লোগো দেখান",
  show_oem_logo: "ওএম লোগো দেখান",
  organisation_alias: "সংগঠন ওরফে",
  update_organisation_alias: "সংস্থা ওরফে আপডেট করুন",
  update_org_alias: "Org ওরফে আপডেট করুন",
  update_your_organization_logo: "আপনার সংস্থার লোগো আপডেট করুন",
  select_a_logo: "একটি লোগো নির্বাচন করুন",
  update_logo: "আপডেট লোগো",
  create_uam_user: "ইউএএম ব্যবহারকারী তৈরি করুন",
  overview: "ওভারভিউ",
  batches: "ব্যাচ",
  track_batch: "ট্র্যাক ব্যাচ",
  batch_movement: "ব্যাচ চলাচল",
  production_planner: "উত্পাদন পরিকল্পনাকারী",
  show_unshipped_only: "শুধুমাত্র আন শিপড দেখান",
  view_logs: "লগ দেখুন",
  params: "প্যারামস",
  add_filter: "ফিল্টার যুক্ত করুন",
  filters: "ফিল্টার",
  batch_id: "ব্যাচ_আইডি",
  show_details: "বিশদ দেখান",
  tracing_logs: "ট্রেসিং লগ",
  quantity: "পরিমাণ",
  location_event_type: "অবস্থান ইভেন্টের ধরণ",
  fetch_plans: "পরিকল্পনা আনুন",
  sku: "স্কু",
  skus: "স্টক রক্ষণাবেক্ষণ ইউনিট",
  code: "কোড",
  add_production_plans: "উত্পাদন পরিকল্পনা যুক্ত করুন",
  date: "তারিখ",
  from_time: "সময় থেকে",
  to_time: "সময়",
  activities: "ক্রিয়াকলাপ",
  task_name: "টাস্ক নাম",
  task_details: "টাস্ক বিশদ",
  start_date: "শুরু তারিখ",
  end_date: "শেষ তারিখ",
  assignee: "Assignee",
  manager: "ম্যানেজার",
  activity: "ক্রিয়াকলাপ",
  activity_type: "ক্রিয়াকলাপের ধরণ",
  escalation_matrix: "এসকেলেশন ম্যাট্রিক্স",
  maintenance_activity: "রক্ষণাবেক্ষণ ক্রিয়াকলাপ",
  em_level: "EM স্তর",
  delay: "বিলম্ব",
  user_group: "ব্যবহারকারী গ্রুপ",
  mins_5: "5 মিনিট",
  mins_10: "10 মিনিট",
  mins_15: "15 মিনিট",
  mins_30: "30 মিনিট",
  hour_1: "1 ঘন্টা",
  hour_6: "6 ঘন্টা",
  day_1: "1 দিন",
  day: "দিন",
  day_3: "3 দিন",
  insights: "অন্তর্দৃষ্টি",
  create_emailer_group: "ইমেলার গ্রুপ তৈরি করুন",
  notification_type: "বিজ্ঞপ্তি প্রকার",
  email: "ইমেল",
  sms: "এসএমএস",
  create_machine_group: "মেশিন গ্রুপ তৈরি করুন",
  create_param_group: "প্যারাম গ্রুপ তৈরি করুন",
  create_user_group: "ব্যবহারকারী গোষ্ঠী তৈরি করুন",
  create_module: "মডিউল তৈরি করুন",
  busbar_temp_module: "বাসবার টেম্প মডিউল",
  power_quality_module: "পাওয়ার কোয়ালিটি মডিউল",
  esg_module: "ইএসজি মডিউল",
  edge_hub: "এজ হাব",
  tolerance: "সহনশীলতা",
  ambient_temp_input_type: "পরিবেষ্টিত টেম্প ইনপুট প্রকার",
  ambient_temp: "পরিবেষ্টিত টেম্প",
  workspace_ambient_temp: "ওয়ার্কস্পেস অ্যাম্বিয়েন্ট টেম্প",
  device_ambient_temp: "ডিভাইস পরিবেষ্টিত টেম্প",
  r_temperature: "আর তাপমাত্রা",
  y_temperature: "Y তাপমাত্রা",
  b_temperature: "বি তাপমাত্রা",
  n_temperature: "N তাপমাত্রা",
  rated_current: "বর্তমান রেট",
  no_load_current: "কোন লোড বর্তমান",
  r_current: "R বর্তমান",
  y_current: "Y বর্তমান",
  b_current: "বি কারেন্ট",
  n_current: "এন কারেন্ট",
  delete_activity: "ক্রিয়াকলাপ মুছুন",
  substation_voltage_kv: "সাবস্টেশন ভোল্টেজ (কেভি)",
  transformer_rating_mva: "ট্রান্সফর্মার রেটিং (এমভিএ)",
  percentage_impedance_of_the_transformer: "ট্রান্সফর্মার % প্রতিবন্ধকতা",
  voltage_rv_y: "ভোল্টেজ আরভি (y)",
  voltage_yb_v: "ভোল্টেজ yb (v)",
  voltage_br_v: "ভোল্টেজ বিআর (ভি)",
  current_r_a: "বর্তমান আর (ক)",
  current_y_a: "বর্তমান y (ক)",
  current_b_a: "বর্তমান খ (ক)",
  thd_i: "Thd i",
  thd_v: "Thd v",
  thd_i1: "Thd i1",
  active_power_kw: "সক্রিয় শক্তি (কেডব্লিউ)",
  apparent_power_kva: "আপাত শক্তি (কেভিএ)",
  reactive_power_kvar: "প্রতিক্রিয়াশীল শক্তি (কেভিআর)",
  active_energy_kwh: "সক্রিয় শক্তি (কেডাব্লুএইচ)",
  apparent_energy_kvah: "আপাত শক্তি (কেভিএএইচ)",
  pf: "পিএফ",
  text: "পাঠ্য",
  energy_source: "শক্তি উত্স",
  scope: "সুযোগ",
  system_logs: "সিস্টেম লগ",
  system_logbook: "সিস্টেম লগ বই",
  esg_data_log: "ইএসজি ডেটা লগ",
  add_esg_data: "ইএসজি ডেটা যুক্ত করুন",
  add_esg_data_log: "ইএসজি ডেটা লগ যুক্ত করুন",
  source_type: "উত্স প্রকার",
  unit_of_measurement: "পরিমাপের একক",
  from_date_optional: "তারিখ থেকে (al চ্ছিক)",
  emission_factor: "নির্গমন ফ্যাক্টর (কেজিও 2)",
  create_dynamic_logger: "গতিশীল লগার তৈরি করুন",
  dynamic_log: "গতিশীল লগ",
  primary_field_label: "প্রাথমিক ক্ষেত্রের লেবেল",
  primary_field_type: "প্রাথমিক ক্ষেত্রের ধরণ",
  primary: "প্রাথমিক",
  additional_fields: "অতিরিক্ত ক্ষেত্র",
  field_label: "ফিল্ড লেবেল",
  field_type: "ক্ষেত্রের ধরণ",
  choose_list: "তালিকা চয়ন করুন",
  list: "তালিকা",
  create_capacity_logger: "ক্ষমতা লগার তৈরি করুন",
  capacity_log: "ক্ষমতা লগ",
  create: "তৈরি করুন",
  create_production_logger: "উত্পাদন লগার তৈরি করুন",
  production_log: "উত্পাদন লগ",
  production_logger: "উত্পাদন লগার",
  input_type: "ইনপুট প্রকার",
  input: "ইনপুট",
  input_name: "ইনপুট নাম",
  output_type: "আউটপুট টাইপ",
  output: "আউটপুট",
  output_name: "আউটপুট নাম",
  output_params: "আউটপুট প্যারাম",
  compute_production: "গণনা উত্পাদন",
  compute_energy: "গণনা শক্তি",
  energy_param_type: "শক্তি পরম প্রকার",
  energy_device: "শক্তি ডিভাইস",
  energy_param: "শক্তি পরম",
  production_operator: "উত্পাদন অপারেটর",
  production_correction_factor: "উত্পাদন সংশোধন ফ্যাক্টর",
  output_operator: "আউটপুট অপারেটর",
  output_correction_factor: "আউটপুট সংশোধন ফ্যাক্টর",
  input_operator: "ইনপুট অপারেটর",
  input_correction_factor: "আউটপুট সংশোধন ফ্যাক্টর",
  energy_unit: "শক্তি ইউনিট",
  production_unit: "উত্পাদন ইউনিট",
  production: "উত্পাদন",
  create_data_logger: "ডেটা লগার তৈরি করুন",
  data_log: "ডেটা লগ",
  add_param: "পরম যোগ করুন",
  param_id: "পরম আইডি",
  create_batch_production_logger: "ব্যাচ উত্পাদন লগার তৈরি করুন",
  batch_tracing_log: "ব্যাচ ট্রেসিং লগ",
  log_name: "লগ নাম",
  location: "অবস্থান",
  info: "তথ্য",
  remarks: "মন্তব্য",
  code_will_be_available_to_log_by_default:
    "কোডটি ডিফল্টরূপে লগ করার জন্য উপলব্ধ হবে",
  fields: "ক্ষেত্র",
  additional_info: "অতিরিক্ত তথ্য",
  action: "ক্রিয়া",
  report_type: "রিপোর্ট টাইপ",
  create_parameter_report: "প্যারামিটার রিপোর্ট তৈরি করুন",
  data_processing: "ডেটা প্রসেসিং",
  addition: "সংযোজন",
  singular: "একক",
  report: "রিপোর্ট",
  energy_parameters_kwh: "শক্তি পরামিতি (কেডাব্লুএইচ)",
  apparent_energy_KVAh: "আপাত শক্তি কেভাহ",
  maximum_demand_MD: "সর্বাধিক চাহিদা (এমডি)",
  excel_frequency: "এক্সেল ফ্রিকোয়েন্সি",
  multiplier: "গুণক",
  single: "একক",
  incomers: "আয়",
  sections: "বিভাগ",
  energy_distribution_report: "শক্তি বিতরণ প্রতিবেদন",
  root_machine: "রুট মেশিন",
  tariff_on: "শুল্ক চালু",
  add_root_common_machine: "রুট এবং সাধারণ মেশিন যুক্ত করুন",
  common_machine: "সাধারণ মেশিন",
  energy_bill_distribution_report: "শক্তি বিল বিতরণ প্রতিবেদন",
  create_consolidated: "একীভূত তৈরি করুন",
  choose_report: "প্রতিবেদন চয়ন করুন",
  id: "আইডি",
  timeframed_sec: "সময় ফ্রেমড সেকেন্ড",
  create_dynamic_excel_report: "গতিশীল এক্সেল রিপোর্ট তৈরি করুন",
  update_dynamic_excel_report: "গতিশীল এক্সেল রিপোর্ট আপডেট করুন",
  add_sheet: "শীট যোগ করুন",
  sheet_name: "শীট নাম",
  timestamp: "টাইমস্ট্যাম্প",
  sheet: "শীট",
  sheets: "শীট",
  sheet_id: "শীট আইডি",
  delete_sheet: "শীট মুছুন",
  report_name: "নাম রিপোর্ট",
  update_details: "আপডেট বিশদ",
  unit: "ইউনিট",
  parameters: "প্যারামিটার",
  send_excel_file: "এক্সেল ফাইল প্রেরণ করুন",
  update_excel_template_based_report:
    "এক্সেল টেম্পলেট ভিত্তিক প্রতিবেদন আপডেট করুন",
  create_excel_template_based_report:
    "এক্সেল টেম্পলেট ভিত্তিক প্রতিবেদন তৈরি করুন",
  upload_excel_template_xlsx: "এক্সেল টেমপ্লেট (এক্সএলএসএক্স) আপলোড করুন",
  param_equation: "পরম সমীকরণ",
  param_equations: "প্যারামিটার সমীকরণ",
  edit_sheets: "শীট সম্পাদনা করুন",
  row_no: "সারি নং",
  column_no: "কলাম নং",
  row_number: "সারি নম্বর",
  column_number: "কলাম নম্বর",
  colummn_number: "কলাম নম্বর",
  no_sheets_added: "কোনও শীট যুক্ত করা হয়নি",
  process_based_usage_sheet: "প্রক্রিয়া ভিত্তিক ব্যবহার শীট",
  process: "প্রক্রিয়া",
  process_label: "প্রক্রিয়া লেবেল",
  add_tag_types: "ট্যাগ প্রকার যুক্ত করুন",
  add_process_to_list: "তালিকায় প্রক্রিয়া যুক্ত করুন",
  machine_tag_based_daily_data_sheet: "মেশিন ট্যাগ ভিত্তিক দৈনিক ডেটা শীট",
  machine_label: "মেশিন লেবেল",
  add_machine_to_list: "তালিকায় মেশিন যুক্ত করুন",
  add_tag_type_to_list: "তালিকায় ট্যাগ প্রকার যুক্ত করুন",
  shift_based_usage_sheet: "শিফট ভিত্তিক ব্যবহার শীট",
  status_for_process: "প্রক্রিয়া",
  status_for_machine: "মেশিন",
  esg_summary_item: "সংক্ষিপ্তসার",
  esg_details_item: "বিশদ",
  esg_scope1_label: "স্কোপ 1",
  esg_scope2_label: "স্কোপ 2",
  esg_scope3_label: "স্কোপ 3",
  esg_scopes_list_label: "স্কোপস",
  transpose: "ট্রান্সপোজ",
  process_based_timeframed_data_sheet: "প্রক্রিয়া ভিত্তিক সময়সীমার ডেটা শীট",
  add_pocess_to_list: "তালিকায় প্রক্রিয়া যুক্ত করুন",
  tag_types: "ট্যাগ প্রকার",
  " consolidated_shift_based_usage_data_sheet":
    "একীভূত শিফট ভিত্তিক ব্যবহারের ডেটা শীট",
  date_format: "তারিখ ফর্ম্যাট",
  include_process_total: "প্রক্রিয়া মোট অন্তর্ভুক্ত",
  machine_group_based_usage_sheet: "মেশিন গ্রুপ ভিত্তিক ব্যবহার শীট",
  machine_group_label: "মেশিন গ্রুপ লেবেল",
  machine_group: "মেশিন গ্রুপ",
  add_machine_group_to_list: "তালিকাতে মেশিন গ্রুপ যুক্ত করুন",
  parameter_based_usage_data_sheet: "প্যারামিটার ভিত্তিক ব্যবহারের ডেটা শীট",
  "show_month_start&end_data": "মাস শুরু এবং শেষ ডেটা দেখান",
  statistics: "পরিসংখ্যান",
  add_param_to_list: "তালিকায় পরম যুক্ত করুন",
  add_timestamp_to_list: "তালিকাতে টাইমস্ট্যাম্প যুক্ত করুন",
  parameter_group_based_timeframed: "প্যারামিটার গ্রুপ ভিত্তিক সময়সীমা",
  param_group_label: "প্যারাম গ্রুপ লেবেল",
  param_group: "প্যারাম গ্রুপ",
  add_param_group_to_list: "তালিকায় পরম গ্রুপ যুক্ত করুন",
  dynamic_logger_based_data_sheet: "গতিশীল লগার ভিত্তিক ডেটা শীট",
  dynamic_log_label: "গতিশীল লগ লেবেল",
  parameter_equation_based_sheet: "প্যারামিটার সমীকরণ ভিত্তিক শীট",
  data_source: "ডেটা উত্স",
  equation_label: "সমীকরণ লেবেল",
  add_equation_to_list: "তালিকায় সমীকরণ যুক্ত করুন",
  absolute_values: "পরম মান",
  consumption_based_sheet: "খরচ ভিত্তিক শীট",
  parameter_name: "প্যারামিটারের নাম",
  statistical_analysis_based_sheet: "পরিসংখ্যান বিশ্লেষণ ভিত্তিক শীট",
  parameter_instantaneous_data_sheet: "প্যারামিটার তাত্ক্ষণিক ডেটা শীট",
  create_sheet_column: "শীট কলাম তৈরি করুন",
  column_label: "কলাম লেবেল",
  machine_id: "মেশিন আইডি",
  create_machine_list: "মেশিন তালিকা তৈরি করুন",
  create_param_list: "প্যারামিটার তালিকা তৈরি করুন",
  create_sheets: "শীট তৈরি করুন",
  create_shift: "শিফট তৈরি করুন",
  description_optional: "বর্ণনা (al চ্ছিক)",
  create_sku: "স্কু তৈরি করুন",
  create_location: "অবস্থান তৈরি করুন",
  location_type: "অবস্থানের ধরণ",
  from_location: "অবস্থান থেকে",
  to_location: "অবস্থান",
  event_type: "ইভেন্টের ধরণ",
  create_location_event_type: "অবস্থান ইভেন্টের ধরণ তৈরি করুন",
  create_list: "তালিকা তৈরি করুন",
  create_param_equation: "পরম সমীকরণ তৈরি করুন",
  positive_params: "ইতিবাচক পরামিতি",
  params_to_be_substracted: "প্যারামগুলি বিয়োগ করতে হবে",
  create_combo_param: "কম্বো প্যারাম তৈরি করুন",
  combo_params: "সম্মিলিত পরামিতি",
  calculated_params: "গণনা করা পরামিতি",
  inter_param_operator: "ইন্টার প্যারাম অপারেটর",
  correction_operator: "সংশোধন অপারেটর",
  correction_operand: "সংশোধন অপারেন্ড",
  create_calculated_param: "গণনা করা প্যারাম তৈরি করুন",
  calculated_param: "গণনা করা পরম",
  correction_factor: "সংশোধন ফ্যাক্টর",
  param1_type: "প্যারাম 1 টাইপ",
  param1: "পরম 1",
  param1_operator: "প্যারাম 1 অপারেটর",
  param1_operand: "প্যারাম 1 অপারেন্ড",
  param2_type: "প্যারাম 2 টাইপ",
  param2: "পরম 2",
  param2_operator: "প্যারাম 2 অপারেটর",
  param2_operand: "প্যারাম 2 অপারেন্ড",
  create_esg_scope_conversion: "ইএসজি স্কোপ রূপান্তর তৈরি করুন",
  category: "বিভাগ",
  esg_scope_logging: "ইএসজি স্কোপ লগিং",
  get_from_param: "পরম থেকে পান",
  choose_param: "পরম চয়ন করুন",
  create_target: "লক্ষ্য তৈরি করুন",
  create_word: "শব্দ তৈরি করুন",
  words: "শব্দ",
  choose_OEM: "OEM চয়ন করুন",
  get_the_models: "মডেলগুলি পান",
  get_oem_devices: "OEM ডিভাইস পান",
  oem_devices: "OEM ডিভাইস",
  model_number: "মডেল নম্বর",
  register: "নিবন্ধন করুন",
  add_modbus_gateway: "মোডবাস গেটওয়ে যুক্ত করুন",
  " add_modbus_TCP_gateway": "মোডবাস টিসিপি গেটওয়ে যুক্ত করুন",
  IPAddress: "আইপি ঠিকানা",
  port_number: "পোর্ট নম্বর",
  HubID: "হাব আইডি",
  baud_rate: "বাউড রেট",
  parity: "সমতা",
  stop_bit: "বিট থামুন",
  update_wifi: "ওয়াইফাই আপডেট করুন",
  SSID: "Ssid",
  password: "পাসওয়ার্ড",
  restart_enture: "পুনরায় চালু করুন",
  force_update_configs: "ফোর্স আপডেট কনফিগারেশন",
  activity_name: "ক্রিয়াকলাপের নাম",
  board: "বোর্ড",
  calender: "ক্যালেন্ডার",
  maintenance: "রক্ষণাবেক্ষণ",
  welcome: "স্বাগতম",
  logout: "লগআউট",
  help: "সাহায্য",
  accounts: "অ্যাকাউন্ট",
  organisation: "সংগঠন",
  pointer_color_optional: "পয়েন্টার রঙ (al চ্ছিক)",
  user_id: "ব্যবহারকারী আইডি",
  confirm_new_password: "নতুন পাসওয়ার্ড নিশ্চিত করুন",
  service: "পরিষেবা",
  send_grid: "সেন্ডগ্রিড",
  default_enture_view: "ডিফল্ট দৃষ্টিভঙ্গি ভিউ",
  permissions: "অনুমতি",
  areas: "অঞ্চল",
  remark: "মন্তব্য",
  days: "দিন",
  yesterdays_aqi: "গতকাল একিউআই",
  aqi: "আকি",
  choose_air_quality_device: "বায়ু মানের ডিভাইস চয়ন করুন",
  delete_this_device: "এই ডিভাইসটি মুছুন",
  air_quality_label: "বায়ু মানের লেবেল",
  humidity: "আর্দ্রতা",
  temperature: "তাপমাত্রা",
  watcher: "প্রহরী",
  area_reference: "অঞ্চল রেফারেন্স",
  write_data: "ডেটা লিখুন",
  day_of_week: "সপ্তাহের দিন",
  month: "মাস",
  diagnostics_notification_groups: "ডায়াগনস্টিকস বিজ্ঞপ্তি গোষ্ঠী",
  hub_name: "হাব নাম",
  hub_model: "হাব মডেল",
  hub_type: "হাব টাইপ",
  hub: "হাব",
  update_your_MQTT_bridge_config: "আপনার এমকিউটিটি ব্রিজ কনফিগারেশন আপডেট করুন",
  MQTT_bridge_mode: "এমকিউটিটি ব্রিজ মোড",
  broker_address: "ব্রোকার ঠিকানা",
  update_your_OPC_config: "আপনার ওপিসি কনফিগারেশন আপডেট করুন",
  OPC_client_mode: "ওপিসি ক্লায়েন্ট মোড",
  OPC_server_URL: "ওপিসি সার্ভার ইউআরএল",
  user_auth: "ব্যবহারকারী প্রমাণ",
  mode: "মোড",
  security_policy: "সুরক্ষা নীতি",
  Update_your_Edge_Hub_restart_Schedule:
    "আপনার এজ হাব পুনঃসূচনা সময়সূচী আপডেট করুন",
  auto_restart: "অটো পুনঃসূচনা",
  time_24Hrs: "সময় (24 ঘন্টা)",
  modbus_ping_latency: "মোডবাস পিং বিলম্ব",
  modbus_unhealthy_wait: "মোডবাস অস্বাস্থ্যকর অপেক্ষা",
  edit_written_log_book: "লিখিত লগ বই সম্পাদনা করুন",
  field_cant_change: "এই ক্ষেত্র পরিবর্তন করতে পারে না",
  re_write: "পুনর্লিখন",
  write_in_logbook: "লগবুক লিখুন",
  write: "লিখুন",
  download_template: "টেমপ্লেট ডাউনলোড করুন",
  write_in_logbook_csv: ".Csv দ্বারা লগ বইতে লিখুন",
  upload_file: "ফাইল আপলোড করুন",
  batch: "ব্যাচ",
  move_batch: "ব্যাচ সরান",
  on: "চালু",
  off: "বন্ধ",
  fast_mode: "দ্রুত মোড",
  batch_shipping_details: "ব্যাচের শিপিংয়ের বিশদ",
  vehicle_no: "যানবাহন নং",
  net_weight: "নেট ওজন",
  actual_weight: "প্রকৃত ওজন",
  vendor: "বিক্রেতা",
  loaded_by: "লোড দ্বারা",
  verified_by: "দ্বারা যাচাই করা",
  document_no: "নথি নং",
  multiple_machine_param: "একাধিক মেশিন এবং প্যারাম",
  map_paramid_paramequation_to_row:
    "মানচিত্র পরম আইডির বা সারি থেকে প্যারাম সমীকরণ",
  add_current_date_to_sheet: "শীটে বর্তমান তারিখ যুক্ত করুন",
  create_parameter_from_template: "টেমপ্লেট থেকে প্যারামিটার তৈরি করুন",
  selected_parameters_count: "নির্বাচিত পরামিতি গণনা",
  restricted_user_access: "সীমাবদ্ধ ব্যবহারকারী অ্যাক্সেস",
  select_all_parameters: "সমস্ত পরামিতি নির্বাচন করুন",
  edit_angular_gauge_widget: "কৌণিক গেজ উইজেট সম্পাদনা করুন",
  add_angular_gauge_widget: "কৌণিক গেজ উইজেট যুক্ত করুন",
  widget_label: "উইজেট লেবেল",
  widget_width: "উইজেট প্রস্থ",
  widget_height: "উইজেট উচ্চতা",
  param_label: "প্যারাম লেবেল",
  choose_parameter: "প্যারামিটার চয়ন করুন",
  processing: "প্রক্রিয়াজাতকরণ",
  edit_banner_widget: "ব্যানার উইজেট সম্পাদনা করুন",
  add_banner_widget: "ব্যানার উইজেট যুক্ত করুন",
  edit_bartd_widget: "বার টিডি উইজেট সম্পাদনা করুন",
  add_bar_widget: "বার উইজেট যুক্ত করুন",
  yaxis_label: "Y - অক্ষ লেবেল",
  xaxis_label: "এক্স - অক্ষ লেবেল",
  y1axis_label: "Y1 - অক্ষ লেবেল",
  y2axis_label: "Y2 - অক্ষ লেবেল",
  edit_dual_axis_bar_trend_widget: "দ্বৈত অক্ষ বার ট্রেন্ড উইজেট সম্পাদনা করুন",
  add_dual_axis_bar_trend_widget: "দ্বৈত অক্ষ বার ট্রেন্ড উইজেট যুক্ত করুন",
  y1_graph_type: "Y1 অক্ষ গ্রাফ প্রকার",
  y2_graph_type: "Y2 অক্ষ গ্রাফ প্রকার",
  idealValue: "আইডিয়ালভ্যালু",
  idealLabel: "আদর্শ লেবেল",
  add_parameters: "পরামিতি যুক্ত করুন",
  add_equation: "সমীকরণ যুক্ত করুন",
  add_params: "প্যারাম যুক্ত করুন",
  enter_of_machines: "মেশিনগুলির কোনও প্রবেশ করান",
  edit_bar_trend_td_widget: "বার ট্রেন্ড টিডি উইজেট সম্পাদনা করুন",
  add_bar_trend_td_widget: "বার ট্রেন্ড টিডি উইজেট যুক্ত করুন",
  graph_type: "গ্রাফ টাইপ",
  aggregation: "সমষ্টি",
  default_graph_type: "ডিফল্ট গ্রাফ প্রকার",
  bar_mode: "বার মোড",
  show_ideal_line: "আদর্শ লাইন দেখান",
  equations: "সমীকরণ",
  edit_bitwise_word_widget: "বিটওয়াইজ ওয়ার্ড উইজেট সম্পাদনা করুন",
  add_bitwise_word_widget: "বিটওয়াইজ ওয়ার্ড উইজেট যুক্ত করুন",
  show_bit_status: "বিট স্ট্যাটাস দেখান",
  edit_bullet_gauge_widget: "বুলেট গেজ উইজেট সম্পাদনা করুন",
  add_bullet_gauge_widget: "বুলেট গেজ উইজেট যুক্ত করুন",
  reference_value_for: "জন্য রেফারেন্স মান",
  gauge_type: "গেজ টাইপ",
  indicator_color: "সূচক রঙ",
  busbar_temperature_graph_widget: "বাস বার তাপমাত্রা গ্রাফ উইজেট",
  busbar_module: "বাস বার মডিউল",
  edit_consumption_gauge_widget: "খরচ গেজ উইজেট সম্পাদনা করুন",
  add_consumption_gauge_widget: "গ্রাহক গেজ উইজেট যুক্ত করুন",
  linearGauge: "লিনিয়ার গেজ",
  show_target: "লক্ষ্য দেখান",
  healthy_on_below_target: "নীচে লক্ষ্য স্বাস্থ্যকর",
  edit_consumption_pie_widget: "খরচ পাই উইজেট সম্পাদনা করুন",
  add_consumption_pie_widget: "ব্যবহার পাই উইজেট যুক্ত করুন",
  add_color_break_point: "রঙ ব্রেক পয়েন্ট যুক্ত করুন",
  custom_color: "কাস্টম রঙ",
  color: "রঙ",
  edit_heat_map_widget: "তাপ মানচিত্রের উইজেট সম্পাদনা করুন",
  add_heat_map__widget: "তাপ মানচিত্রের উইজেট যুক্ত করুন",
  choose_equation: "সমীকরণ চয়ন করুন",
  label_star: "লেবেল*",
  color_star: "রঙ*",
  operator1_star: "অপারেটর 1*",
  value1_star: "মান 1*",
  operator2_star: "অপারেটর 2*",
  actions: "ক্রিয়া",
  condition: "শর্তাবলী",
  status_color: "স্থিতি রঙ",
  text_view: "পাঠ্য দেখুন",
  edit_process_Status_widget: "প্রক্রিয়া স্থিতি উইজেট সম্পাদনা করুন",
  add_process_Status_widget: "প্রক্রিয়া স্থিতি উইজেট যুক্ত করুন",
  edit_machine_Status_widget: "মেশিনের স্থিতি উইজেট সম্পাদনা করুন",
  add_machine_Status_widget: "মেশিনের স্থিতি উইজেট যুক্ত করুন",
  choose_process: "প্রক্রিয়া চয়ন করুন",
  choose_machine: "মেশিন চয়ন করুন",
  table_header_label: "টেবিল শিরোনাম লেবেল",
  edit_table_TD_widget: "টেবিল টিডি উইজেট সম্পাদনা করুন",
  add_table_TD_widget: "টেবিল টিডি উইজেট যুক্ত করুন",
  configure_table: "টেবিল কনফিগার করুন",
  edit_target_based_trend_widget: "লক্ষ্য ভিত্তিক ট্রেন্ড উইজেট সম্পাদনা করুন",
  add_target_based_trend_widget: "লক্ষ্য ভিত্তিক ট্রেন্ড উইজেট যুক্ত করুন",
  target: "লক্ষ্য",
  target_line_label: "টার্গেট লাইন লেবেল",
  edit_usage_score_card: "ব্যবহার স্কোরকার্ড উইজেট সম্পাদনা করুন",
  add_usage_score_card: "ব্যবহার স্কোরকার্ড উইজেট যুক্ত করুন",
  compare_to: "তুলনা করুন",
  assign_color: "রঙ নির্ধারণ করুন",
  enter: "প্রবেশ করুন",
  gauge_to_dashboard: "ড্যাশবোর্ডে গেজ",
  widget_types: "উইজেট প্রকার",
  from_value: "মান থেকে",
  to_value: "মূল্য",
  machine_status: "মেশিনের স্থিতি",
  deviceA_type: "ডিভাইস একটি প্রকার",
  templates: "টেমপ্লেট",
  add_triggers_to_parameter: "প্যারামিটারে ট্রিগার যুক্ত করুন",
  triggers: "ট্রিগার",
  show_filter: "ফিল্টার দেখান",
  view_id: "আইডি দেখুন",
  move_up: "উপরে সরান",
  move_down: "নীচে সরান",
  multiplier_types: "গুণক_ টাইপস",
  energy_report: "শক্তি প্রতিবেদন",
  add_machine_param: "মেশিন প্যারাম যোগ করুন",
  aliasing: "এলিয়াসিং",
  no_zero_read: "কোন শূন্য পড়া",
  remove_negative_entropy: "নেতিবাচক এনট্রপি সরান",
  calculate_consumption: "খরচ গণনা করুন",
  disabled: "অক্ষম",
  limiter_value: "সীমাবদ্ধ মান",
  machine_scroll_view_page: "মেশিন স্ক্রোল দেখুন পৃষ্ঠা",
  create_mannual_parameter: "মানবিক প্যারামিটার তৈরি করুন",
  restrict_user_access: "ব্যবহারকারী অ্যাক্সেস সীমাবদ্ধ",
  add_device_parameter: "ডিভাইস প্যারামিটার যুক্ত করুন",
  entropy: "এনট্রপি",
  idle_latency: "অলস বিলম্ব",
  add_OPC_parameter: "ওপিসি প্যারামিটার যুক্ত করুন",
  node_id: "নোড আইডি",
  byte_format: "বাইট ফর্ম্যাট",
  param_scroll_view_page: "প্যারাম স্ক্রোল ভিউ পৃষ্ঠা",
  timeframed_sec_report: "সময় ফ্রেমড সেকেন্ড রিপোর্ট",
  prod_unit: "প্রোড ইউনিট",
  SEC_unit: "এসইসি ইউনিট",
  energy_parameters: "শক্তি পরামিতি",
  prod_param_type: "প্রোড প্যারাম টাইপ",
  production_parameters: "উত্পাদন পরামিতি",
  no_of_intervals: "অন্তর নেই",
  granular_data: "দানাদার ডেটা",
  csv: "সিএসভি",
  delete_rule: "নিয়ম মুছুন",
  enable_disable_all_parameters: "সমস্ত পরামিতি সক্ষম/অক্ষম করুন",
  disable: "অক্ষম করুন",
  enable: "সক্ষম করুন",
  edit_computed_parameter: "গণিত প্যারামিটার সম্পাদনা করুন",
  quantization: "কোয়ান্টাইজেশন",
  command: "কমান্ড",
  register_size: "রেজিস্টার আকার",
  consumer_ID: "গ্রাহক আইডি",
  site_id: "সাইট আইডি",
  get_data: "ডেটা পান",
  update_param_type: "প্যারাম টাইপ আপডেট করুন",
  write_in_data_logbook: "ডেটা লগবুক লিখুন",
  daily_data: "দৈনিক ডেটা",
  log_entry: "লগ এন্ট্রি",
  enable_Scroll_mode: "স্ক্রোল মোড সক্ষম করুন",
  multiple_areas: "একাধিক অঞ্চল",
  time_of_day: "দিনের সময়",
  capacity_logger: "ক্ষমতা লগার",
  provider: "সরবরাহকারী",
  total_sites_received_bills_MTD: "মোট সাইটগুলি বিল এমটিডি পেয়েছে",
  sites_yet_to_receive_bills: "সাইটগুলি এখনও বিল পেতে",
  total_sites: "মোট সাইট",
  sites_received_bills_MTD: "%সাইটগুলি বিল এমটিডি পেয়েছে",
  total: "মোট",
  total_bills_received_mtd: "মোট বিল এমটিডি পেয়েছে",
  arrear_1: "বকেয়া 1",
  arrear_2: "বকেয়া 2",
  no_reading: "কোন পড়া",
  bills_received_in_5_days: "বিলগুলি গত 5 দিনে প্রাপ্ত",
  site_wise_status: "ওকে স্থিতি ব্যতীত অন্য সাইটের ভিত্তিক স্থিতি",
  site_wise_final_summary: "সাইট ওয়াইজ ফাইনাল সংক্ষিপ্তসার",
  source_link: "উত্স লিঙ্ক",
  data_type: "ডেটা টাইপ",
  select_an_image_to_upload: "আপলোড করতে একটি চিত্র নির্বাচন করুন",
  user_group_Id: "ব্যবহারকারী গ্রুপ আইডি",
  user_group_name: "ব্যবহারকারী গ্রুপের নাম",
  delay_in_mins: "বিলম্ব (মিনিট)",
  delete_task: "টাস্ক মুছুন",
  watchers: "প্রহরী",
  comments: "মন্তব্য",
  asset_under_maintenance: "রক্ষণাবেক্ষণের অধীনে সম্পদ",
  start_time: "সময় শুরু",
  end_time: "শেষ সময়",
  download_latest_usage_table: "সর্বশেষ ব্যবহারের টেবিলটি ডাউনলোড করুন",
  tag: "ট্যাগ",
  add_target_data: "লক্ষ্য ডেটা যুক্ত করুন",
  edit_target: "লক্ষ্য সম্পাদনা করুন",
  delete_target: "লক্ষ্য মুছুন",
  prop: "প্রোপ",
  excel_template_based_report: "এক্সেল টেম্পলেট ভিত্তিক প্রতিবেদন",
  configured_hub: "কনফিগার করা হাব",
  configured_relay: "কনফিগার করা রিলে",
  desired_state: "কাঙ্ক্ষিত অবস্থা",
  configured_notification_group: "কনফিগার করা বিজ্ঞপ্তি গ্রুপ",
  email_ids: "ইমেল আইডি",
  logs: "লগ",
  unit_id: "ইউনিট আইডি",
  download_report: "প্রতিবেদন ডাউনলোড করুন",
  frequency: "ফ্রিকোয়েন্সি",
  ip: "আইপি",
  edit_wifi: "সম্পাদনা ওয়াইফাই",
  details: "বিশদ",
  connected_to: "সংযুক্ত",
  software_version: "সফ্টওয়্যার সংস্করণ",
  mac_address: "ম্যাক ঠিকানা",
  opc_client_enabled: "ওপিসি ক্লায়েন্ট সক্ষম",
  MQTT_bridge_enabled: "এমকিউটিটি ব্রিজ সক্ষম",
  hub_specification: "হাব স্পেসিফিকেশন",
  hub_details: "হাব বিশদ",
  restart_schedule: "শিডিউল পুনরায় চালু করুন",
  restart_device: "ডিভাইস পুনরায় চালু করুন",
  set_up_restart_time: "পুনরায় চালু করার সময় সেট আপ করুন",
  connectivity_logs: "সংযোগ লগ",
  internal_cache_size: "অভ্যন্তরীণ ক্যাশে আকার",
  default: "ডিফল্ট",
  configs: "কনফিগারেশন",
  processed: "প্রক্রিয়াজাত",
  space_available: "স্থান উপলব্ধ",
  kb: "কেবি",
  mb: "এমবি",
  last_ping: "শেষ পিং",
  toggle: "টগল",
  configured_creds: "কনফিগার করা ক্রেডিট",
  psk: "পিএসকে",
  set_value: "মান সেট করুন",
  time_current_time: "সময় (বর্তমান সময়টি উল্লেখ না করা হলে নেওয়া হবে)",
  Set: "সেট",
  health: "স্বাস্থ্য",
  port: "বন্দর",
  mqtt_params: "এমকিউটিটি-প্যারামস",
  delete_device: "ডিভাইস মুছুন",
  view_details: "বিশদ দেখুন",
  parameter_id: "প্যারামিটার আইডি",
  email_address: "ইমেল ঠিকানা",
  attached_triggers: "সংযুক্ত ট্রিগার",
  parameter_details: "প্যারামিটার বিশদ",
  enable_write: "লিখতে সক্ষম করুন",
  disable_write: "লিখুন অক্ষম করুন",
  offset: "অফসেট",
  no_of_parameters: "প্যারামিটার না",
  creation_time: "সৃষ্টি সময়",
  not_configured: "কনফিগার করা হয়নি",
  add_rule: "নিয়ম যুক্ত করুন",
  sl_no: "এসএল নং",
  targeted_by_value: "মান দ্বারা লক্ষ্য",
  configured_action: "কনফিগার করা ক্রিয়া",
  add_action: "ক্রিয়া যুক্ত করুন",
  action_id: "অ্যাকশন আইডি",
  delete_user: "ব্যবহারকারী মুছুন",
  bit: "বিট",
  low: "কম",
  high: "উচ্চ",
  esg_scope_conversion: "ইএসজি স্কোপ রূপান্তর",
  batch_production_logs: "ব্যাচ উত্পাদন লগ",
  esg_data_logs: "ইএসজি ডেটা লগ",
  remove_from_dashboard: "ড্যাশবোর্ড থেকে সরান",
  data_logs: "ডেটা লগ",
  select_device: "ডিভাইস নির্বাচন করুন",
  fetch_connectivity_logs: "সংযোগ লগ আনুন",
  hub_internet_connectivity_logs: "হাব ইন্টারনেট সংযোগ লগ",
  modbus_gateways: "মোডবাস গেটওয়ে",
  device_switched_on: "ডিভাইস চালু আছে",
  device_switched_off: "ডিভাইসটি বন্ধ করা হয়েছে",
  parameter_report: "প্যারামিটার রিপোর্ট",
  timeframed_specific_energy_consumption_report:
    "সময়সীমার নির্দিষ্ট শক্তি খরচ প্রতিবেদন",
  ebill_distribution_report: "ই-বিল বিতরণ প্রতিবেদন",
  root_user_management: "রুট ব্যবহারকারী পরিচালনা",
  user_acces_management: "ব্যবহারকারী অ্যাক্সেস পরিচালনা",
  inference: "অনুমান",
  turn_off: "বন্ধ",
  turn_on: "চালু করুন",
  diagram_pallette: "ডায়াগ্রাম প্যালেট",
  add_component: "উপাদান যোগ করুন",
  components: "উপাদান",
  add_text: "পাঠ্য যুক্ত করুন",
  add_realtime_data: "রিয়েলটাইম ডেটা যুক্ত করুন",
  shapes: "আকার",
  lines: "লাইন",
  text_realtime_component: "পাঠ্য রিয়েলটাইম উপাদান",
  shapes_pallette: "শেপ প্যালেট",
  lines_pallette: "লাইন প্যালেট",
  click_choose: "এসএলডি যোগ করতে আকারগুলি ক্লিক করুন এবং চয়ন করুন",
  choose_line: "এসএলডি যোগ করতে লাইন চয়ন করুন",
  choose_image: "চিত্র চয়ন করুন",
  upload_image_to_add: "এসএলডি যোগ করতে চিত্র আপলোড করুন",
  add_realtime_textdata: "এসএলডি -তে রিয়েল টাইম বা পাঠ্য ডেটা যুক্ত করুন",
  update_user: "আপডেট ব্যবহারকারী",
  add_watcher: "প্রহরী যুক্ত করুন",
  premise_area: "অনুমান অঞ্চল",
  create_computed_param: "গণিত পরম তৈরি করুন",
  create_derived_param: "উত্পন্ন পরম তৈরি করুন",
  proceed_to_ship: "জাহাজে এগিয়ে যান",
  esg_data_collection: "ইএসজি ডেটা সংগ্রহ",
  esg_scope_details: "ESG স্কোপ বিশদ",
  select_custom_date_range: "কাস্টম তারিখের পরিসীমা নির্বাচন করুন",
  days_365: "365 দিন",
  days_180: "180 দিন",
  days_30: "30 দিন",
  create_user_for_SSO: "এসএসওর জন্য ব্যবহারকারী তৈরি করুন",
  add_widget: "উইজেট যুক্ত করুন",
  add_machine_status_rules: "মেশিনের স্থিতি বিধি যুক্ত করুন",
  add_trigger: "ট্রিগার যুক্ত করুন",
  add_logged_parameter: "লগড প্যারামিটার যুক্ত করুন",
  edit_logged_parameter: "লগড প্যারামিটার সম্পাদনা করুন",
  create_mannual_param: "মানবিক পরম তৈরি করুন",
  add_opc_param: "ওপিসি প্যারাম যুক্ত করুন",
  create_write_block: "রাইট ব্লক তৈরি করুন",
  all_parameters: "সমস্ত পরামিতি",
  edit_device: "ডিভাইস সম্পাদনা করুন",
  edit_modbus_device: "মোডবাস ডিভাইস সম্পাদনা করুন",
  edit_mqtt_device: "এমকিউটিটি ডিভাইস সম্পাদনা করুন",
  limit_entropy: "এনট্রপি সীমাবদ্ধ করুন",
  emails: "ইমেল",
  power_factor: "পাওয়ারফ্যাক্টর (পিএফ)",
  tarrif_rate: "শুল্ক হার",
  apparent_power: "আপাত শক্তি",
  reactive_power: "প্রতিক্রিয়াশীল শক্তি",
  active_power: "সক্রিয় শক্তি",
  energy_usage_view_page: "শক্তি ব্যবহার দেখুন পৃষ্ঠা",
  switch_organisation: "সুইচ সংস্থা",
  wireless_creds: "ওয়্যারলেস ক্রেডিট",
  latest: "সর্বশেষ",
  value_is: "মান হয়",
  please_take_necessary_action: "দয়া করে প্রয়োজনীয় পদক্ষেপ নিন",
  edit_role: "ভূমিকা সম্পাদনা",
  delete_role: "ভূমিকা মুছুন",
  today: "আজ",
  days_4: "4 দিন",
  due: "কারণ",
  move_to_done: "সম্পন্ন সরানো",
  power_system_fault: "পাওয়ার সিস্টেম ফল্ট গণনা (গত সপ্তাহে)",
  alarms: "অ্যালার্ম",
  electricity_consumption: "বিদ্যুৎ খরচ (আজ অবধি মাস)",
  average_demand: "গড় চাহিদা",
  water_use_intensity: "জলের ব্যবহারের তীব্রতা (WUI)",
  average_water_cost: "গড় জল ব্যয় (মাসিক)",
  water_use_efficiency: "জল ব্যবহারের দক্ষতা (WUE)",
  batch_logs: "ব্যাচ লগ",
  edit_log: "লগ সম্পাদনা করুন",
  delete_log: "লগ মুছুন",
  dates: "তারিখ",
  log_details: "লগ বিশদ",
  view: "দেখুন",
  scope1_emission: "স্কোপ 1 নির্গমন",
  scope2_emission: "স্কোপ 2 নির্গমন",
  lmtd: "এলএমটিডি",
  company_data: "কোম্পানির ডেটা",
  historian: "ইতিহাসবিদ",
  from_optional: "থেকে (al চ্ছিক)",
  to_optional: "থেকে (al চ্ছিক)",
  trends: "প্রবণতা",
  generate_trend: "প্রবণতা উত্পন্ন",
  by: "দ্বারা",
  check_logs: "লগগুলি পরীক্ষা করুন",
  workspaces: "কর্মক্ষেত্র",
  types_of_nodes_connected: "সংযুক্ত নোডের প্রকার",
  types_of_modbus_device_connected: "সংযুক্ত মোডবাস ডিভাইসের প্রকারগুলি",
  no_of_registers: "রেজিস্টার না",
  write_register: "রেজিস্টার লিখুন",
  setting_view: "সেটিং ভিউ",
  sign_up: "সাইন আপ করুন",
  reset: "পুনরায় সেট করুন",
  mobile_number: "মোবাইল নম্বর",
  nebeskie_labs: "নেবেস্কি ল্যাবস",
  please_register_and_send_otp:
    "দয়া করে আপনার নিবন্ধিত ইমেল আইডি লিখুন এবং ওটিপি প্রেরণে ক্লিক করুন।",
  reset_password: "পাসওয়ার্ড পুনরায় সেট করুন",
  back_to_login: "লগইন ফিরে",
  otp: "ওটিপি",
  send_otp: "ওটিপি প্রেরণ করুন",
  org_alias: "অর্গ ওরফে",
  please_stand_by: "দয়া করে দাঁড়ানো",
  total_usage_this_month: "এই মাসে মোট ব্যবহার",
  total_usage_last_month: "গত মাসে মোট ব্যবহার",
  generate_report_manually: "ম্যানুয়ালি রিপোর্ট উত্পন্ন করুন",
  energy_distribution_detailed_report: "শক্তি বিতরণ বিস্তারিত প্রতিবেদন",
  the_report_is_processing: "প্রতিবেদনটি প্রক্রিয়াজাত হচ্ছে",
  enter_active_energy_manually: "ম্যানুয়ালি সক্রিয় শক্তি প্রবেশ করান",
  include_others: "অন্যদের অন্তর্ভুক্ত করুন",
  bill_amount: "বিলের পরিমাণ",
  yes_delete_it: "হ্যাঁ, এটি মুছুন",
  create_write_param: "রাইট প্যারাম তৈরি করুন",
  report_list: "রিপোর্ট তালিকা",
  plant_id: "উদ্ভিদ আইডি",
  plant_name: "গাছের নাম",
  plant_address: "উদ্ভিদ ঠিকানা",
  add_selected: "নির্বাচিত যোগ করুন",
  clear_selection: "পরিষ্কার নির্বাচন",
  real_time_value: "রিয়েল টাইম মান",
  default_status: "ডিফল্ট স্থিতি",
  default_label: "ডিফল্ট লেবেল",
  default_color_optional: "ডিফল্ট রঙ al চ্ছিক",
  rule_based_status: "নিয়ম ভিত্তিক স্থিতি",
  show_label_only: "শুধুমাত্র লেবেল দেখান",
  show_unit: "ইউনিট প্রদর্শন করুন",
  status_rules: "স্থিতি বিধি",
  configure: "কনফিগার করুন",
  monthly_usage: "মাসিক ব্যবহার",
  meter_consumption_kwh: "মিটার খরচ (কেডাব্লুএইচ)",
  total_unit: "মোট ইউনিট",
  region: "অঞ্চল",
  average_energy_consumed: "গড় শক্তি খরচ হয়",
  average_production: "গড় উত্পাদন",
  average_sec: "গড় সেকেন্ড",
  total_production: "মোট উত্পাদন",
  total_energy_consumed: "মোট শক্তি গ্রাস",
  max_sec: "সর্বোচ্চ সেকেন্ড",
  device_1: "ডিভাইস 1",
  device_parameter_1: "ডিভাইস প্যারামিটার 1",
  device_2: "ডিভাইস 2",
  device_parameter_2: "ডিভাইস প্যারামিটার 2",
  create_param: "পরম তৈরি করুন",
  update_tags: "আপডেট ট্যাগ",
  remove_from_process: "প্রক্রিয়া থেকে সরান",
  machine_parameters: "মেশিন পরামিতি",
  energy: "শক্তি",
  power: "শক্তি",
  remove_from_featured: "বৈশিষ্ট্যযুক্ত থেকে সরান",
  other_parameters: "অন্যান্য পরামিতি",
  add_to_featured: "বৈশিষ্ট্যযুক্ত যোগ করুন",
  logged_parameters: "লগড প্যারামিটার",
  tags: "ট্যাগ্স",
  machine_status_rules: "মেশিনের স্থিতি বিধি",
  connected_communication_devices: "সংযুক্ত যোগাযোগ ডিভাইস",
  mqtt_devices: "এমকিউটিটি ডিভাইস",
  modbus_devices: "মোডবাস ডিভাইস",
  total_no_of_params: "পরম মোট সংখ্যা",
  edge_devices: "প্রান্ত ডিভাইস",
  machines_configured: "মেশিনগুলি কনফিগার করা হয়েছে",
  reports_configured: "প্রতিবেদনগুলি কনফিগার করা হয়েছে",
  views_configured: "কনফিগার করা দর্শন",
  send_email_notification: "ইমেল বিজ্ঞপ্তি প্রেরণ করুন",
  enture_send_email_notification:
    "আসুন নির্বাচিত ব্যবহারকারী গোষ্ঠীতে ইমেল বিজ্ঞপ্তি প্রেরণ করুন",
  agree_and_send: "সম্মত এবং প্রেরণ",
  ebill: "এবিল",
  monthly_trend: "মাসিক প্রবণতা",
  param_view: "প্যারাম ভিউ",
  calories: "ক্যালোরি",
  param_count_view: "পরম গণনা দৃশ্য",
  created: "তৈরি",
  api_data_count_view: "এপিআই ডেটা গণনা ভিউ",
  diesel_emissions: "ডিজেল নির্গমন",
  eb_emissions: "ইবি নির্গমন",
  emissions_kg_co2: "নির্গমন কেজি-কো 2",
  kpis: "কেপিআই",
  jan_23: "জানুয়ারী -23",
  feb_23: "ফেব্রুয়ারী -23",
  mar_23: "মার্চ -23",
  apr_23: "এপ্রিল -23",
  may_23: "মে -23",
  jun_23: "জুন -23",
  lpg_emissions: "এলপিজি নির্গমন",
  petrol_emissions: "পেট্রোল নির্গমন",
  bullet_gauge: "বুলেট গেজ",
  equipment: "সরঞ্জাম",
  enter_no_of_machines: "মেশিনগুলির কোনও প্রবেশ করান",
  shift: "শিফট",
  upload: "আপলোড",
  image_upload: "চিত্র আপলোড",
  derived_parameter: "প্রাপ্ত প্যারামিটার",
  value_type: "মান_ টাইপ",
  enable_entropy: "এনট্রপি সক্ষম করুন",
  remove_decimal: "দশমিক সরান",
  limiter_entropy: "সীমাবদ্ধ এনট্রপি",
  show_sku_id: "স্কু আইডি দেখান",
  production_start_date: "উত্পাদন শুরুর তারিখ (al চ্ছিক)",
  production_start_time: "উত্পাদন শুরুর সময় (al চ্ছিক)",
  time: "সময়",
  temperature_c: "তাপমাত্রা (সি)",
  set_temperature_c: "তাপমাত্রা সেট করুন (সি)",
  no_of_people: "মানুষ নেই",
  co2_level: "সিও 2 স্তর",
  aqi_optional: "AQI al চ্ছিক",
  delete_sheets: "শীট মুছুন",
  root_login: "রুট লগইন",
  uam_login: "ইউএএম লগইন",
  login: "লগইন",
  add_oem_logo: "ওএম লোগো যুক্ত করুন",
  devices: "ডিভাইস",
  derived_data_units: "উত্পন্ন/ডেটা ইউনিট",
  roles: "ভূমিকা",
  engineering: "ইঞ্জিনিয়ারিং",
  control_panel: "নিয়ন্ত্রণ প্যানেল",
  views: "ভিউ",
  reports_analysis: "প্রতিবেদন এবং বিশ্লেষণ",
  actions_triggers: "ক্রিয়া এবং ট্রিগার",
  modules: "মডিউল",
  processes: "প্রক্রিয়া",
  air_quality: "বায়ু মানের",
  manufacturing: "উত্পাদন",
  esg: "ESG",
  task_managment: "টাস্ক ম্যানেজমেন্ট",
  sustainability: "টেকসই",
  groups: "গোষ্ঠী",
  log_book: "লগ বই",
  monitor: "মনিটর",
  enture_settings: "সেটিংস চালু করুন",
  configuration: "কনফিগারেশন",
  bar: "বার",
  line: "লাইন",
  s7_device: "এস 7 ডিভাইস",
  rack: "র্যাক",
  slot: "স্লট",
  db_number: "ডিবি নম্বর",
  byte_offset: "বাইট অফসেট",
  bit_offset: "বিট অফসেট",
  data_length: "ডেটা দৈর্ঘ্য",
  val_type: "মান প্রকার",
  create_s7_device: "এস 7 ডিভাইস যুক্ত করুন",
  scope_label: "স্কোপস",
  client_safety: "ক্লায়েন্ট সুরক্ষা",
  edit: "সম্পাদনা",
  delete: "মুছুন",
  choose_param_to_monitor: "নিরীক্ষণের জন্য পরম চয়ন করুন",
  rules: "নিয়ম",
  add_to_row: "সারি যোগ করুন",
  add_usage: "ব্যবহার যোগ করুন",
  notifications: "বিজ্ঞপ্তি",
  organisation_details: "সংস্থার বিশদ",
  profile: "প্রোফাইল",
  user_access: "ব্যবহারকারী অ্যাক্সেস",
  admin: "অ্যাডমিন",
  shifts: "শিফট",
  add_pie_TD_widget: "পাই টিডি উইজেট যুক্ত করুন",
  edit_pie_TD_widget: "পাই টিডি উইজেট সম্পাদনা করুন",
  legend: "কিংবদন্তি",
  pie: "পাই",
  edit_bar_trend_widget: "বার ট্রেন্ড উইজেট সম্পাদনা করুন",
  add_bar_trend_widget: "বার ট্রেন্ড উইজেট যুক্ত করুন",
  edit_horizantal_bar_trend_widget: "অনুভূমিক বার ট্রেন্ড উইজেট সম্পাদনা করুন",
  add_horizantal_bar_trend_widget: "অনুভূমিক বার ট্রেন্ড উইজেট যুক্ত করুন",
  too_long: "খুব দীর্ঘ",
  trigger_logs: "ট্রিগার লগ",
  create_trigger: "ট্রিগার তৈরি করুন",
  right_click_to_configure: "কনফিগার করতে ডান ক্লিক করুন",
  width: "প্রস্থ",
  height: "উচ্চতা",
  hide_value: "মান লুকান",
  edit_machine: "সম্পাদনা মেশিন",
  wait_time: "সময় অপেক্ষা করুন",
  production_date: "উত্পাদন তারিখ",
  connectivity_status: "সংযোগের স্থিতি",
  production_plans: "উত্পাদন পরিকল্পনা",
  production_pending: "উত্পাদন মুলতুবি",
  pending_shipment: "মুলতুবি চালান",
  trace_logs: "লগ ট্রেস",
  movement_events: "আন্দোলনের ঘটনা",
  kgco2: "কেজিও 2",
  entropy_threshold: "এন্ট্রপি থ্রেশহোল্ড",
  threshold_value: "প্রান্তিক মান",
  pending_dispatch: "মুলতুবি প্রেরণ",
  dispatched: "প্রেরণ",
  dispatch_details: "প্রেরণ বিশদ",
  dispatch: "প্রেরণ",
  raw_materials: "কাঁচামাল",
  pour_qty: "পরিমাণ our ালা",
  summary: "সংক্ষিপ্তসার",
  optional: "Al চ্ছিক",
  param_mqtt_map: "প্যারাম এমকিউটিটি মানচিত্র",
  machine_status_mqtt_map: "মেশিনের স্থিতি এমকিউটিটি মানচিত্র",
  edit_trigger_count_table_widget: "ট্রিগার গণনা টেবিল উইজেট সম্পাদনা করুন",
  add_trigger_count_table_widget: "ট্রিগার গণনা টেবিল উইজেট যুক্ত করুন",
  count: "গণনা",
  real_time_consumption: "রিয়েল টাইম সেবন",
  search_view: "অনুসন্ধান ভিউ",
  multiple_entries: "একাধিক এন্ট্রি",
  combined_parameter: "সম্মিলিত প্যারামিটার",
  background_color: "পটভূমি রঙ",
  resize: "আকার পরিবর্তন করুন",
  remove: "সরান",
  select_views: "দর্শন নির্বাচন করুন",
  difference: "পার্থক্য",
  sum: "যোগফল",
  set_param_type_for_machine: "মেশিনের জন্য প্যারাম টাইপ সেট করুন",
  y1axis: "y1 অক্ষ",
  y2axis: "y2 অক্ষ",
  temperature_parameters: "তাপমাত্রা পরামিতি",
  area_name: "অঞ্চল নাম",
  maintenance_due_on: "রক্ষণাবেক্ষণ কারণ",
  days_left: "দিন বাকি",
  enture_insights: "ভেনচার অন্তর্দৃষ্টি",
  reports: "রিপোর্ট",
  till_date_pie_chart: "আজ অবধি পাই চার্ট",
  till_date_bar_chart: "আজ অবধি বার চার্ট",
  tod_billing_widget: "টড বিলিং উইজেট",
  bar_trend_widget: "বার ট্রেন্ড উইজেট",
  target_based_bar_trend_widget: "লক্ষ্য ভিত্তিক বার ট্রেন্ড উইজেট",
  gauge_widget: "গেজ উইজেট",
  consumption_pie_widget: "খরচ পাই উইজেট",
  consumption_gauge_widget: "গ্রাহক গেজ উইজেট",
  angular_gauge_widget: "কৌণিক গেজ উইজেট",
  table_widget: "টেবিল উইজেট",
  banner_widget: "ব্যানার উইজেট",
  param_equation_banner_widget: "পরম সমীকরণ ব্যানার উইজেট",
  shift_based_usage_table: "শিফট ভিত্তিক ব্যবহার টেবিল",
  bitwise_word_widget: "বিটওয়াইজ ওয়ার্ড উইজেট",
  dynamic_log_widget: "গতিশীল লগ উইজেট",
  machine_status_timeline_widget: "মেশিনের স্থিতি টাইমলাইন উইজেট",
  process_or_machine_status_widget: "প্রক্রিয়া/মেশিনের স্থিতি উইজেট",
  parameter_status_based_usage_status: "প্যারামেটারস্ট্যাটসবেসড উইজেট",
  parameter_insights_widget: "প্যারামিটার অন্তর্দৃষ্টি উইজেট",
  heat_map: "তাপ মানচিত্র",
  usage_score_card_widget: "ব্যবহার স্কোর কার্ড উইজেট",
  busbar_live_monitoring: "বাসবার লাইভ মনিটরিং",
  bullet_or_angular_guage_widget: "বুলেট/কৌণিক গুয়েজ উইজেট",
  dual_axis_bar_trend_widget: "দ্বৈত অক্ষ বার ট্রেন্ড উইজেট",
  trigger_count_table_widget: "ট্রিগার গণনা টেবিল উইজেট",
  bar_trend_td_widget: "বার ট্রেন্ড টিডি উইজেট",
  horizantal_bar_trend_widget: "অনুভূমিক বার ট্রেন্ড উইজেট",
  choose_from_templates: "_ফর্ম_টেমপ্লেটগুলি চয়ন করুন",
  shift_timeframed_trends: "শিফট ভিত্তিক সময়সীমাবদ্ধ প্রবণতা",
  candlestick_trends: "মোমবাতি স্টিক প্রবণতা",
  free_ram: "Free RAM",
  item: "Item",
  item_code: "Item Code",
  department: "Department",
  departments: "Departments",
  issue_vouchers: "Issue Vouchers",
  issue_voucher: "Issue Voucher",
  to_be_approved: "To Be Approved",
  to_be_issued: "To Be Issued",
  intended: "Intended",
  approved: "Approved",
  received: "Received",
  receiver: "Receiver",
  material: "material",
  material_group: "material group",
  material_desc: "Material Description",
  uom: "Unit Of Measurement",
  create_item_code: "Create Item Code",
  create_department: "Create Department",
  users: "Users",
  items: "Items",
  users_in_department: "Users in Department",
  authorisers_in_department: "Authorisers in Department",
  issuers_in_department: "Issuers in Department",
  item_issuer_list: "Item Issuer List",
  add_item_issuer: "Add Item Issuer",
  store: "Store",
  create_issue_voucher: "Create Issue Voucher",
  voucher_requests: "Voucher Requests",
  intended_qty: "Intended Qty",
  issued_qty: "Issued Qty",
  issue_items: "Issue Items",
  voucher_receipt: "Voucher Receipt",
  add_stock: "Add Stock",
  attached_targets: "Attached Targets",
  attach_target: "Attach Target",
  add_target_to_parameter: "Add Target to Parameter",
  empty: "Empty",
  add_day_wise_target: "Add Day wise Target",
  day_wise_targets: "Day Wise Targets",
  fetch_targets: "Fetch Targets",
  day_wise_machine_status_widget: "দিনভিত্তিক যন্ত্রের অবস্থা উইজেট",
  add_day_wise_machine_status_widget:
    "দিনভিত্তিক যন্ত্রের অবস্থা উইজেট যোগ করুন",
  edit_day_wise_machine_status_widget:
    "দিনভিত্তিক যন্ত্রের অবস্থা উইজেট সম্পাদনা করুন",
  add_machines: "যন্ত্র যোগ করুন",
  added_machines: "যন্ত্র যোগ করা হয়েছে",
  consumption_start_time: "Consumption Start time",
};