<template>
    <span>
      <!-- <div class="gauge-title" :class="{ 'gauge-value-bottom': bottom }">
        <span :style="{ color: $store.getters.getColorPalette().accentCode }" :class="gaugeValueClass">{{ unit }}</span>
      </div> -->
      <div :style="{'background-color': $store.getters.getColorPalette().background2ColorCode, 'max-height':height, 'max-width':'100%'}" ref="gauge"></div>
    </span>
  </template>
  
    
    <script>
    import Plotly from 'plotly.js'
    export default {
      name: "SingleParamStreamGauge",
      props: {
        unit: {
          type: String,
          default: ""
        },
        height: {
          type: String,
          default: "210px"
        },
        width: {
          type: String,
          default: "200%"
        },
        decimalPlace: {
          type: Number,
          default: 0
        },
        gaugeValueClass: {
          type: String,
          default: ""
        },
        top: {
          type: Boolean,
          default: false
        },
        bottom: {
          type: Boolean,
          default: true
        },
        maxValue: {
          type: Number,
          default: 100
        },
        minValue: {
          type: Number,
          default: 0
        },
        options:{
            default() {
                return {}
            },
            required: true
        },
        animationSpeed: {
          type: Number,
          default: 10
        },
        initialValue: {
          type: Number,
          default: 0
        },
        value: {
          type: Number,
          default: 0
        },
        donut: {
          type: Boolean,
          default: false
        },
        paramLabel:{
          type:String,
          default:""
        }
      },
      data() {
        return {
          gauge: null,
          layout:{
            colorway:this.$store.getters.getColorPalette().colorTypesCodeArray,
            autosize: true,
            margin: { t: 30, r:20, l: 0, b: 10 },
                  font:{
                        color:this.$store.getters.getColorPalette().accentCode,
                      },
                  plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                  paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                  showLegend:false,
            annotations: [{
              x: null,
              y: null,  
              xref: 'paper',
              yref: 'paper',
              text: null,
              showarrow: false,
              font: {
                size: 12,
                color: this.$store.getters.getColorPalette().accentCode
              },
              align: 'center'
            }],
                  
                }
        };
      },
      mounted() {
        this.createGauge(this.value);
      },
      beforeDestroy() {
        delete this.gauge;
      },
      
      methods: {
        // initializeGauge() {
        //   this.gauge = this.donut
        //     ? new Donut(this.$refs.gauge)
        //     : new Gauge(this.$refs.gauge);
        //   this.gauge.maxValue = this.maxValue;
        //   this.gauge.setMinValue(this.minValue);
        //   this.gauge.animationSpeed = this.animationSpeed;
        //   this.gauge.setOptions(this.options);
        //   // this.gauge.setTextField(this.$refs["gauge-value"], this.decimalPlace);
        //   this.gauge.set(this.value);
        // },
        createGauge(x){
//             const data = {
//   value: x?x:450,
//   type: 'indicator',
//   mode: 'number+gauge',
//   gauge: {
//     shape: 'angular',
//     bar: { thickness: 1 },
//     steps: [
//           {
//             range: [this.minValue, 3500],
//             color: 'cyan',
//             thickness: 1,
//           },
//           {
//             range: [-1200, 1200],
//             color: 'royalblue',
//             thickness: 1,
//           },
//           {
//             range: [0, this.maxValue],
//             color: 'blue',
//             thickness: 0.8,
//           },
//         ]
//   },
// }

// Plotly.newPlot(this.$refs.gauge, data, this.layout, {displaylogo: false});
let steps=[]
let min =0
if(this.options.staticZones && this.options.staticZones.length){
  for(let i of this.options.staticZones){
    steps.push({
        range: [i.min, i.max],
            color: i["strokeStyle"],
            thickness: 1,
    })
    if(min>i.min){
      min=i.min
    }
}
}else{
  steps=null
}
if(x && x<min){
  min =x
}
if(this.value && this.value<min){
  min=this.value
}
const data = [
  {
    domain: { x: [0, 1], y: [0, 1] },
    value: x?x:this.value,
    // title: { text: "Speed" },    
    type: "indicator",
    mode: "gauge+number",
    delta: { reference: 1 },
    // marker: {
    //         line: {
    //           color: this.$store.getters.getColorPalette().accentCode,
    //           width: 0.3
    //         },
    //       },
    gauge: { 
      axis: { range: min>0? [min, this.maxValue>x?this.maxValue:x]:[this.maxValue,min],  tickcolor:this.$store.getters.getColorPalette().accentCode,},
      bar: { color: this.options.pointer_color?this.options.pointer_color:this.$store.getters.getColorPalette().accentCode+'B9' },
      bordercolor:this.$store.getters.getColorPalette().accentCode,
    steps: steps,
 }
  }
];
if(this.paramLabel && this.paramLabel.length>0){

  if(this.height == '150px'){
    this.layout.annotations[0].text=this.paramLabel
    // console.log(this.layout.annotations);
    this.layout.annotations[0].x = 0.5
    this.layout.annotations[0].y = -0.1
  }else{
    this.layout.annotations[0].text=this.paramLabel
    this.layout.annotations[0].x=0.5
    this.layout.annotations[0].y=0.18
  }
}else{
  this.layout.annotations[0].text=''
}
// var layout = { width: 600, height: 400 };
Plotly.newPlot(this.$refs.gauge, data, this.layout, {displaylogo: false});
        },
      },

      watch: {
        value: function(newVal) {
          this.createGauge(newVal);
        },
        paramLabel: function(){
          this.createGauge()
        }
      },
    };
    </script>
    
    <style scoped>
    .gauge-title span {
      display: inline;
      text-align: center;
    }
    </style>